import React, { useState } from "react";

import Api from "../../api";
import { useSynchronizeHubSpotTicketsMutation } from "../../api/rtk/hubspotTicketsApi";
import { AlertDialog, Filter, Layout, NoRecords, OnError, ServiceHeader } from "../../components";
import ScheduledServiceCard from "../../components/cards/ScheduledServicesCard";
import { UserRoleAccess } from "../../constant";
import { isValidArrayWithData } from "../../core/utilities";
import FilterOptions from "../../filter/FilterOptions";
import useHasAccessToFeature from "../../hooks/useHasAccessToFeature";
import ServicesSkeleton from "../../skeletons/ServicesSkeleton";

export default function ScheduledServices() {
  const { data: tickets = [], isLoading, isError, error } = Api.hubspotTickets.useGetAllServicesTicketsQuery();
  const [synchronizeTicketsData] = useSynchronizeHubSpotTicketsMutation();
  const { hasAccess: hasAccessToSynchronize } = useHasAccessToFeature(UserRoleAccess.serviceHubspotSynchronize);

  const ticketsFound = isValidArrayWithData(tickets);

  return isError ? (
    <OnError error={error} />
  ) : isLoading ? (
    <ServicesSkeleton isLoading={isLoading} title="Vehicle Scheduled Services" />
  ) : ticketsFound ? (
    <DisplayTickets allTickets={tickets} hasAccessToSynchronize={hasAccessToSynchronize} synchronizeTicketsData={synchronizeTicketsData} />
  ) : (
    <NoRecords title="Vehicle Scheduled Services" message="No Vehicle Scheduled Services found." />
  );
}

function DisplayTickets({ allTickets, hasAccessToSynchronize, synchronizeTicketsData }) {
  const [isFilter, setIsFilter] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [tickets, setTickets] = useState(allTickets);
  const [displayData, setDisplayData] = useState(tickets);
  const [hubspotSynchAlert, setHubspotSynchAlert] = useState({ show: false });
  const updateTickets = (tickets) => {
    setTickets(tickets);
    setDisplayData(tickets);
  };

  const handleTicketsSync = async () => {
    const synchResponse = await synchronizeTicketsData().unwrap();

    if (synchResponse.success) {
      setHubspotSynchAlert({ show: true, message: "Hubspot and Dashboard has been synchronize." });
    } else {
      setHubspotSynchAlert({ show: true, message: "Hubspot and Dashboard could not be synchronize." });
    }
  };

  const handleFilterDataChange = (filteredData) => {
    setIsFiltered(filteredData.length !== tickets.length);
    setDisplayData(filteredData);
  };

  const filterOptions = () => {
    const options = new FilterOptions();
    return options.getServicesScheduledServices();
  };

  return (
    <Layout>
      <ServiceHeader
        title="Scheduled Services"
        hasAccessToSynchronize={hasAccessToSynchronize}
        handleTicketsSync={handleTicketsSync}
        isFilter={isFilter}
        setIsFilter={setIsFilter}
        allTickets={allTickets}
        updateTickets={updateTickets}
      />
      <AlertDialog show={hubspotSynchAlert.show} alertMessage={hubspotSynchAlert.message} onClick={() => setHubspotSynchAlert({ show: false })} />
      <Filter enabled={isFilter} data={tickets} filterOptions={filterOptions()} handleFilterDataChange={handleFilterDataChange} />
      <section className="grid grid-cols-1 gap-8 mt-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
        {displayData?.map((scheduledService, index) => (
          <ScheduledServiceCard
            key={scheduledService.id}
            name={scheduledService.name}
            makeModel={scheduledService.makeAndModel}
            status={scheduledService.status}
            vehicleReg={scheduledService.vehicle_registration}
            description={scheduledService.hs_content}
            Reason={scheduledService.alertCode}
          />
        ))}
      </section>
    </Layout>
  );
}
