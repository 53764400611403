import React from 'react'

export default function DeviceOdometerDialog({ deviceId, odometer, onChangeOdometer, onCancel, onSave })  {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-80">
        <div className="p-6 bg-gray-800 rounded-lg">
          <h2 className="mb-4 text-xl font-semibold">Dialog</h2>
          <div className="mb-4">
            <label className="block text-gray-400">Device ID</label>
            <input
              type="text"
              value={deviceId}
              readOnly
              className="w-full px-3 py-2 text-white bg-gray-700 border border-gray-600 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-400">Odometer</label>
            <input
              type="number"
              value={odometer}
              onChange={onChangeOdometer}
              className="w-full px-3 py-2 text-white bg-gray-700 border border-gray-600 rounded"
            />
          </div>
          <div className="flex justify-end">
            <button
              className="px-4 py-2 mr-2 font-bold text-white bg-red-500 rounded hover:bg-red-700"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 font-bold text-white bg-green-500 rounded hover:bg-green-700"
              onClick={onSave}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  };  