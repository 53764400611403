
import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useHasAccessToFeature from "../../hooks/useHasAccessToFeature";

/*
RequireAuth.PropTypes = {
  feature: PropTypes.shape({action: PropTypes.string, name: PropTypes.string})
};*/

function RequireAuth({ feature }) {
  const { hasAccess: isAuthorized, isSignedIn } = useHasAccessToFeature(feature);
  const location = useLocation();
  // console.log({ isAuthorized, isSignedIn, location,feature });
  return isSignedIn === false ? <Navigate to="/login" state={{ from: location, isSignedIn }} replace /> :
    isAuthorized ? <Outlet /> :
    isAuthorized === false ? <Navigate to="/" replace /> : null;
};



export default RequireAuth;
