import React, { useState, useEffect } from "react";

import { Layout, NoRecords } from "../../components";
import { Link } from "react-router-dom";
import DownloadIcon from "../../components/icons/DownloadIcon";
import DriverRatingCard from "../../components/cards/DriverRatingCard";
import { isValidArrayWithData } from "../../core/utilities";

const data = [
  {
    name: "Jonas Mkansi",
    rating: "good",
    driver_score: "87%",
    braking_count: "12",
    acceleration_count: "15"
  },
  {
    name: "Eschel Makgwarela",
    rating: "good",
    driver_score: "78%",
    braking_count: "23",
    acceleration_count: "85"
  },
  {
    name: "Hlekani Shikwambani ",
    rating: "good",
    driver_score: "98%",
    braking_count: "112",
    acceleration_count: "215"
  },
  {
    name: "James Elstob ",
    rating: "satisfactory",
    driver_score: "47%",
    braking_count: "82",
    acceleration_count: "116"
  },
  {
    name: "James Shikweni",
    rating: "bad",
    driver_score: "69%",
    braking_count: "112",
    acceleration_count: "15"
  },
  {
    name: "Thulani Shabangu",
    rating: "bad",
    driver_score: "65%",
    braking_count: "100",
    acceleration_count: "25"
  },
  {
    name: "Thulani Shabangu",
    rating: "bad",
    driver_score: "20%",
    braking_count: "122",
    acceleration_count: "255"
  }
];
export default function DriverRatings() {
  const isLoading = false;
  const driverRatings = [];
  const driverRatingsFound = isValidArrayWithData(driverRatings);

  return isLoading ? (
    <DisplayDriverRatings allDriverRatings={[]} isLoading={isLoading}/>
  ) : driverRatingsFound ? (
    <DisplayDriverRatings allDriverRatings={driverRatings} />
  ) : (
    <NoRecords title="Driver Ratings" message="No Driver Ratings found." />
  );
}

function DisplayDriverRatings({ allDriverRatings, isLoading=false }) {
  const [driverRatings, setDriverRatings] = useState(allDriverRatings);
  const [filter, setFilter] = useState();
  const [badRatings, setBadRatings] = useState();
  const [goodRatings, setGoodRatings] = useState();
  const [satisfactoryRatings, setSatisfactoryRatings] = useState();

  useEffect(() => {
    setGoodRatings(driverRatings.filter((el) => el.rating === "good"));
    setSatisfactoryRatings(driverRatings.filter((el) => el.rating === "satisfactory"));
    setBadRatings(driverRatings.filter((el) => el.rating === "bad"));
  }, [driverRatings]);

  useEffect(() => {
    if (filter === "good") {
      setDriverRatings(goodRatings);
    } else if (filter === "satisfactory") {
      setDriverRatings(satisfactoryRatings);
    } else if (filter === "bad") {
      setDriverRatings(badRatings);
    } else {
      setDriverRatings(data);
    }
  }, [filter]);

  return (
    <Layout isLoading={isLoading} loadingText="Loading Driver Ratings">
      <header className="flex flex-row justify-between px-5 rounded-sm shadow-lg bg-other col-span-full xl:col-span ">
        {/* link to driver listing page and driver name */}
        <div className="flex flex-row items-center gap-2 py-4 font-semibold text-primary">
          <Link to="/drivers">Driver Ratings </Link>

          <div className="filter-nav">
            <button type="button" onClick={() => setFilter("all")} className="italic hover:underline">
              All
            </button>
            <button type="button" onClick={() => setFilter("good")} className="px-2 text-sm italic bg-green-400 rounded-full hover:underline text-other">
              Good
            </button>

            <button type="button" onClick={() => setFilter("satisfactory")} className="px-2 text-sm italic bg-yellow-400 rounded-full hover:underline text-other">
              Satisfactory
            </button>
            <button type="button" onClick={() => setFilter("bad")} className="px-2 text-sm italic bg-red-400 rounded-full hover:underline text-other">
              Bad
            </button>
          </div>
        </div>
        {/* download button */}
        <div className="flex items-center">
          <button className="inline-flex items-center justify-center w-full gap-1 text-other btn-primary">
            <DownloadIcon classes="h-4 w-4 fill-current" /> Download Report (PDF)
          </button>
        </div>
      </header>

      <section className={"grid grid-cols-1 sm:grid-cols-2 gap-8 mt-8 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"}>
        {driverRatings?.map((driver, index) => (
          <DriverRatingCard
            key={index + driver}
            name={driver.name}
            rating={driver.rating}
            driver_score={driver.driver_score}
            braking_count={driver.braking_count}
            acceleration_count={driver.acceleration_count}
          />
        ))}
      </section>
    </Layout>
  );
}
