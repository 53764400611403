import React from "react";
import { Link, NavLink } from "react-router-dom";
import { DropDownArrow } from "../icons/SidebarIcons";
import SidebarLinkGroup from "./SidebarLinkGroup";
import { SidebarMenuIcon } from "./SidebarMenuIcon";

const ExpandedMultipleOptionsMenu = ({ pathName, menuName, menuIcon, isActiveItem, subMenuOptions }) => {
  return (
    <SidebarLinkGroup activeCondition={isActiveItem}>
      {(handleClick, open) => {
        return (
          <React.Fragment>
            <Link to={subMenuOptions[0]?.url}>
              <div
                onClick={handleClick}
                className={`sidebar-item group-hover:text-sidebar-other  ${isActiveItem}`}
                // onClick={(e) => {sidebarExpanded ? handleClick() : setSidebarExpanded(true);}}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <SidebarMenuIcon isActive={isActiveItem} menuIcon={menuIcon} />
                    <span className="sidebar-link-name">{menuName}</span>
                  </div>
                  {/* drop-down Icon */}
                  <DropDownArrow style={`dropdown-arrow ${open && "rotate-180"}`} />
                </div>
              </div>
            </Link>
            <ul className={`pl-10 mt-1 ${!open && "hidden"}`}>
              {subMenuOptions?.map(({ url, name }) => (
                <li key={name + url} className="mb-1 last:mb-0">
                  <NavLink
                    end
                    to={url}
                    className={({ isActive }) =>
                      "block border-l border-l-white/20 rounded-r-md hover:border-l-brand pl-1  transition duration-150 truncate group-hover:text-sidebar-other " +
                      (isActive ? "!text-brand" : "text-sidebar-link")
                    }
                  >
                    <span className="text-sm font-medium duration-200 lg:sidebar-expanded:opacity-100 2xl:opacity-100">{name}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </React.Fragment>
        );
      }}
    </SidebarLinkGroup>
  );
};

export default ExpandedMultipleOptionsMenu;
