import React from 'react'
import { NavLink, useLocation } from "react-router-dom";

function ConditionalNavLink({path,classes,children }) {
    const location = useLocation();
    const isNotSamePath = path ? location.pathname !== path : false;
    // console.log({isNotSamePath,path,location})
    return (
      isNotSamePath ?
      <NavLink end to={path} className={classes }>
          {children}
            </NavLink> :
            <>
                {children}
            </>
  )
}

export default ConditionalNavLink