import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setDarkTheme, setLightTheme, selectCurrentTheme } from "../../features/settings/settingsSlice";

import { SunIcon, MoonIcon } from "../icons";

const defaultBrowserTheme = () => {

  if (window.matchMedia("prefers-color-scheme:dark").matches) {
    return "dark-theme";  
  }
  return "light-theme";
}

const SwitchTheme = () => {
  const [theme, setTheme] = useState(useSelector(selectCurrentTheme)||defaultBrowserTheme());
  //const settings = useSelector((state => state.settings));
  const dispatch = useDispatch();

  const toggleTheme = () => {
    
    if (theme === "light-theme") {
      dispatch(setDarkTheme());
      setTheme("dark-theme");
    } else {
      dispatch(setLightTheme());
      setTheme("light-theme");
    }
    if (window.location.pathname === "/" || window.location.pathname.startsWith("/vehicles/vehicle-trip/")) {
      
      window.location.reload(true);
    }
  };

  useEffect(() => {
    //dispatch(setTheme({theme}));
    document.body.className = theme;
  }, [theme, dispatch]);

  // console.log("Switch theme", { theme });

  return (
    <>
      <button onClick={toggleTheme} type="button" className="px-4 hover:text-brand">
        {theme === "light-theme" || theme === "" ? <MoonIcon /> : <SunIcon />}
      </button>
    </>
  );
};

export default SwitchTheme;
