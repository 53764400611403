import React, { useMemo, useState } from "react";
import Api from "../../api";
import { AlertDialog, Layout, NoRecords, OnError, PrimaryHeader } from "../../components";
import InputSelect from "../../components/InputSelect";
import { isValidArrayWithData,dateTimeMidnight } from "../../core/utilities";
import TablesSkeleton from "../../skeletons/TablesSkeleton";

export default function VehiclesLocationsTrackingReport() {
  const { data: vehicles = [], isLoading, isError, error } = Api.vehicles.useGetAllVehiclesQuery();
  const vehiclesWithLocationTracking = vehicles.length > 0 ? vehicles.filter((vehicle) => vehicle.numberOfTrackingLocations > 0) : null;
  const vehiclesFound = isValidArrayWithData(vehiclesWithLocationTracking);
  

  return isError ? (
    <OnError error={error} />
  ) : isLoading ? (
    <TablesSkeleton isLoading={isLoading} title="Vehicle Location Tracking Report" />
  ) : vehiclesFound ? (
    <DisplayVehiclesTrackingReportCreate vehiclesWithLocationTracking={vehiclesWithLocationTracking} />
  ) : (
    <NoRecords title="Vehicle Location Tracking Report" message="No Vehicles found, location tracking reports cannot be generated." />
  );
}

function DisplayVehiclesTrackingReportCreate({ vehiclesWithLocationTracking }) {
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
  const [selectedVehicles, setSelectedVehicles] = useState(null);
  const [alertInfo, setAlertInfo] = useState({ show: false });
  const [isLoadingInfo, setIsLoadingInfo] = useState({ status: false });
  const [postLocationTrackingReport] = Api.vehicles.usePostVehiclesLocationTrackingReportMutation();

  const vehicleList = useMemo(() => {
    return vehiclesWithLocationTracking.map((vehicle) => ({ value: vehicle?.id, label: vehicle?.vehicleRegistrationNumber, id: vehicle.id, model: vehicle?.makeAndModel }));
  }, [vehiclesWithLocationTracking]);

  const today = new Date();
  const maximumDate = today.toISOString().split("T")[0];
  const oneYearAgo = new Date(today);
  oneYearAgo.setFullYear(today.getFullYear() - 1);
  const minimumDate = oneYearAgo.toISOString().split("T")[0];

  const resetForm = () => {
    setDateRange({ startDate: "", endDate: "" });
    setSelectedVehicles(null);
  };

  const handlePdfReport = async (e) => {
    e.preventDefault();
    const vehicles = selectedVehicles.map((vehicle) => { return { id: vehicle.id }; });
    const { startDate, endDate } = dateRange;
    const reportInfo = {
      vehicles, dateRange: {
        startDate: dateTimeMidnight(startDate),
      endDate}
    } // Create a FormData object from the form
    if (!isValidDateRange(new Date(dateRange?.startDate), new Date(dateRange?.endDate))) {
      setDateRange({ startDate: "", endDate: "" });
      setAlertInfo({ show: true, message: "Date range cannot be more than 1 year" });
      return;
    } else {
      try {
        console.log({ formData: reportInfo });
        setIsLoadingInfo({ status: true, text: "Processing Vehicle Location Tracking Report" });
        const response = await postLocationTrackingReport(reportInfo).unwrap(); // Send the FormData instance
        setIsLoadingInfo({ status: false });
        console.log({ response });

        if (response.success) {
          resetForm(); // reset form
          setAlertInfo({ show: true, message: "Location Tracking report has been generated and will be emailed to you shortly" });
        } else {
          console.log({ response });
          setAlertInfo({ show: true, message: "Report could not be generated, please try again" });
        }
      } catch (error) {
        setIsLoadingInfo({ status: false });
        console.log(error);
        setAlertInfo({ show: true, message: "Report could not be generated, please try again" });
        if (error.status === 403) {
          window.location.reload();
        }
      }
    }
  };

  const isValidDateRange = (start, end) => start <= end && end - start <= 365 * 24 * 60 * 60 * 1000;

  const handleDateRange = (e) => {
    const { name, value } = e.target;
    if (name === "startDate" && dateRange?.endDate && value > dateRange?.endDate) {
      setAlertInfo({ show: true, message: "Start date cannot be greater than end date" });
      setDateRange({ ...dateRange, startDate: "", isValid: false });
    } else if (name === "endDate" && dateRange?.startDate && value < dateRange?.startDate) {
      setAlertInfo({ show: true, message: "End date cannot be less than start date" });
      setDateRange({ ...dateRange, endDate: "", isValid: false });
    } else {
      setDateRange({ ...dateRange, [name]: value, isValid: true });
    }
  };

  const handleSelectVehicle = (data) => {
    setSelectedVehicles(data);
  };

  // console.log({ vehicles: vehiclesWithLocationTracking });
  return (
    <Layout isLoading={isLoadingInfo.status} loadingText={isLoadingInfo.text}>
      <PrimaryHeader title="Vehicle Location Tracking Report"></PrimaryHeader>
      <AlertDialog show={alertInfo.show} alertMessage={alertInfo.message} onClick={() => setAlertInfo({ show: false })} />

      <section className="flex flex-col items-center justify-center w-full h-full px-10 py-20 ">
        <form className="flex flex-col w-full gap-4 xl:w-1/2" onSubmit={handlePdfReport}>
          <div className="h-full">
            <span className="text-primary">Select Vehicle</span>
            <InputSelect
              required={true}
              selectionOptions={vehicleList}
              setSelectedOption={handleSelectVehicle}
              allowMultiples={true}
              placeholder={"--Select vehicle(s) --"}
              name={"vehicle_id"}
              className="text-sm font-medium"
              allowSelectAll={true}
              itemName={"Vehicle"}
            />
          </div>
          <div className="flex flex-row justify-between w-full h-full gap-4">
            <div className="w-full">
              <label className="text-primary">Start Date</label>
              <input
                required
                type="date"
                className="border-2 border-secondary input-field"
                name="startDate"
                id="startDate"
                min={minimumDate}
                max={maximumDate}
                onChange={handleDateRange}
                value={dateRange?.startDate}
              />
            </div>

            <div className="w-full">
              <label className="text-primary">End Date</label>
              <input
                required
                type="date"
                className="border-2 input-field border-secondary"
                name="endDate"
                id="endDate"
                min={minimumDate}
                max={maximumDate}
                onChange={handleDateRange}
                value={dateRange?.endDate}
              />
            </div>
          </div>

          <div className="flex justify-end">
            <button className="btn-primary" type="submit">
              Generate Report
            </button>
          </div>
        </form>
      </section>
    </Layout>
  );
}
