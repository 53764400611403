import React, { useState, useMemo, useRef } from 'react';

import { AlertDialog,Layout, PrimaryHeader, NoRecords, OnError } from "../../components";
import { isValidArrayWithData,dateTimeMidnight } from "../../core/utilities";
import TablesSkeleton from "../../skeletons/TablesSkeleton";
import InputSelect from '../../components/InputSelect';
import Api from "../../api";
import DateRangeSelect from "../../components/date/DateRangeSelect";


export default function VehiclesTripsReport() {

  const { data: vehicles = [], isLoading, isError, error } = Api.vehicles.useGetAllVehiclesQuery();
  const vehiclesWithTrips = vehicles.length > 0 ? vehicles.filter(vehicle => vehicle.numberOfTrips > 0) : null;
  const vehiclesFound = isValidArrayWithData(vehiclesWithTrips);


  return isError ? (
    <OnError error={error} />
  ) : isLoading ? (
    <TablesSkeleton isLoading={isLoading} title="Vehicle Trip Report"  />
  ) : vehiclesFound ? (
    <DisplayVehiclesTripsReportCreate vehiclesWithTrips={vehiclesWithTrips} />
  ) : <NoRecords title="Vehicle Trip Report" message="No Vehicles found, trip reports cannot be generated."/>
      
  ;

 }


function DisplayVehiclesTripsReportCreate({ vehiclesWithTrips }){
  const selectInputRef = useRef();
  const [dateRange, setDateRange] = useState({startDate: '', endDate: ''});
  const [selectedVehicles, setSelectedVehicles] = useState(null);
  const [alertInfo, setAlertInfo] = useState({ show: false });
  const [isLoadingInfo, setIsLoadingInfo] = useState({ status: false });
  const [postTripReport] = Api.vehicles.usePostVehiclesTripsReportMutation();


  const vehicleList = useMemo(() => {
    return vehiclesWithTrips
      .map((vehicle) => ({ value: vehicle?.id, label: vehicle?.vehicleRegistrationNumber, id: vehicle.id, model: vehicle?.makeAndModel }))
  }, [vehiclesWithTrips])
  
  const today = new Date();
  const maximumDate = today.toISOString().split('T')[0];
  const oneYearAgo = new Date(today);
    oneYearAgo.setFullYear(today.getFullYear() - 1);
  const minimumDate = oneYearAgo.toISOString().split('T')[0];
  
  const resetForm = () => { 
    setDateRange({startDate: '', endDate: ''});
    setSelectedVehicles(null);
    selectInputRef?.current?.select?.clearValue();
  }

    const handlePdfReport= async (e)=>{
      e.preventDefault();
      const vehicles = selectedVehicles.map((vehicle) => { return { id: vehicle.id }; });
      const { startDate, endDate } = dateRange;
      const reportInfo = {
        vehicles, dateRange: {
          startDate: dateTimeMidnight(startDate),
        endDate}
      } // Create a FormData object from the form
      if(!isValidDateRange(new Date(dateRange?.startDate), new Date(dateRange?.endDate))){
        setDateRange({startDate: '', endDate: ''});
        setAlertInfo({ show: true, message: "Date range cannot be more than 1 year" });
        return 
      }else{
        try {
          console.log({reportInfo});
          setIsLoadingInfo({ status: true, text: "Processing Vehicle Trip Report" });
          const response = await postTripReport(reportInfo).unwrap();  // Send the FormData instance
          setIsLoadingInfo({ status: false});
          console.log({response});

          if (response.success) {
            resetForm(); // reset form
            setAlertInfo({ show: true, message: "Trip report has been generated and will be emailed to you shortly" });
          }else{
            console.log({response});
            setAlertInfo({ show: true, message: "Report could not be generated, please try again" });
          }
        } catch (error) {
          setIsLoadingInfo({ status: false });
       
          console.log(error);
          setAlertInfo({ show: true, message: "Report could not be generated, please try again" });
          if (error.status === 403) {
            window.location.reload();
          }
        }
      }
        
    }


  const isValidDateRange = (start, end) => start <= end && end - start <= 365 * 24 * 60 * 60 * 1000;
  
  const onDateRangeChange = (dateRange) => { 
    console.log(dateRange);
  }


    const handleDateRange = (e)=>{
      const {name, value} = e.target;
      if (name === 'startDate' && dateRange?.endDate && value > dateRange?.endDate) {
          setAlertInfo({ show: true, message: "Start date cannot be greater than end date" });
          setDateRange({...dateRange, startDate:"",isValid: false});
      } else if (name === 'endDate' && dateRange?.startDate && value < dateRange?.startDate) {
          setAlertInfo({ show: true, message: "End date cannot be less than start date" });
          setDateRange({...dateRange, endDate:"", isValid: false});
      } else {
        setDateRange({ ...dateRange, [name]: value, isValid: true});
      }
    }

    const handleSelectVehicle = (data)=>{
      setSelectedVehicles(data);
    }
      
  // console.log({ vehicles: vehiclesWithTrips });
  return (
    <Layout isLoading={isLoadingInfo.status} loadingText={ isLoadingInfo.text}>
      <PrimaryHeader title="Vehicle Trip Report">
      </PrimaryHeader>
      <AlertDialog show={alertInfo.show} alertMessage={alertInfo.message} onClick ={() => setAlertInfo({show:false})} />

      <section className="flex items-center justify-center px-10 pt-32">
        <form className='flex flex-col w-full gap-4 xl:w-2/3' onSubmit={handlePdfReport}>
            <div className=''>
              <span className='text-primary'>Select Vehicle</span>
              <InputSelect
   ref={selectInputRef}
                required={true}
                selectionOptions={vehicleList}
                setSelectedOption={handleSelectVehicle}
                allowMultiples={true}
                placeholder={"--Select vehicle(s) --"}
                name={"vehicle_id"}
                className="text-sm font-medium"
                allowSelectAll={true}
                itemName={"Vehicle"}
            />
          </div>
          {/*
          <div className='flex flex-row justify-between w-full gap-4'>
            {/*<DateTimeRangeSelect onChange={() => alert("Changes")} noFutureDates={true} value={{ start: "", end: "" }} key={""} /> /}
            <DateRangeSelect value={dateRange} noFutureDates={true} minDateOneYear={true} onDateRangeChange={onDateRangeChange} />
          </div>
          */}
          <div className='flex flex-row justify-between w-full gap-4'>
              <div className="w-full">
                <label className='text-primary'>Start Date</label>
                <input required type="date" className="border-2 border-secondary input-field" name='startDate' id='startDate' min={minimumDate} max={maximumDate} onChange={handleDateRange} value={dateRange?.startDate} />
              </div>

              <div className="w-full">
                <label  className='text-primary'>End Date</label>
                <input required type="date" className="border-2 input-field border-secondary" name='endDate' id='endDate' min={minimumDate} max={maximumDate} onChange={handleDateRange} value={dateRange?.endDate}/>
              </div>
          </div>

          <div className='flex justify-end'>
            <button className="btn-primary" type="submit">Generate Report</button>
          </div>

          
        </form>
     
      </section>
  
    </Layout>
  );
};

