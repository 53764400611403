import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from "react-chartjs-2";


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineGraph = ({ title, borderColor, graphData = {} }) => {

  const {fill,tension, borderWidth} = {fill:false,tension:0.1, borderWidth: 2};
  
  const {labels =[], values =[]} = graphData;
    const data = {
        labels, 
        datasets: [{
            label: title,
            data: values,
            fill: fill,
            borderColor,
            tension: tension,
            borderWidth: borderWidth
        }]
      };

      const config = {
          responsive: true,
          plugins: {
            legend: {
              display: false,
              position: "top"
            },
            title: {
              display: title ?  true : false,
              text: title,
              position: "left"
            }

        },
        scales: {
            x: {
              grid: {
                display: false
              }
            },
            y: {
              grid: {
                display: false
              }
            }
          }
      };
    return ( 
        <div className='mx-auto '>
            <Line data={data} options={config} />
        </div>
     );
}
 
export default LineGraph;