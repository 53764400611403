import React from "react";

function ConfirmationDialog({ onNo, onYes, show, confirmationMessage }) {
  return show ? <ShowConfirmation onNo={onNo} onYes={onYes} confirmationMessage={confirmationMessage} /> : "";
}

function ShowConfirmation({ onNo, onYes, confirmationMessage }) {
  return (
    <div className="modal-dialog">
      <div className="flex items-center justify-center h-screen">
    <div id="popup-modal" tabIndex="-1" className="flex-col justify-center p-4 text-primary">
      <div className="w-full h-full max-w-md opacity-100 md:h-auto">
        <div className="relative rounded-lg shadow bg-background ">
          <button
            type="button"
            onClick={onNo}
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-toggle="popup-modal"
          >
            <svg aria-hidden="true" className="w-5 h-5 fill-red-500"  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="p-6 text-center">
            <svg aria-hidden="true" className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
            <h3 className="mb-5 text-lg font-normal text-primary">{confirmationMessage}</h3>

            <div className="flex justify-center gap-8">
                <button
                  data-modal-toggle="popup-modal"
                  type="button"
                  onClick={onYes}
                  className="btn-primary"
                >
                  Yes, I'm sure
                </button>
                <button
                  data-modal-toggle="popup-modal"
                  type="button"
                  onClick={onNo}
                  className="border btn-secondary border-primary/40 hover:border-primary/40 hover:shadow-xl"
                >
                  No, cancel
                </button>

              
            </div>
          </div>
        </div>
      </div>
        </div>
        </div>
      </div>
  );
}

export default ConfirmationDialog;
