import React, { useState, useEffect } from "react";
import { Layout, NoRecords,OnError } from "../../components";
import GraphsSkeleton from "../../skeletons/GraphsSkeleton";
import { Link } from "react-router-dom";
import { BarGraph, LineGraph } from "../../components/charts";
import { isValidArrayWithData, stringToNumber,stringToCurrency,dateMonthYearDisplay } from "../../core/utilities";
import useHasAccessToFeature from "../../hooks/useHasAccessToFeature";
import UserRoleAccess from "../../constant/userRoleAccess";

import Api from "../../api";

/* Sample Code Data
const data = {
  name: "Jonas",
  surname: "Mkansi",
  tel: "0123456789",
  driverId: "08",
  driving_style: {
    driver_score: "75%",
    harsh_braking_count: "80%",
    harsh_acceleration_count: "89%"
  },
  engine_load_time: {
    title: "Throttle Position Time Spend (%)",
    data: {
      "0 - 50": 52,
      "51 - 75": 21,
      "76 - 100": 27
    }
  },
  throttle_position_time: {
    title: "RPM Time Spend (%)",
    data: {
      "0 - 50": 90,
      "51 - 75": 10,
      "76 - 100": 0
    }
  },
  RPM_time_spend: {
    title: "Car Speed Time (%)",
    data: {
      "0 - 2000": 48,
      "2001 - 4000": 51,
      "4001 - 7000": 1
    }
  },
  car_speed_time: {
    title: "Car Speed Time (%)",
    data: {
      "more than 140": 2,
      "0 - 139": 98
    }
  }
};
*/
export default function FuelAnalysis() {

  const fuelAnalysis = [];

  const { hasAccess: hasAccessToImportStdAnalysis } = useHasAccessToFeature(UserRoleAccess.canImportStdFuelAnalysis);
  const { data: customerMonthlyAnalysis = [], isLoading, isError, error } = Api.fuelAnalytics.useGetCustomerLastTwelveMonthsFuelAnalyticsQuery();
  const customerMonthlyAnalysisFound = isValidArrayWithData(customerMonthlyAnalysis);
  
  console.log({customerMonthlyAnalysis})
  return isError ? (
    <OnError error={error} />
  ) : isLoading ? (

    <GraphsSkeleton isLoading={isLoading} loadingText="Loading Fuel Analysis" graphCount={3} />

  ) : customerMonthlyAnalysisFound ? (
    <DisplayFuelAnalysis fuelAnalysis={fuelAnalysis} customerMonthlyAnalysis={customerMonthlyAnalysis} canImportStdAnalysis={hasAccessToImportStdAnalysis} />
  ) : (
        <NoRecords title="Fuel Analysis" message="No Fuel Analysis found.">
          <ImportStdAnalysisData canImportStdAnalysis={hasAccessToImportStdAnalysis} />
    </NoRecords>
  );
}

function DisplayFuelAnalysis({ fuelAnalysis, canImportStdAnalysis, customerMonthlyAnalysis }) {
  
  const [distanceDriven, setDistanceDriven] = useState({});
  const [fuelQuantityUsed, setFuelQuantityUsed] = useState();
  const [maxFuelCost, setMaxFuelCost] = useState({});
  const [kmsPerLiter, setKmsPerLiter] = useState();
  const [averageKmsPerLiters, setAverageKmsPerLiter] = useState();
  const [averageFuelCost, setAverageFuelCost] = useState();
  const [totalFuelCost, setTotalFuelCost] = useState();

  

  useEffect(() => {
  

    const setupAnalysisData = () => { 
      const distance = { labels: [], values: [] };
      const fuel = { labels: [], values: [] };
      const costPerKilometer = { labels: [], values: [] };
      let totalFuel = 0;
      let averageFuelCost = 0;
      let totalKmPerLiter = 0;
      let maxFuelCost = 0;
      customerMonthlyAnalysis.forEach((analysis) => {
        
        const displayDate = dateMonthYearDisplay(analysis.monthly_date);
        const kmPerLiter = analysis.kilometers / analysis.fuel_quantity;
        distance.labels.push(displayDate);
        fuel.labels.push(displayDate);
        costPerKilometer.labels.push(displayDate);
        
        distance.values.push(analysis.kilometers);
        fuel.values.push(analysis.fuel_quantity);
        costPerKilometer.values.push(kmPerLiter);
        
        averageFuelCost = averageFuelCost + analysis.average_fuel_cost;

        
        totalFuel = totalFuel + analysis.total_fuel_cost;
        totalKmPerLiter = totalKmPerLiter + kmPerLiter;

        if (analysis.maximum_fuel_cost > maxFuelCost) {
          maxFuelCost = analysis.maximum_fuel_cost;
        }

      });

      setDistanceDriven(distance);
      setFuelQuantityUsed(fuel);
      setKmsPerLiter(costPerKilometer);
      setTotalFuelCost(totalFuel);
      setAverageFuelCost(averageFuelCost / customerMonthlyAnalysis.length);
      setAverageKmsPerLiter(totalKmPerLiter / customerMonthlyAnalysis.length);
      setMaxFuelCost(maxFuelCost);
    }
    setupAnalysisData();
    
  }, [customerMonthlyAnalysis])
  
  console.log({ distanceDriven,costPerHundredKms: kmsPerLiter });
  return (
    <Layout>
      <header className="flex flex-row justify-between px-5 rounded-sm shadow-lg bg-other col-span-full xl:col-span ">
        {/* link to driver listing page and driver name */}
        <div className="flex flex-row items-center gap-2 py-4 font-semibold text-primary">
          Fuel Analysis 
        </div>
        <div className="flex">
        <div className="flex flex-row items-center gap-2 py-4 font-semibold text-primary">
         <ImportStdAnalysisData canImportStdAnalysis={canImportStdAnalysis} />
         </div>
         </div>
        {/* //TODO: Removed by Mohau till a report exists */}
        {/* download button */}
        {/*
        <div className="flex items-center">
          <button className="inline-flex items-center justify-center w-full gap-1 text-other btn-primary">
            <DownloadIcon classes="h-4 w-4 fill-current" /> Download Report (PDF)
          </button>
        </div>
  */}
      </header>

      <div className="grid gap-4 md:grid-cols-2">
        <section className="section-container">
          <div className="grid grid-cols-4 gap-8 mx-auto">
            <h4>
              Avg Km/L <span className="text-brand">{stringToNumber(averageKmsPerLiters,2)}</span>
            </h4>
            <h4>
              Avg Fuel <span className="text-brand">{stringToNumber(averageFuelCost,2)}</span>
            </h4>
            <h4>
              Max Fuel Cost <span className="text-brand">{stringToCurrency(maxFuelCost,2)}</span>
            </h4>
            <h4>
              Total Fuel Cost <span className="text-brand">{stringToCurrency(totalFuelCost,2)}</span>
            </h4>
          </div>

          <LineGraph title={"KPL (SA)"}  borderColor={"#10b981"}  graphData={kmsPerLiter} />
        </section>

        <section className=" section-container">
          <BarGraph title={"Litres Consumed"} backgroundColor={["#fde047"]} graphData={fuelQuantityUsed}  />
        </section>

        <section className=" section-container">
          <BarGraph title={"Km Driven"} backgroundColor={["#60a5fa"]} graphData={distanceDriven}  />
        </section>
      </div>
    </Layout>
  );
}

function ImportStdAnalysisData({canImportStdAnalysis}) {
  
  return (
    <>
      {canImportStdAnalysis ?
      <Link to="/insights/fuelAnalysis/std-import-data">
  <button type='button' className='inline-flex items-center gap-4 px-3 mx-3 text-sm border rounded-md border-brand text-primary hover:text-brand py-1.5'>
    Import Standard Analysis
        </button>
      </Link> : null }
      </>
  );
}
