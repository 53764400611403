import { api } from "./api";

export const simCardsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllSimCards: builder.query({
      query: () => "/simCards",
      keepUnusedDataFor: 60
    }),
    getSimCard: builder.query({
      query: (id) =>`/simCards/${id}`
    }),
    getSimCardLogs: builder.query({
      query: (id) => `/simCards/logs/${id}`
    }), 
    getSimCardUsageLogs: builder.query({
      query: (id) => `/simCards/usageLogs/${id}`
    }),  
    getSimCardAirtimeDailyUsage: builder.query({
      query: (id) => `/simCards/airtimeDailyUsage/${id}`
    }),  
    getSimCardDataDailyUsage: builder.query({
      query: (id) => `/simCards/dataDailyUsage/${id}`
    }),      
    getSimCardAllSmsSentOut: builder.query({
      query: (id) =>`/simCards/smsSentOut/${id}`
    })
  })
});

export const { useGetAllSimCardsQuery, useGetSimCardQuery, useGetSimCardAllSmsSentOutQuery, useGetSimCardLogsQuery, useGetSimCardUsageLogsQuery,
useGetSimCardAirtimeDailyUsageQuery, useGetSimCardDataDailyUsageQuery} = simCardsApi;