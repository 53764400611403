import React from 'react'

export default function ServiceCardLineDisplay({heading,details}) {
    return (
        <p>
        {`${heading}:`} <span className="font-semibold text-brand">{details}</span>
        </p>
  );
}

export  function ServiceCardConditionalLineDisplay({heading,details}) {

    return (
        <div>
            {
                details ? (<ServiceCardLineDisplay heading={heading} details={details} />) : null
            }
        </div>
    );
}
