import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Layout, PrimaryHeader, OnError, } from "../../components";
import { selectCurrentUser } from "../../features/auth/authSlice";
import { isValidEmailAddress } from "../../core/utilities";
import Api from "../../api";

export default function UserRegistration() {
  const { data: existingUsersEmails, isError, error } = Api.userRegistrations.useGetForRegistrationAllExistingEmailAddressesQuery();

  //console.log(existingUsersEmails);

  return (isError ?  <OnError error={error} />
:   <NewUserRegistration existingUsersEmails={existingUsersEmails}  />   
  );
}

function NewUserRegistration({existingUsersEmails}) {
  
  const [addNewUser] = Api.users.useAddUserMutation();
  const [emailAddress, setEmailAddress] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [designation, setDesignation] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [errors, setErrors] = useState({})
  const [emailExists, setEmailExists] = useState(false);
  const user = useSelector(selectCurrentUser);
  const navigate = useNavigate();

  const handleEmailAddressChange = (event) => {
    const email = event.target.value;
    const exists = existingUsersEmails.find((user) => user.email_address === email)
    setEmailAddress(event.target.value);
    setEmailExists(exists);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleDesignationChange = (event) => {
    setDesignation(event.target.value);
  };

  const handleMobileNumberChange = (event) => {
    setMobileNumber(event.target.value);
  };

  const [loading, setLoading] = useState(false);
  const loadText = 'Adding User...';


  const submitNewUserToApi = async(credentials) => {

    return new Promise((resolve, reject) => {
      
      
    try {
      if (Object.keys(errors).length === 0) {
        // submit the form
      
        addNewUser(credentials).unwrap()
          .then(addResults => {

            if (addResults) {
          
              resolve(true);
            }
            else {
              resolve(false)
            }
          }).catch(error => {
            console.error(error);
            reject(error);
          }) 
          
      }
    }
    catch (error) {
      console.error(error);
      reject(error);
    }


    })
    
  }

  const handleSubmitNewUser = async(event) => {
    event.preventDefault();
    const errors = {};
    if (emailAddress.trim() === '') {
      errors.emailAddress = 'Email address is required';
    } else if (!isValidEmailAddress(emailAddress)) {
      errors.emailAddress = 'Please enter a valid email address';
    }
    else if (existingUsersEmails.find((user) => user.email_address === emailAddress)) {
      errors.emailAddress = 'User already exists in the registrations list';
    }
    if (firstName.trim() === '') {
      errors.firstName = 'First name is required';
    }
    if (lastName.trim() === '') {
      errors.lastName = 'Last name is required';
    }
 
    setErrors(errors);

    setLoading(true);

    try {
      if (Object.keys(errors).length === 0) {
        // submit the form
      
        const addResults = await submitNewUserToApi({ firstName, lastName, emailAddress, designation, mobileNumber, createdBy: user.id, createdAt: new Date() });
      
      
        if (addResults) {
          navigate("/users/registrations")
        }
        else {
          console.error(addResults);
        }
      }
    }
    catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

    
  return (
    <Layout isForegroundProcess={loading} loadingText={loadText}>
      <PrimaryHeader title="Add New User">
        <div className="flex">
          </div>
      </PrimaryHeader>
      
      <section className="flex flex-col">
       


        <div className="input-card">

        <form onSubmit={handleSubmitNewUser} className="flex flex-wrap items-center">
          {
            errors.response ? (<div className="flex w-full mb-4 text-primary">
              <p className="px-2 text-sm text-red-500">{errors.response}</p>)
            </div>): null
          }
        <div className="flex w-full mb-4 text-primary">
        <label htmlFor="emailAddress" className="mr-7">
          Email Address:
        </label>
        <input
          type="email"
          id="emailAddress"
          name="emailAddress"
          value={emailAddress}
          onChange={handleEmailAddressChange}
          className="w-full max-w-md px-2 py-1 input-field"
          />
{errors.emailAddress ? (<p className="pl-1 text-sm text-red-500 ">{errors.emailAddress}</p>) : null}             
      </div>

  
        <div className="flex w-full mb-4 text-primary">
          <label htmlFor="firstName" className="mr-12">
            First Name:
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={firstName}
            onChange={handleFirstNameChange}
            className="w-full max-w-md px-2 py-1 input-field"
            />
           {errors.firstName ? (
          <p className="pl-2 text-sm text-red-500">{errors.firstName}</p>) : null}

          <label htmlFor="lastName" className="pl-6 mr-7">
            Last Name:
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={lastName}
            onChange={handleLastNameChange}
            className="w-full max-w-md px-2 py-1 input-field"
            />
           {errors.lastName ? (
          <p className="pl-2 text-sm text-red-500">{errors.lastName}</p>) : null}            
        </div>
  

      <div className="flex w-full mb-4 text-primary ">
        <label htmlFor="designation" className="mr-10">
          Designation:
        </label>
        <input
          type="text"
          id="designation"
          name="designation"
          value={designation}
          onChange={handleDesignationChange}
          className="w-full max-w-md px-2 py-1 input-field"
        />
      </div>
          
      <div className="flex w-full mb-4 text-primary ">
        <label htmlFor="designation" className="mr-3">
          Mobile Number:
        </label>
        <input
          type="tel"
          id="mobileNumber"
          name="mobileNumber"
          value={mobileNumber}
          onChange={handleMobileNumberChange}
          className="w-full max-w-md px-2 py-1 input-field"
        />
      </div>          
          
      <div className="w-1/4 py-4 mx-4 text-primary">
          <button type="submit" className="w-1/2 btn-primary">
            Add New User
          </button>
      </div>
        </form>
          </div>
        
      </section>
    </Layout>
  );
  
}
