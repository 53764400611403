import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useSelector } from "react-redux";
import { GoogleMap, Polyline, LoadScript,Marker } from '@react-google-maps/api';
import systemConfig from "../../../config/systemConfiguration";
import { selectCurrentTheme } from "../../../features/settings/settingsSlice";
import { dateTimeDisplay } from "../../../core/utilities";
import "./map.css";

const { apiKey } = systemConfig.maps.google;

const containerStyle = {
  width: '100%',
  height: '800px',
};
const defaultMapStyleId = "cf5b3082e4d380a4"; // dark 55208ae8c875e959 ; light cf5b3082e4d380a4

const SOUTHERN_AFRICA_BOUNDS = {
  north: -14.00869,
  south: -37.9887,
  west: 8.351732,
  east: 40.4785087
};


const googleMapOptions = (theme) => {
  
  return {
    clickableIcons: false,
    keyboardShortcuts: false,
    mapTypeControl: false,
    zoomControlOptions: { position: 3 },
    streetViewControl: false,
    restriction: {
      latLngBounds: SOUTHERN_AFRICA_BOUNDS,
      strictBounds: false
    },
    mapId: theme === "dark-theme" ? "55208ae8c875e959" : defaultMapStyleId
  };
};



const TripMap = ({ tripPoints }) => {
  const mapRef = useRef();
  const theme = useSelector(selectCurrentTheme);
  const [tripPointCoordinates, setTripPointCoordinates] = useState([]);
  const startPoint = tripPoints && tripPoints.length > 0 ? tripPoints[0] : null;
  const endPoint = tripPoints && tripPoints.length > 0 ? tripPoints[tripPoints.length - 1] : null;
  const defaultCenter = endPoint ? { lat: (endPoint.latitude+startPoint.latitude)/2, lng: (endPoint.longitude + startPoint.longitude)/2 } : { lat: 0, lng: 0 };
  const options = useMemo(() => googleMapOptions(theme), [theme]);

  const onLoad = useCallback((map) => (mapRef.current = map), []);

  useEffect(() => {
    const snapRouteToRoad = async () => {
      const snappedCoords = [];


      ///Snapped
      /*
      let pathData;    
       
      for (const coord of tripPoints) {
          pathData = pathData ? `${pathData}|${coord.latitude},${coord.longitude}` : `${coord.latitude},${coord.longitude}`;
      }
      console.log({ pathData,len: tripPoints.length });
        const response = await fetch(
          `https://roads.googleapis.com/v1/snapToRoads?path=${pathData}&key=${apiKey}`
        );

        const data = await response.json();
      if (data?.snappedPoints && data.snappedPoints.length > 0) {
        let i = 0;
        for (const coord of data.snappedPoints) {
          const snappedPoint = i === 0 ? { lat: startPoint.latitude, lng: startPoint.longitude } : i === (data.snappedPoints.length - 1) ? {lat: endPoint.latitude, lng: endPoint.longitude} : {lat: coord.location.latitude, lng: coord.location.longitude};
          snappedCoords.push(snappedPoint);
          i++;
        }
        }
*/
      //Not Snapped

        for (const coord of tripPoints) {
          const snappedPoint = {lat: coord.latitude, lng: coord.longitude};
          snappedCoords.push(snappedPoint);
        }
        


      setTripPointCoordinates(snappedCoords);
    };

    snapRouteToRoad();
  },[]);

  // console.log({ aa: window.location })
  return (
    <LoadScript googleMapsApiKey={apiKey}>
      <GoogleMap mapContainerStyle={containerStyle} center={defaultCenter} zoom={10} options={options} onLoad={onLoad} mapContainerClassName="map-container" >
        <Polyline
          path={tripPointCoordinates}
          options={{
            strokeColor: '#0000FF',
            strokeOpacity: 1,
            strokeWeight: 3,
            geodesic: true,
          }}
        />
        <Marker
            key={"start"}
            position={{ lat: startPoint.latitude, lng: startPoint.longitude }}
          label={`Start`} // Marker label
          title={`Start on ${dateTimeDisplay(startPoint.data_timestamp)}`}
        />
                <Marker
            key={"end"}
            position={{ lat: endPoint.latitude, lng: endPoint.longitude }}
          label={`End`} // Marker label
          title={`End on ${dateTimeDisplay(endPoint.data_timestamp)}`}
          
        />
        {tripPoints.map((tripPoint) => {
          if (tripPoint.event_type === 255) {
            return (<Marker
              key={tripPoint.id}
              position={{ lat: tripPoint.latitude, lng: tripPoint.longitude }}
            label={`${tripPoint.gps_speed}`} // Marker label
            title={`Over speeding at ${tripPoint.gps_speed}`} 
          />)
          }
          else
          {
            return null;
            }
        })}
      </GoogleMap>
    </LoadScript>
  );
};

export default TripMap;
