import { api } from "./api";


const vehicleFuelApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllVehiclesFuelTransactions: builder.query({
      query: () => "/vehiclesFuel"
    }),
    createVehiclesFuelTransactions: builder.mutation({
      query: (vehiclesTransactions) => ({
        url: "/vehiclesFuel/transactions",
        method: "POST",
        body: {vehiclesTransactions}
      })
    })
  })
});

export const { useGetAllVehiclesFuelTransactionsQuery,
  useCreateVehiclesFuelTransactionsMutation } = vehicleFuelApi;

export default vehicleFuelApi;