import React from "react";
import { useLocation } from "react-router-dom";


import ExpandedMultipleOptionsMenu from "./ExpandedMultipleOptionsMenu";
import MinimizedMultipleOptionsMenu from "./MinimizedMultipleOptionsMenu";

function SidebarMultipleOptionsMenu({ menuOptions, sidebarExpanded }) {
  const { isRoot, rootPath, menuOptions: subMenuOptions, menuIcon, menuName } = menuOptions;
  const location = useLocation();

  const { pathname: pathName } = location;

  const isWithinRootPath = (pathname) => {
    return pathname === (isRoot ? "/" : `/${rootPath}`) || pathname.includes(rootPath);
  };


  
  return (
    <div className="">

      {sidebarExpanded ? <ExpandedMultipleOptionsMenu pathName={pathName}
        menuName={menuName}
        menuIcon={menuIcon}
        isActiveItem={isWithinRootPath(pathName)}
        subMenuOptions={subMenuOptions}
        sidebarExpanded={sidebarExpanded} />
        : <MinimizedMultipleOptionsMenu pathName={pathName}
          menuName={menuName}
          menuIcon={menuIcon}
          isActive={isWithinRootPath(pathName)}
          subMenuOptions={subMenuOptions} />}
    </div>
  );
}

export default SidebarMultipleOptionsMenu;
