import React from "react";

import { Layout, OnError } from "../../components";
import DriverProfileSkeleton from "../../skeletons/DriverProfileSkeleton";
import { Link, useParams } from "react-router-dom";
import LeftArrowIcon from "../../components/icons/LeftArrowIcon";
import DownloadIcon from "../../components/icons/DownloadIcon";
import PieChart from "../../components/charts/PieChart";
import Api from "../../api";

export default function DriverProfile() {
  //data can be finished based on the id from params

  const { driverId } = useParams();
  const { data, isLoading , isError, error} = Api.drivers.useGetDriverQuery(driverId);

  const driver = data ? data[0] : undefined;

  console.log({ isLoading, driver });

  /* Sample Code Data
  const data = {
    name: "Jonas",
    surname: "Mkansi",
    tel: "0123456789",
    status: "online",
    driverId: "08",
    driving_style: {
      driver_score: "75%",
      harsh_braking_count: "80%",
      harsh_acceleration_count: "89%"
    },
    engine_load_time: {
      title: "Throttle Position Time Spend (%)",
      data: {
        "0 - 50": 52,
        "51 - 75": 21,
        "76 - 100": 27
      }
    },
    throttle_position_time: {
      title: "RPM Time Spend (%)",
      data: {
        "0 - 50": 90,
        "51 - 75": 10,
        "76 - 100": 0
      }
    },
    RPM_time_spend: {
      title: "Car Speed Time (%)",
      data: {
        "0 - 2000": 48,
        "2001 - 4000": 51,
        "4001 - 7000": 1
      }
    },
    car_speed_time: {
      title: "Car Speed Time (%)",
      data: {
        "more than 140": 2,
        "0 - 139": 98
      }
    }
  };
  */

  return isError ? <OnError error={error} /> :
    isLoading ? <DriverProfileSkeleton  isLoading={isLoading} /> : <DisplayDriverProfile driver={driver} />;
}

function DisplayDriverProfile({ driver, isLoading=false }) {

  const driverImage = driver.image_url|| "/person_placeholder.png";
  return (
    <Layout isLoading={isLoading} loadingText="Loading Driver">
      <header className="flex flex-row justify-between rounded-sm shadow-lg bg-other col-span-full xl:col-span">
        {/* link to driver listing page and driver name */}
        <div className="flex flex-row items-center gap-2 px-5 py-4 font-semibold text-primary">
          {/* link to drivers listing page */}
          <div className="inline-flex items-center group">
            <LeftArrowIcon classes="h-4 w-4 group-hover:-translate-x-2 fill-current" />
            <Link to="/drivers">Drivers /</Link>
          </div>
          {/* name and status icon */}
          <div className="inline-flex items-center">
            <h2 className="inline-flex gap-2 font-normal">
              {driver?.first_name} <span className="hidden md:block">{driver?.last_name}</span>
            </h2>
            <div className=" inline-flex items-center gap-1 p-1.5 text-sm rounded-l-xl rounded-t-md bg-other text-primary">
              {/* driver does not have a status, only vehicles
              <div className={`w-4 h-4 rounded-full ${driver?.status==="online" ? "bg-green-500" : driver?.status==="pending" ? "bg-yellow-500" : "bg-red-500"}`}></div>
              */}
            </div>
          </div>
        </div>
        {/* download button */}
        <div className="flex items-center">
          {/* TODO Once the driver report has been done, enable the button below */}
          {false ? <button
            disabled
            className="text-other inline-flex gap-1 items-center justify-center w-full px-3 py-2 transition-colors duration-300 transform rounded-md hover:translate-y-0.5 bg-brand focus:outline-none sm:w-auto sm:mx-1 hover:shadow-md"
          >
            <DownloadIcon classes="fill-current" /> Download Report (PDF)
          </button> : null
          }
        </div>
      </header>

      <section className="grid grid-cols-1 mt-8 overflow-hidden gap-y-8 lg:gap-8 lg:grid-cols-3 text-primary">
        <div className="flex flex-col w-full gap-3">
          <div className="flex flex-col gap-2 ">
            <h2 className="font-semibold opacity-70">Driver Details</h2>

            <div className="flex items-center gap-4 p-4 shadow bg-other ">
              <img
                className="object-cover object-center w-40 h-40 p-1 rounded-full ring-2 ring-primary group-hover:ring-secondary"
                src={driverImage}
                width={32}
                height={32}
                alt="driver profile"
              />

              <div className="font-semibold">
                <p>
                  Name:{" "}
                  <span className="font-normal">
                    {driver?.first_name} {driver?.last_name}
                  </span>
                </p>
                <p>
                  Phone Number: <span className="font-normal">{driver?.tel}</span>
                </p>
                <p>
                  Driver ID: <span className="font-normal">{driver?.identity_number}</span>{" "}
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <h2 className="font-semibold opacity-70 ">Driving Style</h2>
            <div className="p-4 font-semibold shadow bg-other ">
              <p>
                Driver Score: <span className="font-normal">{driver?.driving_style?.driver_score}</span>
              </p>
              <p>
                Harsh Braking Counts: <span className="font-normal">{driver?.driving_style?.harsh_braking_count}</span>
              </p>
              <p>
                Harsh Acceleration Counts: <span className="font-normal">{driver?.driving_style?.harsh_acceleration_count}</span>{" "}
              </p>
            </div>
          </div>
        </div>

        {/* pie chart */}
        <div className="flex flex-col col-span-2 gap-2">
          <h2 className="font-semibold opacity-70">Statistics</h2>
          <div className="grid grid-cols-1 gap-10 p-4 font-semibold shadow md:gap-4 md:grid-cols-2 2xl:grid-cols-4 xl:grid-cols-3 bg-other">
            {driver?.engine_load_time ? <PieChart chartTitle={driver?.engine_load_time?.title} chartData={driver?.engine_load_time?.data} /> : null}
            {driver?.throttle_position_time ? <PieChart chartTitle={driver?.throttle_position_time?.title}
              chartData={driver?.throttle_position_time?.data} /> : null}
            {driver?.RPM_time_spend ? <PieChart chartTitle={driver?.RPM_time_spend?.title} chartData={driver?.RPM_time_spend?.data} /> : null}
            {driver?.car_speed_time ? <PieChart chartTitle={driver?.car_speed_time?.title} chartData={driver?.car_speed_time?.data} /> : null}
          </div>
        </div>
      </section>
    </Layout>
  );
}
