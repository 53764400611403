import { MenuIcons } from "./SidebarMenuIcon";

export function initializeSidebarMenuFeatureAccess() {
  return { dashboard: [], vehicles: [], insights: [], services: [], users: [] };
}

export default class SidebarMenuOptions {
  constructor(featureAccess) {
    this.featureAccess = featureAccess;
  }

  dashboard() {
    return {
      isRoot: true,
      rootPath: "dashboard",
      menuIcon: MenuIcons.DASHBOARD,
      menuName: "Dashboard",
      menuOptions: [
        {
          name: "Real-Time Data",
          url: "/"
        }
      ]
    };
  }

  devices() {
    return {
      rootPath: "devices",
      menuIcon: MenuIcons.GPS,
      menuName: "Devices",
      menuOptions: [
        {
          name: "Customer Devices",
          url: "/devices"
        },
        {
          name: "Device Events",
          url: "/devices/events"
        },
        {
          name: "Device Sms Alerts",
          url: "/devices/sms-alerts"
        }
      ]
    };
  }

  vehicles() {
    return {
      rootPath: "vehicles",
      menuIcon: MenuIcons.VEHICLE,
      menuName: "Vehicles",
      menuOptions: [
        {
          name: "Overview",
          url: "/vehicles"
        },
        {
          name: "Battery State",
          url: "/vehicles/battery-state"
        },
        {
          name: "Approved Help Options",
          url: "/vehicles/approved-help-options"
        },
        {
          name: "Events Report",
          url: "/vehicles/vehicles-events-report"
        },
        {
          name: "Trips Report",
          url: "/vehicles/vehicles-trips-report"
        },
        {
          name: "Tracking Location Report",
          url: "/vehicles/vehicles-tracking-location-report"
        }
      ]
    };
  }

  insights() {
    return {
      rootPath: "insights",
      menuIcon: MenuIcons.INSIGHTS,
      menuName: "Insights",
      menuOptions: [
        {
          name: "Fuel Analysis",
          url: "/insights"
        },
        {
          name: "Driver Ratings",
          url: "/insights/driver-ratings"
        }
      ]
    };
  }

  services() {
    const servicesOptions = {
      rootPath: "services",
      menuIcon: MenuIcons.REPAIR_SERVICE,
      menuName: "Services",
      menuOptions: [
        {
          name: "Tickets Overview",
          url: "/services/tickets-overview"
        },
        {
          name: "Accident Management",
          url: "/services/accident-management"
        },
        {
          name: "Repairs & Maintenance",
          url: "/services/repairs-maintenance"
        },
        {
          name: "Licensing & Fines",
          url: "/services/licensing-fine-management"
        },
        {
          name: "Scheduled Services",
          url: "/services/scheduled-services"
        },
        {
          name: "Roadside Assistance",
          url: "/services/roadside-assistance"
        },
        {
          name: "Vehicle Inspection",
          url: "/services/vehicle-inspections"
        }
      ]
    };
    if (!this.featureAccess.services?.overview) {
      servicesOptions.menuOptions.shift();
    }

    return servicesOptions;
  }

  users() {
    return {
      rootPath: "users",
      menuIcon: MenuIcons.USER,
      menuName: "Users",
      menuOptions: [
        {
          name: "System Users",
          url: "/users"
        },
        {
          name: "User Registrations",
          url: "/users/registrations"
        }
      ]
    };
  }
}
