const DropDownArrow = ({style}) => {
    return ( 
        <div className="flex ml-2 shrink-0">
            <svg className={style} viewBox="0 0 12 12">
                <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
            </svg>
        </div>
     );
}
 
export default DropDownArrow;