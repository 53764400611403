import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import ParentLayout from "./components/ParentLayout";
import UserRoleAccess from "./constant/userRoleAccess";
import RequireAuth from "./features/auth/RequireAuth";

// Import pages

//dashboard
import Dashboard from "./pages/dashboard/Dashboard";
import DashboardCameraFeeds from "./pages/dashboard/DashboardCameraFeeds";
import DashboardLiveDataFeed from "./pages/dashboard/DashboardLiveDataFeed";
import VehicleHealthIssues from "./pages/dashboard/VehicleHealthIssues";
//scheduling and alert
import Alerts from "./pages/Alerts";
import Scheduling from "./pages/Scheduling";
// devices, DeviceEvents and DeviceSmsAlerts
import { Devices, DeviceEvents, DeviceSmsAlerts, DeviceDetails } from "./pages/device";
// driver
import DriverProfile from "./pages/driver/DriverProfile";
import Drivers from "./pages/driver/Drivers";
// vehicle
import {
  ApprovedHelp, VehicleDetails, Vehicles, VehiclesBatteries, VehiclesLocationsTrackingReport,
  VehiclesTripsReport, VehiclesEventsReport, VehicleTrip
} from "./pages/vehicle";
// insights
import DriverRatings from "./pages/insights/DriverRatings";
import FuelAnalysis from "./pages/insights/FuelAnalysis";
import ImportStdFuelAnalysis from "./pages/insights/ImportStdFuelAnalysis";
import VehicleInspection from "./pages/insights/VehicleInspection";
// settings
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Register from "./pages/Register";
import { AccidentManagement, LicensingFineManagement, RepairsMaintenance, RoadsideAssistance, ScheduledServices, VehicleInspections } from "./pages/services";
import TicketOverview from "./pages/services/TicketOverview";
//simCards
import {SimCards,SimCardDetails} from "./pages/simCard";
//users
import ScrollTo from "./components/ScrollTo";
import {Users, UserDetails, UserRegistration, UserRegistrations } from "./pages/user";

import ResetPassword from "./pages/ResetPassword";

function App() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <ScrollTo />
      {/* protected routes need to be created after connecting the login with the API */}
      <Routes>
        <Route path="/" element={<ParentLayout />}>
          <Route exact path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route element={<RequireAuth />}>
            <Route path="*" element={<Navigate to="/" />} />
            <Route exact path="/" element={<Dashboard />} />
            <Route exact path="/dashboard/camera-feeds" element={<DashboardCameraFeeds />} />
            <Route exact path="/dashboard/live-data-feed" element={<DashboardLiveDataFeed />} />
            <Route exact path="/dashboard/vehicle-health-issues" element={<VehicleHealthIssues />} />

            <Route exact path="/scheduling" element={<Scheduling />} />
            <Route exact path="/alerts" element={<Alerts />} />

            <Route exact path="/insights" element={<FuelAnalysis />} />
            <Route exact path="/insights/driver-ratings" element={<DriverRatings />} />
            <Route exact path="/insights/vehicle-inspection" element={<VehicleInspection />} />
            <Route element={<RequireAuth feature={UserRoleAccess.canImportStdFuelAnalysis} />}>
              <Route exact path="/insights/fuelAnalysis/std-import-data" element={<ImportStdFuelAnalysis />} />
            </Route>
          </Route>

          <Route element={<RequireAuth feature={UserRoleAccess.deviceList} />}>
            <Route exact path="/devices" element={<Devices />} />
            <Route exact path="/devices/:deviceId" element={<DeviceDetails />} />
            <Route exact path="/devices/events" element={<DeviceEvents />} />
            <Route exact path="/devices/sms-alerts" element={<DeviceSmsAlerts />} />
          </Route>

          <Route element={<RequireAuth feature={UserRoleAccess.simCardList} />}>
            <Route exact path="/sim-cards" element={<SimCards />} />
            <Route exact path="/sim-cards/:iccId" element={<SimCardDetails />} />
          </Route>
          <Route element={<RequireAuth />}>
            <Route exact path="/drivers" element={<Drivers />} />
            <Route path="/drivers/:driverId" element={<DriverProfile />} />

            <Route exact path="/vehicles" element={<Vehicles />} />
            <Route path="/vehicles/:vehicleId" element={<VehicleDetails />} />
            <Route path="/vehicles/battery-state" element={<VehiclesBatteries />} />
            <Route path="/vehicles/approved-help-options" element={<ApprovedHelp />} />
            <Route path="/vehicles/vehicle-trip/:vehicleId/:tripId" element={<VehicleTrip />} />
            <Route exact path="/vehicles/vehicles-events-report" element={<VehiclesEventsReport />} />
            <Route exact path="/vehicles/vehicles-trips-report" element={<VehiclesTripsReport />} />
            <Route exact path="/vehicles/vehicles-tracking-location-report" element={<VehiclesLocationsTrackingReport />} />
          </Route>
          <Route element={<RequireAuth feature={UserRoleAccess.serviceOverviewList} />}>
            <Route exact path="/services/tickets-overview" element={<TicketOverview />} />
          </Route>
          <Route element={<RequireAuth />}>
            <Route exact path="/services/accident-management" element={<AccidentManagement />} />
            <Route exact path="/services/repairs-maintenance" element={<RepairsMaintenance />} />
            <Route exact path="/services/licensing-fine-management" element={<LicensingFineManagement />} />
            <Route exact path="/services/roadside-assistance" element={<RoadsideAssistance />} />
            <Route exact path="/services/scheduled-services" element={<ScheduledServices />} />
            <Route exact path="/services/vehicle-inspections" element={<VehicleInspections />} />
          </Route>
          <Route element={<RequireAuth feature={UserRoleAccess.userList} />}>
            <Route exact path="/users" element={<Users />} />
            <Route element={<RequireAuth feature={UserRoleAccess.userView} />}>
              <Route exact path="/users/:userId" element={<UserDetails />} />
            </Route>
            <Route exact path="/users/registrations" element={<UserRegistrations />} />
            <Route exact path="/users/registrations/new-user" element={<UserRegistration />} />
          </Route>

          <Route path="/health/check" element={<div></div>} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
