import React, { useState } from "react";
import Api from "../../api";
import { useSynchronizeHubSpotTicketsMutation } from "../../api/rtk/hubspotTicketsApi";
import { AlertDialog, Filter, Layout, NoRecords, OnError, ServiceHeader } from "../../components";
import RepairMaintenanceCard from "../../components/cards/RepairMaintenanceCard";
import UserRoleAccess from "../../constant/userRoleAccess";
import { isValidArrayWithData } from "../../core/utilities";
import FilterOptions from "../../filter/FilterOptions";
import useHasAccessToFeature from "../../hooks/useHasAccessToFeature";
import ServicesSkeleton from "../../skeletons/ServicesSkeleton";

export default function RepairsMaintenance() {
  const { data: tickets = [], isLoading, isError, error } = Api.hubspotTickets.useGetAllBreakdownTicketsQuery();
  const [synchronizeTicketsData] = useSynchronizeHubSpotTicketsMutation();
  const { hasAccess: hasAccessToSynchronize } = useHasAccessToFeature(UserRoleAccess.serviceHubspotSynchronize);

  const ticketsFound = isValidArrayWithData(tickets);

  return isError ? (
    <OnError error={error} />
  ) : isLoading ? (
    <ServicesSkeleton isLoading={isLoading} title="Vehicle Repairs & Maintenance" />
  ) : ticketsFound ? (
    <DisplayTickets allTickets={tickets} hasAccessToSynchronize={hasAccessToSynchronize} synchronizeTicketsData={synchronizeTicketsData} />
  ) : (
    <NoRecords title="Vehicle Repairs & Maintenance" message="No Vehicle Repairs & Maintenance found." />
  );
}

function DisplayTickets({ allTickets, hasAccessToSynchronize, synchronizeTicketsData, isLoading = false }) {
  const [isFilter, setIsFilter] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [tickets, setTickets] = useState(allTickets);
  const [displayData, setDisplayData] = useState(tickets);
  const [hubspotSynchAlert, setHubspotSynchAlert] = useState({ show: false });
  const updateTickets = (tickets) => {
    setTickets(tickets);
    setDisplayData(tickets);
  };

  const [loading, setLoading] = useState(false);
  const loadText = 'Synchronizing Hubspots...';

  const handleTicketsSync = async () => {
    setLoading(true);
    try {
    const synchResponse = await synchronizeTicketsData().unwrap();
    if (synchResponse.success) {
      setHubspotSynchAlert({ show: true, message: "Hubspot and Dashboard have been synchronized." });
    } else {
      setHubspotSynchAlert({ show: true, message: "Hubspot and Dashboard could not be synchronized." });
    }
  } catch (error) {
    console.error("Error synchronizing tickets:", error); 
    setHubspotSynchAlert({ show: true, message: "An error occurred while synchronizing Hubspot." });
  } finally {
    setLoading(false);
  } 
  };

  const handleFilterDataChange = (filteredData) => {
    setIsFiltered(filteredData.length !== tickets.length);
    setDisplayData(filteredData);
  };

  const getFilterOptions = () => {
    const options = new FilterOptions();
    return options.getServicesRepairsAndMaintenance();
  };

  const filterOptions = getFilterOptions();

  // console.log({ tickets,displayData });
  return (
    <Layout isForegroundProcess={loading} loadingText={loadText}>
      <ServiceHeader
        title="Repair & Maintenance"
        hasAccessToSynchronize={hasAccessToSynchronize}
        handleTicketsSync={handleTicketsSync}
        isFilter={isFilter}
        setIsFilter={setIsFilter}
        allTickets={allTickets}
        updateTickets={updateTickets}
      />
      <AlertDialog show={hubspotSynchAlert.show} alertMessage={hubspotSynchAlert.message} onClick={() => setHubspotSynchAlert({ show: false })} />
      <Filter enabled={isFilter} data={tickets} filterOptions={filterOptions} handleFilterDataChange={handleFilterDataChange} />
      <section className="grid grid-cols-1 gap-8 mt-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
        {displayData?.map((repairMaintenance) => (
          <RepairMaintenanceCard
            key={repairMaintenance.id}
            name={repairMaintenance.name}
            makeModel={repairMaintenance.makeAndModel}
            status={repairMaintenance.status}
            vehicleReg={repairMaintenance.vehicle_registration}
            description={repairMaintenance.hs_content}
            Reason={repairMaintenance.alertCode}
            dateAllocated={null}
            serviceProviderAllocated={null}
          />
        ))}
      </section>
    </Layout>
  );
}
