import React, { useState, useEffect } from "react";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { useDispatch, useSelector } from "react-redux";
import LayoutHeader from "./LayoutHeader";
import Sidebar from "./Sidebar/Sidebar";
import { selectCurrentUser } from "../features/auth/authSlice";
import { expandSidebar, minimizeSidebar, selectCurrentSidebar } from "../features/settings/settingsSlice";
import Loading from "./Loading";
import systemConfig from "../config/systemConfiguration";

const Layout = ({ children, isLoading, loadingText, isForegroundProcess = false }) => {
  const user = useSelector(selectCurrentUser);
  const [sidebar, setSidebar] = useState(useSelector(selectCurrentSidebar) || { expanded: true });
  const sidebarExpanded = sidebar.expanded;
  const dispatch = useDispatch();
  const isLoadingState = isLoading || isForegroundProcess;
  const handleExpandSidebar = (expanded) => {
    setSidebar({ expanded });
    if (expanded) {
      dispatch(expandSidebar());
    } else {
      dispatch(minimizeSidebar());
    }
  };

  useEffect(() => {
    
    if (user.isRemoteLogEnabled) {     
      LogRocket.init(systemConfig.logRocket.appCode);
      LogRocket.identify(user.id, { name: user.fullName, entityName: user.entity.name });
      
      setupLogRocketReact(LogRocket);
    }
  },[user])

  return (
    <section className="relative">
      {/*  Site header */}
      <LayoutHeader sidebarExpanded={sidebarExpanded} setSidebarObject={setSidebar} />

      <div className="flex overflow-hidden ">
        <aside className="fixed z-40 h-full mr-8">
          {/* Sidebar */}
          <div className={`relative h-full ${isLoadingState ? "blur-xs" : ""}`}>
            <Sidebar sidebarExpanded={sidebarExpanded} setSidebarObject={setSidebar} />
            <div className="group">
            <button type="button" onClick={() => handleExpandSidebar(!sidebarExpanded)} className="absolute bg-white rounded-full shadow-md -right-2 bottom-40 group-hover:bg-brand">
              {sidebarExpanded ? (
                <svg className="w-8 h-8 fill-secondary group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M8 12l6-6v12z" />
                </svg>
              ) : (
                <svg className="w-8 h-8 fill-secondary group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M16 12l-6 6V6z" />
                </svg>
              )}
              </button>
              </div>
          </div>
        </aside>

        {/* Content area */}
        <div className={`relative flex flex-col flex-1 ${sidebarExpanded ? "ml-60" : "ml-16"} overflow-hidden pr-0`}>
          {/*  Page header */}
          <main className={`flex flex-col w-full gap-4 px-4 py-4 mx-auto sm:px-6 lg:px-8 my-w-9x ${isLoadingState && isForegroundProcess ? "brightness-50 contrast-50 blur-sm " : ""}`}>{children}</main>
        </div>
      </div>

      {isLoadingState ? (
              <Loading isLoadingState={isLoading} loadingText={loadingText} isForegroundProcess={isForegroundProcess} />
          ) : null}
    </section>
  );
};

export default Layout;
