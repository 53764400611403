import React from "react";
import { Link } from "react-router-dom";
import Layout from "./Layout";
import PrimaryHeader from "./header/PrimaryHeader";

import EmptyFolderIcon from "./icons/EmptyFolderIcon";
function NoRecords({ title, message, children }) {
  const displayMessage = message ? message : "No records found";

  return (
    <Layout>
      <PrimaryHeader title={title}>{children}</PrimaryHeader>

      <section className="flex justify-center ">
        <div className="flex flex-col items-center p-4 mt-20 rounded-md w-96">
          <Link onClick={() => window.location.reload()}>
            <EmptyFolderIcon classes="h-24 w-24 fill-primary" />
          </Link>
          <span className="text-lg text-center capitalize text-primary">
            {displayMessage}
            <br></br>
            <Link onClick={() => window.location.reload()}>Refresh</Link> the page, We'll look again
          </span>
        </div>
      </section>
    </Layout>
  );
}

export default NoRecords;
