import BugsnagPerformance from "@bugsnag/browser-performance";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import LogRocket from "logrocket";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.css";
import { persistor, store } from "./store/store";

Bugsnag.start({
  apiKey: "59b4a11cda08885a289efcf9e5abd797",
  plugins: [new BugsnagPluginReact()],
  appVersion: "1.1.79",
  beforeSend: function (report) {
    report.updateMetaData("LogRocket", {
      sessionURL: LogRocket.sessionURL
    });
  }
});
BugsnagPerformance.start({ apiKey: "59b4a11cda08885a289efcf9e5abd797" });

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
