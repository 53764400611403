import React from "react";
import { useParams } from "react-router-dom";
import Api from "../../api";
import { Layout, NoRecords, OnError, PrimaryHeader} from "../../components";
import TripMap from "../../components/maps/google/TripMap";
import { distanceDisplay, durationDisplay } from "../../core/utilities";
import TablesSkeleton from "../../skeletons/TablesSkeleton";

export default function VehicleTrip() {
  const { vehicleId, tripId } = useParams();
  // console.log(vehicleId,tripId);
  const { data: vehicle, isLoading, isError, error } = Api.vehicles.useGetVehicleTripsQuery({ vehicleId, tripId });
  const vehicleTripsFound = vehicle?.tripPoints?.length > 0;

  console.log({vehicle})

  return isError ? (
    <OnError error={error} />
  ) : isLoading ? (
    <TablesSkeleton isLoading={isLoading} rowCount={15} title={"Vehicle Trip"} />
  ) : vehicleTripsFound ? (
    <DisplayVehicleTrip vehicle={vehicle} />
  ) : (
    <NoRecords title="Vehicle Trips" message="No Vehicle Trips found." />
  );
}

function DisplayVehicleTrip({ vehicle }) {
  const { tripPoints } = vehicle;
  const title = `Vehicle Trip : ${vehicle.vehicleRegistrationNumber} (${vehicle.makeAndModel}) traveled ${distanceDisplay(vehicle.trip_distance)} over ${durationDisplay(
    vehicle.trip_duration,
    false
  )}`;

  const getBreadcrumbs = (vehicle) => {
    return [
      {
        name: "Home",
        path: "/"
      },
      {
        name: "Vehicles",
        path: "/vehicles"
      },
      {
        name: `${vehicle.vehicleRegistrationNumber}(${vehicle.makeAndModel})`,
        path: `/vehicles/${vehicle.id}`
      },
      {
        name: "Vehicle Trip"
      }
    ];
  };

  console.log({tripPoints})
  return (
    <Layout>
      <PrimaryHeader breadcrumbItems={getBreadcrumbs(vehicle)}>
        <div className="flex"></div>
      </PrimaryHeader>

      <section className="detail-section">
        <div className="p-0 h-88 section-container">
          <TripMap tripPoints={tripPoints} />
        </div>
      </section>
    </Layout>
  );
}
