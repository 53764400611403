import { Pie } from "react-chartjs-2";

const PieChart = ({chartTitle, chartData}) => {
    const data = {
        labels: Object?.keys(chartData),
        datasets: [{
          data: Object?.values(chartData),
          backgroundColor: [
            "rgb(255, 99, 132)",
            "rgb(54, 162, 235)",
            "rgb(255, 205, 86)"
          ],
          hoverOffset: 4
        }]
      };

      const config = {
          responsive: true,
          plugins: {
            legend: {
              position: "top"
            },
            title: {
              display: true,
              text: chartTitle,
              position: "top"
            }

        }
      };
    return ( 
        <div>
            <Pie data={data} options={config} />
        </div>
     );
}
 
export default PieChart;