import React from "react"
import ToastSuccess from "./ToastSuccess";
import ToastFail from "./ToastFail";

function Toast({success,message, show}) {
  return (
    success ? <ToastSuccess message={message} show={show}/> : <ToastFail message={message} show={show}/>
  )
}

export default Toast