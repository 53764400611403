import htmlParse from "html-react-parser";
import React from "react";
import { Link } from "react-router-dom";
import Api from "../../../api";
import Timeline from "../../../components/Timeline";
import BoxArchiveIcon from "../../../components/icons/BoxArchiveIcon";
import { dateTimeDisplay,numberFormat } from "../../../core/utilities";
import systemConfig from "../../../config/systemConfiguration";
import { UnlinkIcon } from "../../../components/icons";

const DeviceOverview = ({ device, onDeviceUnlink,hasAccessToUnlinkVehicle }) => {
  const { data: deviceLogs = [] } = Api.devices.useGetDeviceLogsQuery(device.id);
  const activeAlertsFound = false; // device.activeAlerts;
  const camerasInstalled = systemConfig.features.camerasInstalled;
  const deviceInstalled = device.vehicle_id > 0;

  const deviceLogsTimeLine = () => {
    return deviceLogs?.map((deviceLog) => {
      const showOldValue = deviceLog.column_name === "primary_sim_iccid" || deviceLog.column_name === "primary_sim_imsi" ? true : false;
      const prefix = deviceLog.column_name === "primary_sim_iccid" ? "Iccid" : deviceLog.column_name === "primary_sim_imsi" ? "Imsi" : "";
      return {
        date: dateTimeDisplay(deviceLog.change_date),
        title: `${prefix} ${deviceLog.new_value}`.trim(),
        description: showOldValue ? `Old value was ${deviceLog.old_value}` : ""
      };
    });
  };

  const categoryText = (category) => {
    let textAttrib = "text-brand";
    switch (category) {
      case "Never":
        textAttrib = "text-delete";
        break;

      case "Over 30 Days":
        textAttrib = "text-warn";
        break;

      default:
        textAttrib = "text-brand";
        break;
    }
    return textAttrib;
  };

  const onVehicleUnlink = () => {
    if (onDeviceUnlink) {
      onDeviceUnlink();
    }
  }
  // console.log({device,deviceNotInstalled: deviceInstalled})
  return (
    <section className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
      <div className="section-container" tabIndex="0">
        <h2 className="font-semibold">Device Overview {!deviceInstalled ? <span className="text-delete">[Not Installed]</span> : "" }</h2>
        <ul className="ml-8 list-disc">
          <li>
            Type: <span className="font-semibold text-brand">{device?.device_type}</span>
          </li>

          <li>
            Serial Number: <span className="font-semibold text-brand">{device.serial_number || ""}</span>
          </li>
          <li>
            Firmware: <span className="font-semibold text-brand">{device.firmware_name || ""}</span>
          </li>
          {device.vehicleMakeAndModelRegOrVin ? (
            <li>
              <div className="group">
              Vehicle:{" "}
              <Link to={`/vehicles/${device.vehicle_id}`}>
                <span className="font-semibold text-brand">{device.vehicleMakeAndModelRegOrVin}</span>
              </Link>
                <button onClick={onVehicleUnlink} className={hasAccessToUnlinkVehicle ? "show-hover" : "hidden"}><UnlinkIcon classes={"fill-current"} /></button>
                </div>
            </li>
          ) : null}
          {device.vehicle_battery_voltage ? (
            <li>
              Battery Voltage:{" "}
                <span className="font-semibold text-brand">{numberFormat(device.vehicle_battery_voltage,1)}</span>
            </li>
          ) : null}
           {device.primary_sim_msisdn ? (
            <li>
              SimCard Msisdn:{" "}
              <Link to={`/sim-cards/${device.primary_sim_iccid}`}>
              <span className="font-semibold text-brand">{device.primary_sim_msisdn}</span>
              </Link>
            </li>
          ) : null}
        </ul>
      </div>

      <div className="section-container" tabIndex="1">
        <div>
          <h2 className="font-semibold">Connectivity</h2>
          <ul className="ml-8 list-disc">
            <li>
              Last Online: <span className={`font-semibold ${categoryText(device.lastSeen.category)}`}>{dateTimeDisplay(device.lastSeen.dateTime) || device.lastSeen.categoryInfo.label}</span>
            </li>
            <li>
              Last Reported:{" "}
              <span className={`font-semibold ${categoryText(device.lastReported.category)}`}>{dateTimeDisplay(device?.lastReported.dateTime) || device.lastReported.categoryInfo.label}</span>
            </li>

            <li>
              Last Data Timestamp:{" "}
              <span className={`font-semibold ${categoryText(device.lastDataTimestamp.category)}`}>
                {dateTimeDisplay(device.lastDataTimestamp.dateTime) || device.lastDataTimestamp.categoryInfo.label}
              </span>
            </li>
            {camerasInstalled ? <li>
              Last Camera Footage:{" "}
              <span className={`font-semibold ${categoryText(device.lastCameraFootage.category)}`}>
                {dateTimeDisplay(device.lastCameraFootage.dateTime) || device.lastCameraFootage.categoryInfo.label}
              </span>
            </li> : null}
          </ul>
        </div>
      </div>

      <div className="section-container" tabIndex="2">
        <div>
          <h2 className="font-semibold">Manufacture Connectivity</h2>
          <ul className="ml-8 list-disc">
            <li>
              Status: <span className={`font-semibold ${device.manufacturer_api_activity_status === "Offline" ? "text-delete" : "text-brand"}`}>{device.manufacturer_api_activity_status}</span>
            </li>
            <li>
              Last Online: <span className={`font-semibold ${categoryText(device.manufacturerApiLastOnline.category)}`}>{dateTimeDisplay(device?.manufacturerApiLastOnline.dateTime)}</span>
            </li>

            <li>
              Last Sync: <span className={`font-semibold ${categoryText(device.manufacturerApiLastSync.category)}`}>{dateTimeDisplay(device?.manufacturerApiLastSync.dateTime)}</span>
            </li>
            <li>
              First Sign In: <span className={"font-semibold text-brand"}>{dateTimeDisplay(device.manufacturerApiFirstLogin.dateTime)}</span>
            </li>
          </ul>
        </div>
      </div>

      {activeAlertsFound ? (
        <div className="section-container lg:col-span-3 max-h-60 vertical-auto-scrollbar" tabIndex="3">
          <h2 className="font-semibold">Active Alerts</h2>
          <ul className="ml-8 list-disc">
            {device.activeAlerts?.map((alert, index) => (
              <li key={index}>{htmlParse(`${alert.description} on <i>${dateTimeDisplay(alert.data_timestamp)}</i>`)}</li>
            ))}
          </ul>
        </div>
      ) : null}

      <div className="section-container lg:col-span-3" tabIndex="4">
        <h2 className="font-semibold">Device Logs</h2>
        <Timeline items={deviceLogsTimeLine()} />

        {deviceLogs.length === 0 ? (
          <p className="inline-flex gap-2">
            {" "}
            <BoxArchiveIcon classes={"fill-current"} /> No Logs
          </p>
        ) : null}
      </div>
    </section>
  );
};

export default DeviceOverview;
