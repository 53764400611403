import React from "react";
 import "../context-menu.css";
import {
    Menu,
    Item,
    Separator,
    contextMenu
} from "react-contexify";
import {useSelector} from "react-redux";
import { selectCurrentTheme } from "../features/settings/settingsSlice";

export function showContextMenu({id,event}) {
    contextMenu.show({ 
        id,
        event, 
        position: {
        x: event.clientX,
        y: event.clientY
    }
    })
}

export default function ContextMenu({ title, menu, id, data, menuOptions }) {


    return < DisplayContextMenu id={id} title={title} menu={menu} data={data} menuOptions={menuOptions} />;
            
}
  
function DisplayContextMenu({ id, title, menu, data, menuOptions }) {
    const theme = useSelector(selectCurrentTheme);
    const isMenuItemDisabled = ({ menuItem, menuOptions, data }) => {
        const { row } = menuOptions;
        //console.log({menuItem, datum, data});
        if (!menuItem.enableRequires)
            return false;
        
        if (!row || !data) {
            return true;
        }

        if (data && data.length > 0) {
            const filtered = data.filter((rec) => rec[menuItem.enableRequires]);
            if (filtered.length > 0) {
                return false;
            }

        }

        else {

            if (row[menuItem.enableRequires]) {
                return false;
            }
        }

        return true;
        
    }

    //console.log({menu,id,title,menuOptions});
    return (

        <Menu id={id} animation="fade" theme={`${theme === "dark-theme" ? "dark" : "light"}`} className='absolute rounded-md shadow-xl bg-other'>
            {title ? <div className='p-4 bg-background'>
                <p className="text-center text-primary">{title}</p>
                <Separator className="bg-background" />
            </div> : null}

            <div className='p-4'>
                {menu?.map((menuItem, index) => {
                    const isSeparator = menuItem.separator;
                      
                    const disabled = isMenuItemDisabled({menuItem,menuOptions,data} );
                    return isSeparator ?  <Separator key={index} /> : (<Item key={index}
                        id={menuItem.id}
                        onClick={menuItem.onClick}
                        disabled={disabled}
                        className='cursor-pointer  rounded-xl text-primary'>
                        {menuItem.label}
                    </Item>)
                })}
            </div>
            </Menu> 

    ) 
}

