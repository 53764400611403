import React, { useEffect, useState } from 'react';

export default function ScrollTo() {
  const [isVisible, setIsVisible] = useState(false);
  const [scrollY,setScrollY] = useState(0);
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [isSafari, setIsSafari] = useState(false);
  

 useEffect(() => {
   const userAgent = navigator.userAgent.toLowerCase();
   window.addEventListener('scroll', handleScroll);
   
   if (userAgent.includes('safari') && !userAgent.includes('chrome')) {
     setIsSafari(true);
   }

   function handleScroll() {
     const currentY = window.scrollY;
     const isBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;
     
    if (!isBottom &&  scrollY < window.scrollY) {
      setIsScrollingDown(true);
    }
    else
    {
      setIsScrollingDown(false);
    }
    setScrollY(currentY);
    
    if (currentY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
     calculateScrollProgress();
    //  console.log({scrollY, currentY,isScrollingDown,isVisible});
   }
   
   function calculateScrollProgress() {
    let scrollProgress = document.getElementById("scroll-to-container");
    const pos = document.documentElement.scrollTop;
    const calcHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
     const scrollValue = Math.round((pos * 100) / calcHeight);
     const isBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;

     if (scrollProgress) {
       if (pos > 100) {
         scrollProgress.style.display = "grid";
       } else {
         scrollProgress.style.display = "none";
       }
       scrollProgress.addEventListener("click", () => {
        
         
         if (isScrollingDown && !isBottom) {
           setIsScrollingDown(false);
           window.scrollTo(0, document.body.scrollHeight); 

         }
         else
           {
             document.documentElement.scrollTop = 0;
           }
       });

       scrollProgress.addEventListener("contextmenu", (event) => { 
        event.preventDefault();
        scrollProgress.style.display = "none";
       })


       scrollProgress.style.background = `conic-gradient(#03cc65 ${scrollValue}%, #d7d7d7 ${scrollValue}%)`;
     }
   }
   
 
   return () => {
   window.removeEventListener('scroll', handleScroll);
   }
 }, [isScrollingDown, isVisible, scrollY])
 
 
  return (
    <>
      {isVisible ?

        isSafari ? <DisplayForSafari isScrollingDown={isScrollingDown} /> : <DisplayForOtherBrowsers isScrollingDown={isScrollingDown} /> 
      : null
      }
    </>
  )
}

function DisplayForSafari({ isScrollingDown })
{
  return (<div id="scroll-to-container">
  <span id="scroll-to-content" className={`transition-transform duration-300 ${isScrollingDown ? "flip-x rotate-180" : ""}`}>&#8593;</span>
</div>)
}

function DisplayForOtherBrowsers({ isScrollingDown })
{
  return (<div id="scroll-to-container">
  <span id="scroll-to-content" className={`transition-transform duration-300 ${isScrollingDown ? "flip-x rotate-180" : ""}`}>&#x1F815;</span>
</div>)
}