import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from "react-chartjs-2";
  
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  
  const MultiLineGraph = ({ title, graphData = {} }) => {
  
    const {fill,tension, borderWidth} = {fill:false,tension:0.1, borderWidth: 2};
    
    const {labels =[], datasets =[]} = graphData;
      const data = {
          labels, 
          datasets: datasets.map(dataset => {
              return {              
            label: dataset.label,
            data: dataset.data,
            fill: dataset.fill||fill,
            borderColor : dataset.borderColor,
            tension:dataset.tension||tension,
            borderWidth: dataset.borderWidth||borderWidth 
              }
          })
        };
  
        const config = {
            responsive: true,
            plugins: {
              legend: {
                display: true,
                position: "top"
              },
              title: {
                display: title ?  true : false,
                text: title,
                position: "left"
              }
  
          },
          scales: {
              x: {
                grid: {
                  display: false
                }
              },
              y: {
                grid: {
                  display: false
                }
              }
            }
        };
      return ( 
          <div className='mx-auto '>
              <Line data={data} options={config} />
          </div>
       );
  }
   
  export default MultiLineGraph;