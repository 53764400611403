import React from "react";
import htmlParse from "html-react-parser";
import { BatteryIcon, CompassIcon } from "../icons";
import { AlertIcon } from "../icons/SidebarIcons";
import { dateTimeDisplay,distanceDisplay,durationDisplay, speedDisplay } from "../../core/utilities";
import Timeline from "../Timeline";


const VehicleOverview = ({ vehicle }) => {

  const activeAlertsFound = vehicle.activeAlerts;


  const vehicleTripsTimeLine = () => {
    return vehicle.trips.map((trip) => {

      return {
        date: dateTimeDisplay(trip.start_date_time),
        title: `${distanceDisplay(trip.distance)} driven`,
        description: `Trip took ${durationDisplay(trip.duration,false)}, maximum speed for the trip was ${speedDisplay(trip.maximum_speed)}  and the trip ended at ${dateTimeDisplay(trip.end_date_time)}`,
        link: { path: `/vehicles/vehicle-trip/${vehicle?.id.toString()}/${trip.id.toString()}`, title: "View Trip" }  
      }
      
  });
  }
  
  // console.log({ vehicle });

  return (
    <section className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
      <div className="section-container" tabIndex="0">
        <h2 className="font-semibold">Vehicle Overview</h2>
        <ul className="ml-8 list-disc">
          <li>
            Ignition: <span className="font-semibold capitalize text-brand">{vehicle?.overview.ignitionOn}</span>
          </li>
          {/* //TODO: Figure out where to get the data for Drive and Gear and Parking Brake
          <li>
            Drive: <span className="font-semibold capitalize text-brand">{vehicle.overview.drive}</span>
          </li>
          <li>
            Gear: <span className="font-semibold capitalize text-brand">{vehicle.overview.gear}</span>
          </li>
              
          <li>
            Parking Brake: <span className="font-semibold capitalize text-brand">{vehicle.overview.parkingBrake}</span>
        </li>
        */}
          <li>
            Odometer: <span className="font-semibold capitalize text-brand">{vehicle.overview.odometer}</span>
          </li>
          <li>
            On the Road: <span className="font-semibold capitalize text-brand">{vehicle.overview.onTheRoad}</span>
          </li>
        </ul>
      </div>

      <div className="section-container" tabIndex="1">
        <div>
          <p>Battery Voltage </p>
          <span className={`inline-flex gap-2 font-semibold capitalize ${vehicle.battery_capacity > 50 ? "text-brand" : "text-red-900"}`}>
            {" "}
            <BatteryIcon classes={`${vehicle.battery_capacity > 50 ? "fill-brand" : "fill-red-900"}`} /> {vehicle.overview.batteryVoltage}
          </span>
        </div>

        {/* //TODO: Figure out where to get the data for Accelarot and  Brake              
        <div>
          <p>Accelerator</p>
          <div className="w-full bg-secondary rounded-full h-2.5">
            <div className="mt-1 bg-brand h-2.5 rounded-full" style={{ width: vehicle.overview.accelerator }}></div>
          </div>
        </div>

        <div>
          <p>Brake</p>
          <div className="w-full bg-secondary rounded-full h-2.5">
            <div className="mt-1 bg-brand h-2.5 rounded-full" style={{ width: vehicle.overview.brake }}></div>
          </div>
        </div>
      */}
      </div>

      <div className="section-container" tabIndex="2">
        <div className="flex flex-row items-center gap-2">
          <CompassIcon classes="h-8 w-8" />
          <p>
            Speed: <span className="font-semibold capitalize text-brand">{vehicle.overview.speedDirection}</span>
          </p>
        </div>
        <div>
          <p>
            Next Schedule Service: <span className="font-semibold capitalize text-brand">{vehicle.overview.scheduledService}</span>
          </p>
          <button className="mt-2 border-2 btn border-primary hover:opacity-40" disabled>View service Center</button>
        </div>
      </div>

      {activeAlertsFound ? <div className="section-container lg:col-span-3 max-h-60 vertical-auto-scrollbar" tabIndex="3">
        <h2 className="font-semibold">Active Alerts</h2>
        <ul className="ml-8 list-disc">
          {vehicle.activeAlerts.map((alert, index) => (
           <li key={index}>{htmlParse(`${alert.description} on <i>${dateTimeDisplay(alert.data_timestamp)}</i>`)}</li>
          ))}
        </ul>
      </div> : null}

      <div className="section-container lg:col-span-3" tabIndex="4">
        <h2 className="font-semibold">Trips</h2>
        <Timeline items={vehicleTripsTimeLine()}/>
       
        {vehicle.trips.length === 0 ? (
          <p className="inline-flex gap-2">
            {" "}
            <AlertIcon classes={"fill-current"} /> No Trips Found !
          </p>
        ) : null}
      </div>

    </section>
  );
};

export default VehicleOverview;
