import React from 'react'
import { CameraIcon, ChartSimpleIcon, HomeIcon, LocationDotIcon, SmsMessageIcon, TriangleExclamationIcon } from "./icons";


export const TabIconTypes = Object.freeze({
    Alert: "alertIcon",
    Chart: "chart",
    Home: "homeIcon",
    Sms: "smsIcon",
    Camera: "cameraIcon",
    LocationDot: "locationDotIcon"

}); 

function Tabs({items}) {
  return (
    <div className="border-b">
        <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
              {items.map((item,i) => {
                  return (
                      <li key={i} className="me-2">
                          <DisplayItem item={item} />
                      </li>
                  )
              })}      

    </ul>
</div>
  )
}

function DisplayItem({ item }) {
    const { name, onClick, icon, currentTab, enabled = true } = item;
    const isCurrentTab = currentTab === name;
    return (
        <button id={name} onClick={onClick} className={`${isCurrentTab ? "text-primary cursor-default" : !enabled ? "text-other cursor-not-allowed" :  "hover:text-brand"} focus:outline-none inline-flex items-center justify-center p-4 border-transparent hover:border-gray-300`} >
            <DisplayIcon icon={icon} /> 
        {name}
    </button>
)
}


function DisplayIcon({ icon }) {
 
    switch (icon) {
        case TabIconTypes.Alert:
            return (<TriangleExclamationIcon classes="w-4 h-4 me-2 fill-current" />)
        case TabIconTypes.Chart:
            return (<ChartSimpleIcon classes="w-4 h-4 me-2 fill-current" />)        
        case TabIconTypes.Home:
            return <HomeIcon classes="w-4 h-4 me-2 fill-current" />
        case TabIconTypes.Sms:
            return <SmsMessageIcon classes="w-4 h-4 me-2 fill-current" />
        case TabIconTypes.Camera:
            return <CameraIcon classes="w-4 h-4 me-2 fill-current" />
        case TabIconTypes.LocationDot:
            return <LocationDotIcon classes="w-4 h-4 me-2 fill-current"/>
    
        default:
            return null;
    }

}

export default Tabs