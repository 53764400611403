import { api } from "./api";

export const vehicleServicesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllVehicleServices: builder.query({
      query: () => "/vehicleServices",
      keepUnusedDataFor: 5
    }),
    getAllUpcomingVehicleServices: builder.query({
        query: () => "/vehicleServices?current=true",
        keepUnusedDataFor: 5
      }),
    getVehicle: builder.query({
      query: (id) =>`/vehicleServices/${id}`
    })
  })
});

export const { useGetAllVehicleServicesQuery,useGetAllUpcomingVehicleServicesQuery,useGetVehicleServiceQuery } = vehicleServicesApi;