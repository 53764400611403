import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSendSingleSmsMutation } from "../../api/rtk/smsApi";
import { ChartIcon, ConfirmationDialog, Layout, NoRecords, OnError, PageFilter, Pagination, PrimaryHeader } from "../../components";
import ContextMenu, { showContextMenu } from "../../components/ContextMenu";
import { BarGraph, DoughnutGraph, } from "../../components/charts";
import Filter from "../../components/filter/Filter";
import { Table, TableColumnOptions } from "../../components/table";
import Toast from "../../components/toast/Toast";
import SystemFeatures from "../../constant/systemFeatures";
import TeltonikaSmsCommands from "../../core/TeltonikaSmsCommands";
import { dateDayMontDisplay, daysToSameDateLastMonth, isValidArrayWithData, objectPick,getDeviceStatusColourScheme } from "../../core/utilities";

import Api from "../../api";
import CsvExportButton from "../../components/CsvExportButton";
import MultiLineGraph from "../../components/charts/MultiLineGraph";
import FilterButton from "../../components/filter/FilterButton";
import ExportFields from "../../constant/exportFields";
import { getCategoryInfoTotals, getCategoryTotals } from "../../core/utilities";
import { selectCurrentUser } from "../../features/auth/authSlice";
import { getFilterOptionsFeature, getIsFilterFeature, getPerPageFeature, getIsShowChartsFeature, setIsFilterFeature, setPerPageFeature, setIsShowChartsFeature } from "../../features/settings/settingsSlice";
import FilterOptions from "../../filter/FilterOptions";
import usePageFilter from "../../hooks/usePageFilter";
import TablesSkeleton from "../../skeletons/TablesSkeleton";
import DeviceOdometerDialog from "./DeviceOdometerDialog";

const initialContextMenu = {
  identifier: null,
  row: null,
  menu: []
};

const PROCESS_FEATURE = SystemFeatures.DEVICES;
export default function Devices() {
  const { data: devices = [], isLoading, isError, error } = Api.devices.useGetAllDevicesQuery();
  const { data: devicesDailyConnectStatistics = [] } = Api.devices.useGetDevicesDailyConnectivityStatisticsQuery(daysToSameDateLastMonth());
  const devicesFound = isValidArrayWithData(devices);

  // console.log({count:devices.length,devices,isLoading,isError,error})
  //console.log({ daysToSameDateLastMonth: daysToSameDateLastMonth() });

  return isError ? (
    <OnError error={error} />
  ) : isLoading ? (
    <TablesSkeleton isLoading={isLoading} rowCount={15} title={"Devices"} />
  ) : devicesFound ? (
    <DisplayDevices devices={devices} devicesDailyConnectStatistics={devicesDailyConnectStatistics} />
  ) : (
    <NoRecords title="Devices" message="No Devices found." />
  );
}

function DisplayDevices({ devices, devicesDailyConnectStatistics }) {
  const currentItemsPerPage = useSelector(getPerPageFeature(PROCESS_FEATURE));
  const currentIsFilter = useSelector(getIsFilterFeature(PROCESS_FEATURE));
  const currentIsShowCharts = useSelector(getIsShowChartsFeature(PROCESS_FEATURE));
  const defaultFilterOptions = useSelector(getFilterOptionsFeature(PROCESS_FEATURE));
    const [shownDevices, setShownDevices] = useState(devices);
  const [exportData, setExportData] = useState(devices);
  const [isFilter, setIsFilter] = useState(currentIsFilter);
  const [isHideCharts, setIsHideCharts] = useState(!currentIsShowCharts);
  const [isFiltered, setIsFiltered] = useState(false);
  const [commandConfirmation, setCommandConfirmation] = useState({ confirm: false });
  const [commandConfirmState, setCommandConfirmState] = useState(undefined);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [contextMenuOptions, setContextMenuOptions] = useState(initialContextMenu);
  const [deviceCommandInfo, setDeviceCommandInfo] = useState({});
  const [deviceId, setDeviceId] = useState();
  const [deviceOdometer, setDeviceOdometer] = useState(0);
  const [showDeviceOdometerDialog, setShowDeviceOdometerDialog] = useState(false);
  const [manufacturerApiStatusInfo, setManufacturerApiStatusInfo] = useState();
  const [lastReportedInfo, setLastReportedInfo] = useState();
  const [lastDataTimestampInfo, setLastDataTimestampInfo] = useState();
  const [deviceDailyConnectInfo, setDeviceDailyConnectInfo] = useState();
  const [autoFilterValues, setAutoFilterValues] = useState();
  const [sendSingleSms] = useSendSingleSmsMutation();
  const navigate = useNavigate();

  const user = useSelector(selectCurrentUser);
  const devicesOnTheRoad = devices.filter(device => device.vehicle_is_immobile !== true);
  const { numberOfItemsOnCurrentPage, itemsPerPage, currentPage, numberOfPages, totalNumberOfItems, handleItemsPerPageChange, handlePageClick } = usePageFilter({ displayData: shownDevices, currentItemsPerPage });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPerPageFeature({ name: PROCESS_FEATURE, perPage: itemsPerPage }));
  }, [dispatch, itemsPerPage]);

  useEffect(() => {
    dispatch(setIsFilterFeature({ name: PROCESS_FEATURE, isFilter }));
  }, [dispatch, isFilter]);

  useEffect(() => {
    
    dispatch(setIsShowChartsFeature({ name: PROCESS_FEATURE, isShowCharts: !isHideCharts }));
  }, [dispatch, isHideCharts]);


  useEffect(() => {
    const shouldClear = deviceCommandInfo.showToast || deviceCommandInfo.sendSuccess;
    const timer = setTimeout(() => {
      if (shouldClear) {
        setDeviceCommandInfo({});
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [deviceCommandInfo]);

  useEffect(() => {
    const statusColourScheme = getDeviceStatusColourScheme();


    setManufacturerApiStatusInfo(getCategoryTotals({ data: shownDevices, fieldName: "manufacturer_api_activity_status", colorScheme: statusColourScheme }));
    const lastReportedInfoTotals = getCategoryInfoTotals({ data: shownDevices, fieldName: "lastReported.categoryInfo" });
    const lastDataTimestampInfoTotals = getCategoryInfoTotals({ data: shownDevices, fieldName: "lastDataTimestamp.categoryInfo" });

    const deviceDailyConnectInfo = {
      labels: devicesDailyConnectStatistics.map((stats) => dateDayMontDisplay(stats.day_date)),
      datasets: [
        {
          borderColor: "#10b981",
          label: "Devices Daily Connections",
          data: devicesDailyConnectStatistics.map((stats) => stats.reported)
        },
        {
          borderColor: "#0000FF",
          label: "Devices Daily Data",
          data: devicesDailyConnectStatistics.map((stats) => stats.data_timestamps)
        }
      ]
    };
    const lastReportedInfo = {
      values: lastReportedInfoTotals.map((device) => device.value),
      labels: lastReportedInfoTotals.map((device) => device.label),
      colours: lastReportedInfoTotals.map((device) => device.colour)
    };
    setLastReportedInfo(lastReportedInfo);
    const lastDataTimestampInfo = {
      values: lastDataTimestampInfoTotals.map((device) => device.value),
      labels: lastDataTimestampInfoTotals.map((device) => device.label),
      colours: lastDataTimestampInfoTotals.map((device) => device.colour)
    };
    setLastDataTimestampInfo(lastDataTimestampInfo);
    //    setLastSeenInfo(lastSeenInfo);
    //    setFirmwareNameInfo(firmwareInfo);
    setDeviceDailyConnectInfo(deviceDailyConnectInfo);
  }, [shownDevices, devicesDailyConnectStatistics]);

  const handleDeviceView = () => { 
    const { row } = contextMenuOptions;
    const id = row.id;
    navigate(`/devices/${id}`);
  }

  const handleDeviceGetInfo = () => {
    const { row } = contextMenuOptions;
    const msisdn = row.sim_msisdn;
    const smsMessage = TeltonikaSmsCommands.GetInfo;
    const reference = `Command ${smsMessage} done via portal by user id:${user.id}`;
    setCommandConfirmState("ConfirmationNotRequired");
    setContextMenuOptions(initialContextMenu);
    setDeviceCommandInfo({ msisdn, smsMessage, reference });
  };

  const handleDeviceGetGps = () => {
    const { row } = contextMenuOptions;
    const msisdn = row.sim_msisdn;
    const smsMessage = TeltonikaSmsCommands.GetGps;
    const reference = `Command ${smsMessage} done via portal by user id:${user.id}`;
    setCommandConfirmState("ConfirmationNotRequired");
    setContextMenuOptions(initialContextMenu);
    setDeviceCommandInfo({ msisdn, smsMessage, reference });
  };

  const handleDeviceGetGoogleGps = () => {
    const { row } = contextMenuOptions;
    const msisdn = row.sim_msisdn;
    const smsMessage = TeltonikaSmsCommands.GetGps;
    const reference = `Command ${smsMessage} done via portal by user id:${user.id}`;
    setCommandConfirmState("ConfirmationNotRequired");
    setContextMenuOptions(initialContextMenu);
    setDeviceCommandInfo({ msisdn, smsMessage, reference });
  };

  const handleDeviceSdFormat = () => {
    const { row } = contextMenuOptions;
    const msisdn = row.sim_msisdn;
    const smsMessage = TeltonikaSmsCommands.FormatSdCard;
    const reference = `Command ${smsMessage} done via portal by user id:${user.id}`;
    setCommandConfirmState("ConfirmationRequired");
    setContextMenuOptions(initialContextMenu);
    setDeviceCommandInfo({ msisdn, smsMessage, reference });
  };

  const handleDeviceSetOdometer = () => {
    const { row } = contextMenuOptions;
    const msisdn = row.sim_msisdn;

    setContextMenuOptions({ row, msisdn });
    setDeviceId(row.id);
    setShowDeviceOdometerDialog(true);

    // setDeviceCommandInfo({ msisdn, smsMessage, reference });
  };

  const contextMenu = [
    {
      id: "",
      label: "View Device",
      onClick: handleDeviceView,
      enableRequires: "id"
    },
    {
      separator: true
    },
    {
      id: TeltonikaSmsCommands.FormatSdCard,
      label: "Format",
      onClick: handleDeviceSdFormat,
      enableRequires: "primary_sim_msisdn"
    },
    {
      separator: true
    },
    {
      id: TeltonikaSmsCommands.GetInfo,
      label: "Get Device Info",
      onClick: handleDeviceGetInfo,
      enableRequires: "primary_sim_msisdn"
    },
    {
      id: TeltonikaSmsCommands.GetGps,
      label: "Get Device GPS",
      onClick: handleDeviceGetGps,
      enableRequires: "primary_sim_msisdn"
    },
    {
      id: TeltonikaSmsCommands.GetGoogleGps,
      label: "Get Device Google GPS Link",
      onClick: handleDeviceGetGoogleGps,
      enableRequires: "primary_sim_msisdn"
    },
    {
      separator: true
    },
    {
      id: TeltonikaSmsCommands.SetDeviceOdometer,
      label: "Set Device Odometer",
      onClick: handleDeviceSetOdometer,
      enableRequires: "primary_sim_msisdn"
    }
  ];

  const handleFilterDataChange = (filteredData) => {
    setIsFiltered(filteredData.length !== devices.length);
    setShownDevices(filteredData);
    setExportData(
      filteredData.map((device) => {
        return objectPick(device, ExportFields.DEVICES);
      })
    );
  };

  const handleTableContextMenu = (e, identifier, row) => {
    const msisdn = row.primary_sim_msisdn;
    
    if (msisdn) {
      setContextMenuOptions({ identifier, row });
      showContextMenu({ id: "devices", event: e });
     
    } else {
      setContextMenuOptions(initialContextMenu);
      e.preventDefault();
    }

    // const { pageX, pageY } = e;
    // console.log({ identifier,row,e });
  };



  const handleNegativeConfirmation = () => {
    setCommandConfirmation({ confirm: false });
    if (commandConfirmState === "AwaitingConfirmation") {
      setCommandConfirmState("NegativeConfirmation");
    }
  };

  const handlePositiveConfirmation = () => {
    setCommandConfirmation({ confirm: false });
    if (commandConfirmState === "AwaitingConfirmation") {
      setCommandConfirmState("PositiveConfirmation");
    }
  };

  const handleLastReportedDevicesOnClick = (row, value) => {
    
    const label = lastReportedInfo.labels[row];
    setAutoFilterValues({ name: "lastReportedCategoryInfoLabel", label, value: label });
    if (!isFilter) {
      setIsFilter(true);
    }

  }

  const handleLastTimestampDataDevicesOnClick= (row, value) => {
    
    const label = lastDataTimestampInfo.labels[row];
    setAutoFilterValues({ name: "lastDataTimestampCategoryInfoLabel", label, value: label });
    if (!isFilter) {
      setIsFilter(true);
    }

  }

  const handleDeviceStatusOnClick = (row, value) => {

    const label = row.label;
    setAutoFilterValues({ name: "manufacturer_api_activity_status", label, value: label });
    if (!isFilter) {
      setIsFilter(true);
    }

  }


  const filterOptions = () => {
    const option = new FilterOptions();
    return option.getDeviceOptions();
  };

  const onSelectedTableRowChanged = (selected) => {
    const rows = devices.filter((device) => selected.includes(device.id));
    // console.log({ rows, selected });
    setSelectedDevices(rows);
  };

  useEffect(() => {
    async function sendSms() {
      try {
        const { msisdn, smsMessage, reference } = deviceCommandInfo;

        if (selectedDevices && selectedDevices.length > 0) {
          const multipleMsisdn = selectedDevices.map((device) => device.sim_msisdn);
          const smsSendInfo = await sendSingleSms({ msisdns: multipleMsisdn, smsMessage: TeltonikaSmsCommands.fullCommand(smsMessage), reference }).unwrap();
          setCommandConfirmState("SmsInitiated");
          if (smsSendInfo.smsResponse) {
            setCommandConfirmState("SmsSent");
            setDeviceCommandInfo({ msisdn, smsMessage, reference, sendSuccess: true, showToast: true });
          } else {
            setDeviceCommandInfo({ msisdn, smsMessage, reference, sendSuccess: false, showToast: true });
          }
        } else if (msisdn) {
          const smsSendInfo = await sendSingleSms({ msisdn, smsMessage: `  ${smsMessage}`, reference }).unwrap();
          setCommandConfirmState("SmsInitiated");
          if (smsSendInfo.smsResponse) {
            setCommandConfirmState("SmsSent");
            setDeviceCommandInfo({ msisdn, smsMessage, reference, sendSuccess: true, showToast: true });
          } else {
            setDeviceCommandInfo({ msisdn, smsMessage, reference, sendSuccess: false, showToast: true });
          }
          
        } else {
          // console.log("This does not have a msisdn", { deviceCommandInfo });
        }

        setCommandConfirmState(undefined);
      } catch (error) {
        if (error.status === 403) {
          window.location.reload();
        }
      }
    }

    switch (commandConfirmState) {
      case "ConfirmationNotRequired":
        sendSms();
        break;

      case "ConfirmationRequired":
        const { smsMessage } = deviceCommandInfo;
        setCommandConfirmation({ confirm: true, message: `Are you sure you want to send sms message: ${smsMessage} to devices?` });
        setCommandConfirmState("AwaitingConfirmation");
        break;
      case "AwaitingConfirmation":
        break;

      case "NegativeConfirmation":
        setCommandConfirmState(undefined);
        break;

      case "PositiveConfirmation":
        sendSms();
        break;

      default:
        break;
    }
  }, [deviceCommandInfo, commandConfirmState, sendSingleSms, selectedDevices]);

  const contextDevice = selectedDevices.length > 1 ? `${selectedDevices.length} selected devices` : selectedDevices.length === 1 ? selectedDevices[0].id : contextMenuOptions.identifier;

   // console.log({ devices, displayData, commandConfirmation, commandConfirmState, contextMenuOptions });

  const onDeviceOdometerChange = (event) => {
    setDeviceOdometer(event.target.value);
  };
  const onDeviceOdometerEditCancel = () => {
    setShowDeviceOdometerDialog(false);
    setContextMenuOptions(initialContextMenu);
  };
  const onDeviceOdometerEditSave = () => {
    const { msisdn } = contextMenuOptions;
    //const msisdn = row.sim_msisdn;
    const smsMessage = `${TeltonikaSmsCommands.SetDeviceOdometer}${deviceOdometer}`;
    const reference = `Command ${smsMessage} done via portal by user id:${user.id}`;
    setShowDeviceOdometerDialog(false);
    // console.log({ smsMessage, reference, msisdn });

    setCommandConfirmState("ConfirmationRequired");
    setContextMenuOptions(initialContextMenu);
    setDeviceCommandInfo({ msisdn, smsMessage, reference });
  };

  
  // console.log({ devices,devicesOnTheRoad, devicesDailyConnectStatistics,lastReportedInfo });
  return (
    <Layout>
      {showDeviceOdometerDialog ? (
        <DeviceOdometerDialog deviceId={deviceId} odometer={deviceOdometer} onChangeOdometer={onDeviceOdometerChange} onCancel={onDeviceOdometerEditCancel} onSave={onDeviceOdometerEditSave} />
      ) : null}
      <PrimaryHeader title="Devices">
        <div className="flex">
          <CsvExportButton exportData={exportData} filename={"Devices"} />
          <button type="button" onClick={() => setIsHideCharts((hideChart) => !hideChart)} className="inline-flex items-center gap-4 mx-4 btn text-primary group hover:text-brand">
            <ChartIcon />
            <span className="inline-flex items-center gap-1">{isHideCharts ? "Show Charts" : "Hide Charts"}</span>
          </button>

          <FilterButton isFilter={isFilter} setIsFilter={setIsFilter} />
        </div>
      </PrimaryHeader>
      <Filter enabled={isFilter} data={devices}
        filterOptions={filterOptions()}
        handleFilterDataChange={handleFilterDataChange}
        feature={PROCESS_FEATURE}
        defaultFilterOptions={defaultFilterOptions}
        autoFilterValues={autoFilterValues}
        style={{ display: 'flex', flexDirection: 'row' }}
      />
      {isHideCharts ? null : (
        <section className="py-4 section-container">
          <div className="flex h-auto">
            <div className="w-1/5">
              <DoughnutGraph entityName={"Device"} graphData={manufacturerApiStatusInfo} onItemClick={handleDeviceStatusOnClick}/>
            </div>
            <div className="flex w-4/5">
              <div className="w-1/3">
                <BarGraph title={"Last Reported Devices"} backgroundColor={lastReportedInfo?.colours} graphData={lastReportedInfo} onItemClick={handleLastReportedDevicesOnClick} />
              </div>
              <div className="w-1/3 mx-3">
                <BarGraph title={"Timestamp from Devices"} backgroundColor={lastDataTimestampInfo?.colours} graphData={lastDataTimestampInfo} onItemClick={handleLastTimestampDataDevicesOnClick}/>
              </div>
              <div className="w-1/3">
                <MultiLineGraph graphData={deviceDailyConnectInfo} />
              </div>
            </div>
          </div>
        </section>
      )}
      <section className="detail-section">
        <PageFilter
          itemsPerPage={itemsPerPage}
          setItemsPerPage={handleItemsPerPageChange}
          numberOfItemsOnCurrentPage={numberOfItemsOnCurrentPage}
          recordsName={"Devices"}
          isFiltered={isFiltered}
          totalNumberOfItems={totalNumberOfItems}
        />
        <ContextMenu id={"devices"} menu={contextMenu} title={`Device Options for ${contextDevice}`} data={selectedDevices} menuOptions={contextMenuOptions} />
        <Toast success={deviceCommandInfo.sendSuccess} show={deviceCommandInfo.showToast} message={"Sms sent to device"} />
        <ConfirmationDialog show={commandConfirmation.confirm} onNo={handleNegativeConfirmation} onYes={handlePositiveConfirmation} confirmationMessage={commandConfirmation.message} />
        <Table
          data={shownDevices}
          pagingInfo={{ dataPerPage: itemsPerPage, currentPage }}
          tableColumns={TableColumnOptions.devices}
          initialSort={{ key: "id", order: "asc" }}
          onContextMenu={handleTableContextMenu}
          firstColumnCheckBox={false}
          onSelectedChanged={onSelectedTableRowChanged}
          includeSequentialId={true}
          outOfRange={{ key: "last_reported_days", aboveLimit: 5, includeNull: true }}
        />
        <Pagination handlePageClick={handlePageClick} numberOfPages={numberOfPages} />
      </section>
    </Layout>
  );
}
