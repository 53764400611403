export const ProcessFlowStage = Object.freeze({
    Confirm: "confirm",
    Confirmed: "confirmed.yes",
    Cancel: "confirm.no",
    Alert: "alert",
    Info: "info",
    NotStarted: "not.started",
    Processing: "processing",
    Complete: "complete"
});
  
export default ProcessFlowStage;