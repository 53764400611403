import React from "react";
import Layout from "../../components/Layout";
import InfoCard from "../../components/vehicle/InfoCard";
import { PrimaryHeader,  NoRecords, OnError } from "../../components";
import { isValidArrayWithData } from "../../core/utilities";
import Api from "../../api";
import ApprovedHelpSkeleton from "../../skeletons/ApprovedHelpSkeleton";

const ApprovedHelp = () => {

    const { data: vehicleMaintenanceProviders=[], isLoading, isError, error } = Api.vehicleMaintenanceProviders.useGetAllVehicleMaintenanceProvidersQuery();
    const providersFound = isValidArrayWithData(vehicleMaintenanceProviders);

    return (isError ? <OnError error={error} /> :
        isLoading ?  <ApprovedHelpSkeleton isLoading={isLoading} loadingText="Loading Fuel Analysis" recordsCount={15} /> :        
        providersFound ? <DisplayApprovedHelp vehicleMaintenanceProviders={vehicleMaintenanceProviders} /> : 
        <NoRecords title="Approved Help Options" message="No Approved Help found." />);
}

function DisplayApprovedHelp({ vehicleMaintenanceProviders}) {
    
    return ( 
        <Layout>
            <div className="rounded-sm shadow-lg bg-other col-span-full xl:col-span-8">
            <PrimaryHeader title="Approved Help" />
            </div>

            <section className='grid gap-4 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4'>
                {vehicleMaintenanceProviders.map(serviceProvider => 
                        <InfoCard key={serviceProvider.id} linkTo={"contact"} centerName={serviceProvider.name} address={serviceProvider.physical_address} phone={serviceProvider.contact_telephone} />
                        
                    )
                }
               
            </section>

            
        </Layout>
        
     );
}
 
export default ApprovedHelp;