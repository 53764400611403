import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams,useLocation, useNavigate } from "react-router-dom";
import Api from "../../api";
import { useSynchronizeSimCardsMutation } from "../../api/rtk/simControlApi";
import { ContextMenu, Filter, Layout, NoRecords, OnError, PageFilter, Pagination, PrimaryHeader, Table, TableColumnOptions } from "../../components";
import { showContextMenu } from "../../components/ContextMenu";
import CsvExportButton from "../../components/CsvExportButton";
import FilterButton from "../../components/filter/FilterButton";
import { SystemFeatures, UserRoleAccess } from "../../constant";
import { isValidArrayWithData } from "../../core/utilities";
import { getFilterOptionsFeature, getIsFilterFeature, getPerPageFeature, setIsFilterFeature, setPerPageFeature } from "../../features/settings/settingsSlice";
import FilterOptions from "../../filter/FilterOptions";
import useHasAccessToFeature from "../../hooks/useHasAccessToFeature";
import usePageFilter from "../../hooks/usePageFilter";
import TablesSkeleton from "../../skeletons/TablesSkeleton";

const initialContextMenu = {
  show: false,
  identifier: null,
  row: null,
  menu: []
};

const PROCESS_FEATURE = SystemFeatures.SIM_CARDS;

export default function SimCards() {
  const { data: simCards, isLoading, isError, error, refetch } = Api.simCards.useGetAllSimCardsQuery();
  const { hasAccess: hasAccessToSynchronize } = useHasAccessToFeature(UserRoleAccess.simCardSynchronize);
  const [synchronizeSimCardData, { isLoading: isLoadingSynchSimCardData, isUninitialized: isUninitializedSynchSimCardData, isSuccess: isSuccessSynchSimCardData }] = useSynchronizeSimCardsMutation();
  const simCardsFound = isValidArrayWithData(simCards);

  useEffect(() => {
    if (!isUninitializedSynchSimCardData && isSuccessSynchSimCardData) {
      //resetSynchSimCardData();
      //window.location.reload();
      refetch();
    }
  }, [isSuccessSynchSimCardData, isUninitializedSynchSimCardData, refetch]);

  // console.log({ simCards, isLoadingSynchSimCardData, isSuccessSynchSimCardData, isUninitializedSynchSimCardData, hasAccessToSynchronize });

  return isError ? (
    <OnError error={error} />
  ) : isLoading ? (
    <TablesSkeleton title={"SimCards"} rowCount={17} />
  ) : simCardsFound ? (
    <DisplaySimCards simCards={simCards} synchronizeSimCardData={synchronizeSimCardData} canSynchronizeSimCardData={hasAccessToSynchronize} />
  ) : (
    <NoRecords title="SimCards" message="No SimCards found." />
  );
}

function DisplaySimCards({ simCards, synchronizeSimCardData, canSynchronizeSimCardData }) {
  const currentItemsPerPage = useSelector(getPerPageFeature(PROCESS_FEATURE));
  const currentIsFilter = useSelector(getIsFilterFeature(PROCESS_FEATURE));
  const defaultFilterOptions = useSelector(getFilterOptionsFeature(PROCESS_FEATURE));
  const [displayData, setDisplayData] = useState(simCards);
  const [exportData, setExportData] = useState(simCards);
  const [isFilter, setIsFilter] = useState(currentIsFilter);
  const [isFiltered, setIsFiltered] = useState(false);
  const [contextMenuOptions, setContextMenuOptions] = useState(initialContextMenu);
  const navigate = useNavigate();

  const { numberOfItemsOnCurrentPage, itemsPerPage, currentPage, numberOfPages, totalNumberOfItems, handleItemsPerPageChange, handlePageClick } = usePageFilter({
    displayData,
    currentItemsPerPage
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPerPageFeature({ name: PROCESS_FEATURE, perPage: itemsPerPage }));
  }, [dispatch, itemsPerPage]);

  useEffect(() => {
    dispatch(setIsFilterFeature({ name: PROCESS_FEATURE, isFilter }));
  }, [dispatch, isFilter]);

  const contextMenu = [
    {
      label: "Format SD",
      onClick: () => {
        setContextMenuOptions(initialContextMenu);
        alert("Format SD");
      }
    },
    {
      label: "Get Device Info",
      onClick: () => {
        setContextMenuOptions(initialContextMenu);
        alert("Get Device Information");
      }
    }
  ];

  const handleFilterDataChange = (filteredData) => {
    setIsFiltered(filteredData.length !== simCards.length);
    setDisplayData(filteredData);
    setExportData(
      filteredData.map((simCard) => {
        // return objectPick(simCard,ExportFields.SIM_CARDS);
        return simCard;
      })
    );
  };

  const handleSynchronizeSimData = async () => {
    const synchResponse = await synchronizeSimCardData().unwrap();
    console.log(synchResponse);
  };

  const handleTableContextMenu = (e, identifier, row) => {
 
    const msisdn = row.msisdn;
    if (msisdn) {
     
      showContextMenu({ id: "devices", event: e });
      setContextMenuOptions({ show: true, identifier, row });
    } else {
      setContextMenuOptions(initialContextMenu);
      e.preventDefault();
    }
  };

  const handleTableDataDoubleClick = (_, identifier) => {
    
   //   navigate(`/sim-cards/${identifier}`);
    
  }  

  const filterOptions = () => {
    const options = new FilterOptions();
    return options.getSimCardOptions();
  };

  // console.log({ simCards });
  return (
    <Layout>
      <PrimaryHeader title="SimCards">
        <div className="flex">
          {canSynchronizeSimCardData && (
            <button type="button" onClick={handleSynchronizeSimData} className="e-flex items-center gap-4 px-3 mx-3 text-sm border rounded-md border-brand text-primary hover:text-brand py-1.5'">
              Synchronize Data
            </button>
          )}
          <CsvExportButton exportData={exportData} filename={"SimCards"} />

          <FilterButton isFilter={isFilter} setIsFilter={setIsFilter} />
        </div>
      </PrimaryHeader>

      <Filter
        enabled={isFilter}
        data={simCards}
        filterOptions={filterOptions()}
        handleFilterDataChange={handleFilterDataChange}
        feature={PROCESS_FEATURE}
        defaultFilterOptions={defaultFilterOptions}
      />

      <section className="detail-section">
        <PageFilter
          itemsPerPage={itemsPerPage}
          setItemsPerPage={handleItemsPerPageChange}
          numberOfItemsOnCurrentPage={numberOfItemsOnCurrentPage}
          recordsName={"SimCards"}
          isFiltered={isFiltered}
          totalNumberOfItems={totalNumberOfItems}
        />
        <ContextMenu id={"simCards"}
          menu={contextMenu}
          title={`SimCard Options for ${contextMenuOptions.identifier}`}
          menuOptions={contextMenuOptions} />
        <Table
          data={displayData}
          pagingInfo={{ dataPerPage: itemsPerPage, currentPage }}
          tableColumns={TableColumnOptions.simCards}
          initialSort={{ key: "id", order: "asc" }}
          onTableDataDoubleClick={handleTableDataDoubleClick}
          onContextMenu={handleTableContextMenu}
          includeSequentialId={true}
        />

        <Pagination handlePageClick={handlePageClick} numberOfPages={numberOfPages} />
      </section>
    </Layout>
  );
}
