import React from "react";
import Timeline from "../../../components/Timeline";
import { TriangleExclamationIcon } from "../../../components/icons";
import { dateTimeDisplay } from "../../../core/utilities";

function DeviceEventsView({ deviceEvents }) {
  const deviceEventTimeLine = () => {
    return deviceEvents?.map((deviceEvent) => {
      return {
        date: dateTimeDisplay(deviceEvent.data_timestamp),
        title: deviceEvent.description,
        description: `${deviceEvent.event_type} ${deviceEvent.event_value}`
      };
    });
  };

  //console.log({ deviceEvents });

  return (
    <section className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
      <div className="section-container lg:col-span-3" tabIndex="4">
        <h2 className="font-semibold">Device Events</h2>
        <Timeline items={deviceEventTimeLine()} />

        {deviceEvents?.length === 0 ? (
          <p className="inline-flex gap-2">
            {" "}
            <TriangleExclamationIcon classes={"fill-current"} /> No Events
          </p>
        ) : null}
      </div>
    </section>
  );
}

export default DeviceEventsView;
