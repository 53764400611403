import { api } from "./api";
import  Constant  from "../../constant";
import systemConfig from "../../config/systemConfiguration";
const { entity } = systemConfig;

export const hubspotTicketsApi = api.injectEndpoints({
  tagTypes:["Hubspot"],
  endpoints: (builder) => ({
    getAllHubspotTickets: builder.query({
      query: () => "/hubspot/tickets",
      providesTags:["Hubspot"],
      keepUnusedDataFor: 5
    }),
    getAllBreakdownTickets: builder.query({
      query: () => "/hubspot/tickets/breakdowns",
      providesTags:["Hubspot"]
    }),
    getActiveBreakdownTicketsCount: builder.query({
      query: () => "/hubspot/tickets/breakdowns?activeOnly=true&countOnly=true",
      providesTags:["Hubspot"],
      transformResponse: (responseData => {
        return  responseData && responseData.length > 0 ? responseData[0].count : 0;
      })
    }),
    getAllAccidentsTickets: builder.query({
      query: () => "/hubspot/tickets/accidents",
      providesTags:["Hubspot"]
    }),
    getAllServicesTickets: builder.query({
      query: () => "/hubspot/tickets/services",
      providesTags:["Hubspot"]
    }),
    getAllInspectionsTickets: builder.query({
      query: () => "/hubspot/tickets/inspections",
      providesTags:["Hubspot"]
    }),
    getAllFinesManagementTickets: builder.query({
      query: () => "/hubspot/tickets/fines",
      providesTags:["Hubspot"]
    }),
    getAllTheftTickets: builder.query({
      query: () => "/hubspot/tickets/thefts",
      providesTags:["Hubspot"]
    }),
    getAllExpiredLicenseTickets: builder.query({
      query: () => "/hubspot/tickets/expired",
      providesTags:["Hubspot"]
    }),
    getAllDiagnosticAlertsTickets: builder.query({
      query: () => "/hubspot/tickets/alerts",
      providesTags:["Hubspot"]
    }),
    getAllRoadsideAssistanceTickets: builder.query({
      query: () => "/hubspot/tickets/roadside"
    }),
    getHubspotTicket: builder.query({
      query: (id) => `/hubspot/tickets/${id}`,
      providesTags:["Hubspot"]
    }),
    synchronizeHubSpotTickets: builder.mutation({
      query: () => `/hubspot/tickets/retrieve/tickets?customerUuId=${entity.uuId}&key=${Constant.System.Riiot.Api.Key}`,
      method: "GET",
      invalidatesTags:["Hubspot"]
  })
  })
});

export const { useGetAllHubspotTicketsQuery, useGetAllBreakdownTicketsQuery, useGetActiveBreakdownTicketsCountQuery,
  useGetAllAccidentsTicketsQuery, useGetAllServicesTicketsQuery, useGetAllInspectionsTicketsQuery, useGetAllFinesManagementTicketsQuery,
  useGetAllTheftTicketsQuery, useGetAllExpiredLicenseTicketsQuery, useGetHubspotTicketQuery, useGetAllDiagnosticAlertsTicketsQuery,
  useGetAllRoadsideAssistanceTicketsQuery, useSynchronizeHubSpotTicketsMutation } = hubspotTicketsApi;