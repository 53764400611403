export const SystemFeatures = Object.freeze({
    DASHBOARD: "dashBoard",
    DASHBOARD_CAMERA_FEEDS: "dashBoardCameraFeeds",
    DASHBOARD_LIVE_DATA_FEED: "dashBoardLiveDataFeeds",
    DRIVERS: "drivers",
    DEVICES: "devices",
    DEVICE_EVENTS: "deviceEvents",
    DEVICE_SMS_ALERTS: "deviceSmsAlerts",
    DEVICE_CAMERA_FEEDS: "deviceCameraFeeds",
    DEVICE_TRIP_POINTS: "deviceTripPoints",
    INSIGHTS: "insights",
    GPS: "gps",
    SIM_CARDS: "simCards",
    SIM_CARDS_USAGE_LOGS: "simCards.usage.logs",
    SIM_CARDS_AIRTIME_DAILY_USAGE: "simCards.airtime.daily.usage",
    SIM_CARDS_DATA_DAILY_USAGE: "simCards.data.daily.usage",
    USERS: "users",
    USER_REGISTRATIONS: "userRegistrations",
    VEHICLES: "vehicles",
    VEHICLES_BATTERY: "vehiclesBatteryInfo"
});
  
export default SystemFeatures;