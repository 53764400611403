import { api } from "./api";

export const driversApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllDrivers: builder.query({
      query: () => "/drivers",
      keepUnusedDataFor: 5
    }),
    getDriver: builder.query({
      query: (id) =>`/drivers/${id}`
    })
  })
});

export const { useGetAllDriversQuery,useGetDriverQuery } = driversApi;