import { baseApi } from "../rtk/baseApi";


export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/authenticate",
        method: "POST",
        body: { ...credentials,isDashboardLogin: true }
      })

    }),
    logout: builder.mutation({
      query: (credentials) => ({
        url: "/authenticate",
        method: "DELETE",
        body: { ...credentials }
      })

    })
  })
});

export const { useLoginMutation, useLogoutMutation  } = authApi;


