import React from "react";
import Timeline from "../../../components/Timeline";
import { SmsMessageIcon } from "../../../components/icons";
import { dateTimeDisplay, removeDuplicates } from "../../../core/utilities";

function DeviceSmsOutView({ deviceSmsOut }) {
  const deviceSmsOutTimeLine = () => {
    const deviceSms = removeDuplicates(deviceSmsOut, "id");

    return deviceSms?.map((smsOut) => {
      return {
        date: dateTimeDisplay(smsOut.created_at),
        title: smsOut.sms_message,
        description: smsOut.response_message || ""
      };
    });
  };

  console.log({ deviceSmsOut });

  return (
    <section className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
      <div className="section-container lg:col-span-3" tabIndex="4">
        <h2 className="font-semibold">Device SMS Out</h2>
        <Timeline items={deviceSmsOutTimeLine()} />

        {deviceSmsOut.length === 0 ? (
          <p className="inline-flex gap-2">
            {" "}
            <SmsMessageIcon classes={"fill-current"} /> No Sent SMS
          </p>
        ) : null}
      </div>
    </section>
  );
}

export default DeviceSmsOutView;
