import React from "react";
import { Link } from "react-router-dom";
import {ArrowsUpDownIcon,BarsArrowDownIcon,BarsArrowUpIcon} from "@heroicons/react/24/solid"



function TableHeaderSort({ headerSort, headerKey,onSort,sortOrder,sortKey }) {
  const isSortKey = headerKey === sortKey;

    return (headerSort ? <SortHeader onSort={onSort} headerKey={headerKey} isSortKey={isSortKey} sortOrder={sortOrder}/> : "");

}

function SortHeader({onSort, headerKey,isSortKey,sortOrder}) { 
    
  return (<Link onClick={() => onSort(headerKey)}>
    {isSortKey ? sortOrder ==="asc" ? <BarsArrowUpIcon className="ml-1 w-5 h-5 currentColor"/> : <BarsArrowDownIcon className="ml-1 w-5 h-5 currentColor"/> :
      <ArrowsUpDownIcon className="ml-1 w-4 h-4 text-gray-700"/>
    }
  </Link>)
}

export default TableHeaderSort
