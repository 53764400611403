import { api } from "./api";
import Constant from "../../constant";

const vehiclesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllVehicles: builder.query({
      query: () => "/vehicles",
      keepUnusedDataFor: 300
    }),
    getAllVehiclesBatteryStateInfo: builder.query({
      query: () => "/vehicles/batteryState"
    }),
    getAllVehiclesLifetimeFuelAnalytics: builder.query({
      query: () => "/vehicles/lifetimeFuelAnalytics"
    }),    
    getAllFuelTransactionAndVehiclesRegistrationNumbers: builder.query({
      query: () => "/vehicles/fuelTransactionAndVehiclesRegistrationNumbers"
    }),
    getVehicle: builder.query({
      query: (id) => `/vehicles/${id}`
    }),
    getAllRealtimeVehicles: builder.query({
      query: ({cameraFeedsMaxDaysToInclude,liveFeedMaxDaysToInclude}) => `/vehicles/realtime?liveFeedMaxDaysToInclude=${liveFeedMaxDaysToInclude}&cameraFeedsMaxDaysToInclude=${cameraFeedsMaxDaysToInclude}`
    }),
    getOnlineOnlyVehiclesRealtime: builder.query({
      query: (onlineMaxDaysToInclude) => `/vehicles/onlineRealtime?onlineMaxDaysToInclude=${onlineMaxDaysToInclude}`
    }),
    getRealtimeVehicle: builder.query({
      query: (id) => `/vehicles/realtime/${id}`
    }),
    getAllVehiclesDashboardCameraFeeds: builder.query({
      query: () => `/vehicles/dashboardCameraFeeds?daysToInclude=${Constant.System.CameraFeeds.NoOfDaysToInclude}`
    }),
    getVehicleDashboardCameraFeeds: builder.query({
      query: (id) => `/vehicles/dashboardCameraFeeds/${id}?daysToInclude=${Constant.System.CameraFeeds.NoOfDaysToInclude}`
    }),
    getVehicleTrips: builder.query({
      query: ({vehicleId,tripId}) => `/vehicles/trips/${tripId}?vehicleId=${vehicleId}`
    }),
    postVehiclesTripsReport: builder.mutation({
      query: (reportInfo) => ({
        url:"/vehicles/tripsReport",
        method: "POST",
        body: { ...reportInfo }
      })
  }),
    postVehiclesLocationTrackingReport: builder.mutation({
      query: (reportInfo) => ({
        url:"/vehicles/locationsTrackingReport",
        method: "POST",
        body: { ...reportInfo }
      })
    }),
    postVehiclesEventsReport: builder.mutation({
      query: (reportInfo) => ({
        url:"/vehicles/eventsReport",
        method: "POST",
        body: { ...reportInfo }
      })
    }),
    getVehicleSmsTracking: builder.query({
      query: (id) => `/vehicles/smsTracking/${id}`
    }),
    postCreateVehiclesSmsTracking: builder.mutation({
      query: (id) => ({
        url:"/vehicles/smsTracking",
        method: "POST",
        body: { id }
      })
    }),
    postUpdateVehicleMobility: builder.mutation({
      query: ({id,set}) => ({
        url:"/vehicles/mobility",
        method: "POST",
        body: { id, set }
      })
    }),
    postLinkVehicleToDevice: builder.mutation({
      query: ({id,set}) => ({
        url:"/vehicles/linkDevice",
        method: "POST",
        body: { id, set }
      })
    }),
    postUnlinkVehicleFromDevice: builder.mutation({
      query: ({id,set}) => ({
        url:"/vehicles/unlinkDevice",
        method: "POST",
        body: { id, set }
      })
  })         
    
  })
});

export const { useGetAllVehiclesQuery, useGetAllVehiclesBatteryStateInfoQuery,
  useGetVehicleQuery, useGetAllRealtimeVehiclesQuery, useGetRealtimeVehicleQuery,
  useGetAllFuelTransactionAndVehiclesRegistrationNumbersQuery,
  useGetAllVehiclesLifetimeFuelAnalyticsQuery,
  useGetVehicleTripsQuery,
  useGetOnlineOnlyVehiclesRealtimeQuery, 
  usePostVehiclesTripsReportMutation,
  usePostVehiclesLocationTrackingReportMutation,
  usePostVehiclesEventsReportMutation,
  usePostCreateVehiclesSmsTrackingMutation, useGetVehicleSmsTrackingQuery, useLazyGetVehicleSmsTrackingQuery,
  usePostUpdateVehicleMobilityMutation, useGetAllVehiclesDashboardCameraFeedsQuery, useGetVehicleDashboardCameraFeedsQuery,
  usePostLinkVehicleToDeviceMutation,usePostUnlinkVehicleFromDeviceMutation
 
} = vehiclesApi;

export default vehiclesApi;