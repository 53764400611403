import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  
  theme: "light-theme",
  sidebar: { expanded: true },
  features: []
};

function findExistingFeature(features, name) {
  
  return features.find((feature => feature.name === name));
}

export const settingsSlice = createSlice({
  name: "settings",
  initialState: initialState,
  reducers: {
    setDarkTheme: (state) => {
      state.theme = "dark-theme";
    },
    setLightTheme: (state) => {
      state.theme = "light-theme";
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    toggleTheme: (state) => {
      state.theme = state.theme === "light-theme" ? "dark-theme" : "light-theme";
    },
    expandSidebar: (state) => {
      state.sidebar = { expanded: true };
    },
    minimizeSidebar: (state) => {
      state.sidebar = { expanded: false };
    },
    setFilterOptionsFeature: (state, action) => { 
      const { filterOptions } = action.payload;
      if (findExistingFeature(state.features,action.payload.name)) {
        state.features = state.features.map(feature => {
          if (feature.name === action.payload.name) {
            feature.filterOptions = filterOptions;
          }
          return feature;
        });
      }
      else {
        state.features.push({ name: action.payload.name, filterOptions });
      }
    },
    setPerPageFeature: (state, action) => { 
      const { perPage } = action.payload;
      if (findExistingFeature(state.features,action.payload.name)) {
        state.features = state.features.map(feature => {
          if (feature.name === action.payload.name) {
            feature.perPage = perPage;
          }
          return feature;
        });
      }
      else {
        state.features.push({ name: action.payload.name, perPage });
      }
    },
    setIsFilterFeature: (state, action) => { 
      const { isFilter } = action.payload;
      if (findExistingFeature(state.features,action.payload.name)) {
        state.features = state.features.map(feature => {
          if (feature.name === action.payload.name) {
            feature.isFilter = isFilter;
          }
          return feature;
        });
      }
      else {
        state.features.push({ name: action.payload.name, isFilter });
      }
    },
    setIsShowChartsFeature: (state, action) => { 
      const { isShowCharts } = action.payload;
      if (findExistingFeature(state.features,action.payload.name)) {
        state.features = state.features.map(feature => {
          if (feature.name === action.payload.name) {
            feature.isShowCharts = isShowCharts;
          }
          return feature;
        });
      }
      else {
        state.features.push({ name: action.payload.name, isShowCharts });
      }
    },    
    setIsDisplayLiveOnlyFeature: (state, action) => { 
      const { isDisplayLiveOnly } = action.payload;
      if (findExistingFeature(state.features,action.payload.name)) {
        state.features = state.features.map(feature => {
          if (feature.name === action.payload.name) {
            feature.isDisplayLiveOnly= isDisplayLiveOnly;
          }
          return feature;
        });
      }
      else {
        state.features.push({ name: action.payload.name, isDisplayLiveOnly });
      }
    }
  }
});

export const { setLightTheme, setDarkTheme, toggleTheme, setTheme, expandSidebar, minimizeSidebar,
  setFilterOptionsFeature,setPerPageFeature,setIsFilterFeature, setIsShowChartsFeature, setIsDisplayLiveOnlyFeature} = settingsSlice.actions;

export const selectCurrentTheme = (state) => state.settings.theme;
export const selectCurrentSidebar = (state) => state.settings.sidebar;
export const getFilterOptionsFeature = name => state => findExistingFeature(state.settings.features, name)?.filterOptions;
export const getPerPageFeature = name => state => findExistingFeature(state.settings.features, name)?.perPage || 10;
export const getIsFilterFeature = name => state => findExistingFeature(state.settings.features, name)?.isFilter || false;
export const getIsShowChartsFeature = name => state => findExistingFeature(state.settings.features, name)?.isShowCharts || false;
export const getIsDisplayLiveOnlyFeature = name => state => findExistingFeature(state.settings.features, name)?.isDisplayLiveOnly || false;
export default settingsSlice.reducer;
