import React, { useEffect, useState } from 'react';
import './MediaModal.css';
import { DownloadIcon, CloseIcon, MaximiseIcon, MinimiseIcon, RightChevronIcon, LeftChevronIcon } from "../icons";

const MediaModal = ({ closeModal, rowLinkData, onPreviousClick, onNextClick, previousNextInfo }) => {
  const [isMaximised, setIsMaximised] = useState(false);
  const { previousMediaExists, nextMediaExists } = previousNextInfo;

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = rowLinkData.url;
    link.download = rowLinkData.alt;
    link.click();
  };

  const handlePreviousClick = (e) => {
    
    if (onPreviousClick) {
      onPreviousClick(e);
    }

  }

  
  const handleNextClick = (e) => {
    
    if (onNextClick) {
      onNextClick(e);
    }

  }

  const handleToggleMaximise = () => {
    setIsMaximised(!isMaximised);
  };

  useEffect(() => {
    const body = document.body;
    body.style.overflow = 'hidden';

    const handleCloseModal = (event) => {
      if (event.target.classList.contains('modal-overlay')) {
        closeModal();
      }
    };

    document.addEventListener('click', handleCloseModal);

    return () => {
      body.style.overflow = '';
      document.removeEventListener('click', handleCloseModal);
    };
  }, [closeModal]);

  const isVideo = rowLinkData.url.endsWith('.mp4');

 
  return (
    <div className={`modal fixed inset-0 z-50 backdrop-blur-sm modal-overlay ${isMaximised ? 'maximised' : ''}`}>
      {previousMediaExists ? <div className="left-chevron" onClick={handlePreviousClick}>
        <LeftChevronIcon color="var(--color-primary)" />
      </div> : null}

      <div className="modal-header">
        <h2 style={{ color: 'var(--color-primary)', marginRight: 'auto' }}>{rowLinkData.alt}</h2>
        <h2 style={{ color: 'var(--color-primary)', marginRight: 'auto' }}>{rowLinkData.headerOne}</h2>
        <h2 style={{ color: 'var(--color-primary)', marginRight: 'auto' }}>{rowLinkData.headerTwo}</h2>
        {!isVideo && (
          <button className="maximise-button" style={{ marginRight: '30px' }} onClick={handleToggleMaximise}>
            {isMaximised ? <MinimiseIcon color="var(--color-primary)" /> : <MaximiseIcon color="var(--color-primary)" />}
          </button>
        )}
        <button className="download-button" style={{ marginRight: '30px' }} onClick={handleDownload}>
          <DownloadIcon color="var(--color-primary)" />
        </button>
        <span className="close" onClick={closeModal}>
          <CloseIcon color="var(--color-primary)" />
        </span>
      </div>

      {nextMediaExists ? <div className="right-chevron" onClick={handleNextClick}>
        <RightChevronIcon color="var(--color-primary)" />
      </div> : null}

      <div className={`modal-content`}>
        {isVideo ? (
          <div>
            <video controls className={`modal-media'}`}>
              <source src={rowLinkData.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        ) : (
          <div>
            <img
              src={rowLinkData.url}
              alt={rowLinkData.alt}
              className={`modal-media ${isMaximised ? 'maximised' : ''}`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MediaModal;