import React, {  } from "react";
import { Link } from "react-router-dom";
import DownArrowIcon from "./icons/DownArrowIcon";


const Driver = ({ driver, id }) => {
  const driverFullName = driver.full_name;
  const driverImage = driver.image_url || "/person_placeholder.png";
  const reportedStatusBgColor = driver?.driver_status === "online" ? "bg-green-500" : driver?.driver_status === "pending" ? "bg-yellow-500" : "bg-red-500";
  
  return (
    <Link to={`/drivers/${id}`}>
      <div className="driver-card group ">
        <img
          className="driver-image"
          src={driverImage}
          width={32}
          height={32}
          alt="driver profile"
        />

        <h3 className="group-hover:text-other">{driverFullName}</h3>

        <p className="group-hover:text-other">View Details</p>
        <DownArrowIcon classes={"fill-primary group-hover:fill-other group-hover:animate-bounce opacity-80"} />

        {/* availability status */}
        <div className="reported-status">
          <div className={`animate-pulse w-5 h-5 rounded-full ${reportedStatusBgColor}`}></div>
        </div>
      </div>
    </Link>
  );
};

export default Driver;
