import { toBoolean } from "../core/utilities";
  
const systemConfiguration = () => {
  let defaultZoom = 11;
  let googleDefaultCenter = process.env.REACT_APP_GOOGLE_MAP_DEFAULT_CENTER;
  if (googleDefaultCenter) {
    const coOrdinates = googleDefaultCenter.split(",");
    if (coOrdinates?.length > 0) {
      googleDefaultCenter = { lat: Number(coOrdinates[0]), lng: Number(coOrdinates[1]) };
    } else {
      googleDefaultCenter = { lat: -25.42695, lng: 27.91085 };
      defaultZoom = 12;
    }
  }
  else
  {
    googleDefaultCenter = { lat: -25.42695, lng: 27.91085 };
    defaultZoom = 12;
    }

  return {
    maps: {
      google: {
        defaultCenter: googleDefaultCenter,
        defaultZoom: defaultZoom,
        apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY
      }
    },
    entity: {
      acronym: process.env.REACT_APP_RIIOT_CUSTOMER_ACRONYM || "RIIOT",
      uuId: process.env.REACT_APP_RIIOT_CUSTOMER_UUID || "NOT.DEFINED.SET.RIIOT.ENV",
      name: process.env.REACT_APP_RIIOT_CUSTOMER_NAME || "Riiot",
    dummyEmail:process.env.REACT_APP_RIIOT_CUSTOMER_DUMMY_EMAIL|| "user@riiottech.co.za"},
    backend: {
      baseUrl: process.env.REACT_APP_RIIOT_SYSTEM_BACKEND_URL || "NOT.DEFINED.SET.RIIOT.ENV",
      apiRoot: process.env.REACT_APP_RIIOT_SYSTEM_API_ROOT || "/api/v1.0"
    },
    features: {
      camerasInstalled: toBoolean(process.env.REACT_APP_RIIOT_CAMERAS_INSTALLED)  
    },
    logRocket: {appCode: process.env.REACT_APP_LOG_ROCKET||"yykpqw/dash-dev"}
  };
};

export default systemConfiguration();
