const DriverRatingCard = ({rating, name, driver_score, braking_count, acceleration_count}) => {
    return ( 
        <div className="relative overflow-hidden rounded-lg section-container">
            
            <span className={` capitalize alert-status ${rating === "good"?"bg-green-400":(rating === "bad")?"bg-red-400":"bg-yellow-400"}`}>{rating}</span>
     
                <h3 className="text-xl font-semibold">{name}</h3>
                <div className="flex flex-col gap-2 text-sm">
                    <p>Driver Score: <span className="font-semibold text-brand">{driver_score}</span></p>
                    <p>Harsh Braking Counts: <span className="font-semibold text-brand">{braking_count}</span></p>
                    <p>Harsh Acceleration Counts: <span className="font-semibold text-brand">{acceleration_count}</span></p>
                    
                </div>
                
                <button className="w-full mt-4 rounded-lg btn-primary">View Details</button>
                  
            
           
        </div>
    );
}
 
export default DriverRatingCard;