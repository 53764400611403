import React from "react";
import { useState, useEffect } from "react";
import { serviceBackgroundStageColor } from "../../core/utilities";
var DataCollection = require("data-collection");
var _ = require("lodash");

function ServiceFilter({ allTickets, setCurrentTickets }) {
 
  const [ticketCount] = useState(allTickets.length);
  const [ticketsDtColl] = useState(new DataCollection(allTickets));
  const [allDataFilters, setAllDataFilters] = useState();
  const [filter, setFilter] = useState();

  const filterBackgroundColor = (filter) => {
     
    return serviceBackgroundStageColor(filter);
  }

    
  useEffect(() => {
    const setupDataStageFilters = () => {
      const allStagesNoneUnique = ticketsDtColl.query().sort("status_display_order", false).values("status");
      const allStages = _.uniq(allStagesNoneUnique);
      return allStages;
    };

    const stageFilters = setupDataStageFilters();
    setAllDataFilters(stageFilters);
  }, []);// [allTickets,ticketsDtColl]);
    
  useEffect(() => {
    if (filter) {
      const filteredServices = ticketsDtColl.query().filter({ status: filter }).values();
      // console.log(filteredServices, filter);
      setCurrentTickets(filteredServices);
        
    } else {
      setCurrentTickets(allTickets);
    }
  }, [filter]); //[filter, allTickets,setCurrentTickets,ticketsDtColl]);
    
  return (
    <div className="filter-nav">
    {allTickets &&
      allTickets.length > 1 && <button type="button" onClick={() => setFilter(null)} className="italic hover:underline">
      All
      <span className="inline-flex items-center justify-center w-4 h-4 ml-2 text-xs font-semibold bg-green-200 rounded-full text-white-800">{ticketCount}</span>
    </button>}

    {allDataFilters && 
      allDataFilters.length > 1 &&
      allDataFilters.map((filter, index) => {
        const count = ticketsDtColl.query().filter({ status: filter }).values().length;
        // console.log(filter, count);
        return (
          filter ?
          <button
            key={filter}
            type="button"
            onClick={() => setFilter(filter)}
            className={`px-2 text-sm italic rounded-full hover:underline text-other ${filterBackgroundColor(filter)}`}
          >
            {filter}
            <span className="inline-flex items-center justify-center w-4 h-4 ml-2 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">{count}</span>
          </button> : null
        );
      })}
  </div>)
}

export default ServiceFilter