import htmlParse from "html-react-parser";
import React from "react";
import { Link } from "react-router-dom";
import Api from "../../../api";
import Timeline from "../../../components/Timeline";
import BoxArchiveIcon from "../../../components/icons/BoxArchiveIcon";
import { dateTimeDisplay,numberFormat } from "../../../core/utilities";


const SimCardOverview = ({ simCard }) => {
  const { data: simCardLogs = [] } = Api.simCards.useGetSimCardLogsQuery(simCard.iccid);

  const activeAlertsFound = false; // device.activeAlerts;

  const deviceInstalled = simCard.vehicle_id > 0;

  const deviceLogsTimeLine = () => {
    return simCardLogs?.map((deviceLog) => {
      const showOldValue = deviceLog.column_name === "primary_sim_iccid" || deviceLog.column_name === "primary_sim_imsi" ? true : false;
      const prefix = deviceLog.column_name === "primary_sim_iccid" ? "Iccid" : deviceLog.column_name === "primary_sim_imsi" ? "Imsi" : "";
      return {
        date: dateTimeDisplay(deviceLog.change_date),
        title: `${prefix} ${deviceLog.new_value}`.trim(),
        description: showOldValue ? `Old value was ${deviceLog.old_value}` : ""
      };
    });
  };

  const categoryText = (category) => {
    let textAttrib = "text-brand";
    switch (category) {
      case "Never":
        textAttrib = "text-delete";
        break;

      case "Over 30 Days":
        textAttrib = "text-warn";
        break;

      default:
        textAttrib = "text-brand";
        break;
    }
    return textAttrib;
  };

  // console.log({simCard, simCardLogs})
  return (
    <section className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-2">
      <div className="section-container" tabIndex="0">
        <h2 className="font-semibold">Sim Card Overview {!deviceInstalled ? <span className="text-delete">[Not Installed]</span> : "" }</h2>
        <ul className="ml-8 list-disc">
          <li>
            Apn: <span className="font-semibold text-brand">{simCard?.apn}</span>
          </li>

          <li>
          <Link to={`/devices/${simCard.deviceId}`}>
              Device Id: <span className="font-semibold text-brand">{simCard.deviceId || ""}</span>
          </Link>
          </li>
          <li>
            Msisdn: <span className="font-semibold text-brand">{simCard.msisdn || ""}</span>
          </li>
          {simCard.vehicleMakeAndModelRegOrVin ? (
            <li>
              Vehicle:{" "}
              <Link to={`/vehicles/${simCard.vehicle_id}`}>
                <span className="font-semibold text-brand">{simCard.vehicleMakeAndModelRegOrVin}</span>
              </Link>
            </li>
          ) : null}
          {simCard.vehicle_battery_voltage ? (
            <li>
              Battery Voltage:{" "}
                <span className="font-semibold text-brand">{numberFormat(simCard.vehicle_battery_voltage,3)}</span>
            </li>
          ) : null}
        </ul>
      </div>

{/*      
      <div className="section-container" tabIndex="1">
        <div>
          <h2 className="font-semibold">Connectivity</h2>
          <ul className="ml-8 list-disc">
            <li>
              Last Online: <span className={`font-semibold ${categoryText(simCard.lastSeen.category)}`}>{dateTimeDisplay(simCard.lastSeen.dateTime) || simCard.lastSeen.categoryInfo.label}</span> 
            </li>            <li>
              Last Reported:{" "}
               <span className={`font-semibold ${categoryText(simCard.lastReported.category)}`}>{dateTimeDisplay(simCard?.lastReported.dateTime) || simCard.lastReported.categoryInfo.label}</span> 
            </li>

            <li>
              Last Data Timestamp:{" "}
               <span className={`font-semibold ${categoryText(simCard.lastDataTimestamp.category)}`}>
                {dateTimeDisplay(simCard.lastDataTimestamp.dateTime) || simCard.lastDataTimestamp.categoryInfo.label}
              </span>
              
            </li>
            {camerasInstalled ? <li>
              Last Camera Footage:{" "}
              
              <span className={`font-semibold ${categoryText(simCard.lastCameraFootage.category)}`}>
                {dateTimeDisplay(simCard.lastCameraFootage.dateTime) || simCard.lastCameraFootage.categoryInfo.label}
              </span>
              
            </li> : null}
          </ul>
        </div>
      </div>
*/}
      <div className="section-container" tabIndex="2">
        <div>
          <h2 className="font-semibold">Manufacture Info</h2>
          <ul className="ml-8 list-disc">
            <li>
              Status: <span className={`font-semibold ${simCard.network_status === "DELETED" ? "text-delete" : "text-brand"}`}>{simCard.network_status}</span>
            </li>
            <li>
              Data Balance (MB):  <span className={`font-semibold ${simCard.data_balance_mb < 1 ? "text-delete" : "text-brand"}`}>{numberFormat(simCard?.data_balance_mb)}</span> 
            </li>

            <li>
              Airtime Balance: <span className={`font-semibold ${simCard.airtime_balance < 1 ? "text-delete" : "text-brand"}`}>{numberFormat(simCard?.artime_balance)}</span> 
            </li>
            <li>
            SMS Balance: <span className={`font-semibold ${simCard.sms_balance < 1 ? "text-delete" : "text-brand"}`}>{numberFormat(simCard?.sms_balance)}</span> 
            </li>
          </ul>
        </div>
      </div>

      {activeAlertsFound ? (
        <div className="section-container lg:col-span-3 max-h-60 vertical-auto-scrollbar" tabIndex="3">
          <h2 className="font-semibold">Active Alerts</h2>
          <ul className="ml-8 list-disc">
            {simCard.activeAlerts?.map((alert, index) => (
              <li key={index}>{htmlParse(`${alert.description} on <i>${dateTimeDisplay(alert.data_timestamp)}</i>`)}</li>
            ))}
          </ul>
        </div>
      ) : null}

      <div className="section-container lg:col-span-3" tabIndex="4">
        <h2 className="font-semibold">Sim Card Logs</h2>
        <Timeline items={deviceLogsTimeLine()} />

        {simCardLogs.length === 0 ? (
          <p className="inline-flex gap-2">
            {" "}
            <BoxArchiveIcon classes={"fill-current"} /> No Logs
          </p>
        ) : null}
      </div>
    </section>
  );
};

export default SimCardOverview;
