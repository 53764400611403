import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserSignOut } from "../../features/auth/authSlice";
import UserAvatar from "../../images/user-avatar.png";

function UserMenu() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const userFullName = user.fullName;
  const { isRiiotAdmin } = user;
  const userDesignation = useSelector((state) => state.auth.user.designation);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdownOpen ||!dropdown.current || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const handleSignOut = () => { 
    dispatch(setUserSignOut()); 
    navigate("/logout");
  }

 
  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className="inline-flex items-center justify-center outline-none group focus-none"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <img className="object-cover object-center w-8 h-8 rounded-full group-hover:rotate-12" src={UserAvatar} width="32" height="32" alt="User profile pic" />
        <div className="flex items-center truncate group">
          <span className="ml-2 text-sm font-medium truncate text-primary group-hover:text-brand">{userFullName}</span>
          <svg className="w-3 h-3 ml-1 text-opacity-0 fill-current shrink-0 text-primary group-hover:fill-brand" viewBox="0 0 12 12">
            <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
          </svg>
        </div>
      </button>

      {dropdownOpen && (
        <div className="origin-top-right z-10 absolute top-full right-0 min-w-44 bg-other  py-1.5 rounded shadow-lg overflow-hidden mt-1">
          <div ref={dropdown} onFocus={() => setDropdownOpen(true)} onBlur={() => setDropdownOpen(false)}>
            <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-secondary/80 ">
              <div className="font-medium text-primary">{userFullName}</div>
              <div className="text-xs italic opacity-85 text-primary">{userDesignation||"User"}</div>
            </div>
          <ul>
           {/* Not implemented yet
              <li>
                <Link className="flex items-center px-3 py-1 text-sm font-medium text-brand opacity-80 hover:opacity-100" to="/" onClick={() => setDropdownOpen(!dropdownOpen)}>
                  Settings
                </Link>
              </li> 
              */}
              {/*isRiiotAdmin ?
              <li>
        
              </li> 
              : null */}
              <li>
              <button onClick={handleSignOut} type="button" className="px-3 py-1 text-sm font-medium text-brand opacity-80 hover:opacity-100">
              Sign Out
              </button>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserMenu;
