import React from 'react'
import FilterIcon from "../icons/FilterIcon"
import { DropDownArrow } from "../icons/SidebarIcons"

export default function FilterButton({isFilter,setIsFilter}) {
  return (
    <button type="button" onClick={() => setIsFilter((filter) => !filter)} className="inline-flex items-center gap-4 btn text-primary group hover:text-brand ">
    <FilterIcon />
    <span className="inline-flex items-center gap-1">
        {isFilter ? "Hide Filters" : "Filter"}
      <DropDownArrow style={`fill-current h-2 w-2 ${isFilter && "rotate-180"}`} />
    </span>
  </button>
  )
}
