import { createSlice } from "@reduxjs/toolkit";
import { isValidNumber } from "../../core/utilities";

const initialState = {
  user: { isSignedIn: false },
  signOutMode: undefined,
  signIn: undefined
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setCredentials: (state, action) => {
        const { accessToken, id, designation, emailAddress, fullName, isRiiotAdmin, isRiiotUser, role, roleInfo, access, entity, tokenExpiry,refreshTokenExpiry, isRemoteLogEnabled } = action.payload;
        const isSignedIn = isValidNumber(id) && accessToken && emailAddress && emailAddress.length > 3;
        const signInDateTime = isSignedIn ? new Date().toISOString() : undefined;
        state.signIn = undefined;  
        state.signOutMode = undefined; 
        state.user = {
            isSignedIn,
            id,
            emailAddress,
            fullName,
            designation,
            isRiiotAdmin,
            isRiiotUser,
            role,
            roleInfo,
            access,
            entity,
            refreshTokenExpiry,
            tokenExpiry,
            token: accessToken,
            signInDateTime,
            isRemoteLogEnabled
      };
    },
    clearSignInCredentials: (state) => {
        state.user = { isSignedIn: false };
        state.signIn = undefined;
    },
    setSystemSignOut: (state, action) => {
      const { status } = action.payload;
      state.signOutMode = { isUserSignOut: false, isSystemSignOut: true, status };
    },
    setUserSignOut: (state) => {
      state.signOutMode = { isUserSignOut: true };
      },
    setSignInFail: (state, action) => { 
          const { emailAddress } = action.payload; 
          const failedSignIn = state.signIn?.emailAddress === emailAddress ? state.signIn.failedSignIn + 1 : 1;
          state.signIn = { emailAddress: emailAddress, failedSignIn: failedSignIn };
    },
    clearSignInFail: (state) => { 
      state.signIn = undefined;
  }
  }
});

export const { setCredentials, clearSignInCredentials, setSystemSignOut, setUserSignOut, setSignInFail, clearSignInFail } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentEmailAddress = (state) => state.auth.user.emailAddress;
export const selectCurrentEntity = (state) => state.auth.user.entity;
