import React, { useState, useEffect } from "react";
import Alert from "../components/cards/Alert";
import { Layout, NoRecords } from "../components";

const alerts = [];
/* static data sample
const dataStatic =[
    {
        'name':'Toyota Corolla 1.8 XS CVT',
        'status':'new',
        'vehicleReg':'CDS 791 MP',
        'description':'Mass or Volume Air Flow "A" Circuit Range/Performance Problem', 'alertCode':'P0101', 
        'priority':'high'   
    },
    {
        'name':'Toyota Corolla 2.0 XR CVT',
        'status':'new', 
        'vehicleReg':'CGH 791 MP', 
        'description':'Intake Air Temperature Circuit High Input Bank 1', 
        'alertCode':'P0113', 
        'priority':'high' 
    },
    {
        'name':'Toyota Corolla 1.8 XS CVT',
        'status':'in progress',
        'vehicleReg':'DCW 127 MPP', 
        'description':'Injector Circuit Malfunction - Cylinder 1', 
        'alertCode':'P0201',
        'priority':'medium' 
      
    },
    {
        'name':'Toyota Corolla 5.1 XS CVT',
        'status':'in progress',
        'vehicleReg':'DCW 127 MPP',
        'description':'Intake Air Temperature Circuit High Input Bank 1',
        'alertCode':'P0113',
        'priority':'high',
      
    },
    {
        'name':'Volkswagen Polo GTi', 
        'status':'completed', 
        'vehicleReg':'RRT 392 MP', 
        'description':'Speeding FineIntake Air Temperature Circuit High Input Bank 1', 
        'alertCode':'P0222', 
        'priority':'low'
      
    }
      
    ]
    */

export default function Alerts() {
    const isLoading = false;
    const alertsFound = false;



 


  
    return (isLoading ? <DisplayAlerts alerts={[]} isLoading={isLoading}  /> : 
    alertsFound ? <DisplayAlerts alerts={alerts} /> : 
    <NoRecords title="Alerts" message="No Alerts found." />);
}

function DisplayAlerts({ alerts, isLoading=false }) {
  
    const [alertsData, setAlertData ] = useState(alerts);
    const [filter, setFilter] = useState();
    const [newAlerts, setNewAlerts] = useState();
    const [CompletedAlerts, setCompleted] = useState();
    const [InProgressAlerts, setInProgress] = useState();



    useEffect(() => {
        if(filter === "completed"){
            setAlertData(CompletedAlerts)
        }else if (filter === "in progress"){
            setAlertData(InProgressAlerts)
        }else if (filter === "new"){
            setAlertData(newAlerts)
        }else{
            setAlertData(alerts)
        }
      }, [CompletedAlerts, InProgressAlerts, alerts, filter, newAlerts]);

    useEffect(() => {
        setCompleted(alerts.filter(el => el.status === "completed"))
        
        setInProgress(alerts.filter(el => el.status === "in progress"))
        setNewAlerts(alerts.filter(el => el.status === "new"))
      }, [alerts]);
    
  return (
    <Layout isLoading={isLoading} loadingText="Loading Alerts" >
        <header className="flex flex-row pr-4 rounded-sm shadow-lg bg-other col-span-full xl:col-span">
    {/* vehicle details name*/}
          <div className='flex flex-row items-center gap-2 px-5 py-4 font-semibold text-primary'>
              <h2 className="font-semibold text-primary">Alerts</h2>
          </div>
            {/* download button */}
            <div className='flex flex-row items-center gap-4 text-brand'>
                  <button type='button' onClick={() => setFilter("all")}
                      className="italic hover:underline">All</button>
                  <button type='button' onClick={() => setFilter("new")}
                      className="px-2 text-sm italic bg-blue-400 rounded-full hover:underline text-other">New</button>

                  <button type='button' onClick={() => setFilter("in progress")}
                      className="px-2 text-sm italic bg-yellow-400 rounded-full hover:underline text-other">In Progress</button>
                  <button type='button' onClick={() => setFilter("completed")}
                      className="px-2 text-sm italic bg-green-400 rounded-full hover:underline text-other">Completed</button>
            </div>
        </header>
        
        <section className={"grid grid-cols-1 sm:grid-cols-2 gap-8 mt-8  lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"}>
        
        {alertsData?.map((alert, index) => (
            <Alert 
            key={index}
            name={alert.name} 
            status={alert.status} 
            vehicleReg={alert.vehicleReg}
            description={alert.description} 
            alertCode={alert.alertCode}
            priority={alert.priority} />  
        ))}
 
        </section>

        
    </Layout>
  );
}
