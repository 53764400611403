import React from "react";
import Timeline from "../../../components/Timeline";
import { SmsMessageIcon } from "../../../components/icons";
import { dateTimeDisplay, removeDuplicates } from "../../../core/utilities";

function DeviceSmsAlertView({ deviceSmsAlerts }) {
  const deviceSmsOutTimeLine = () => {
    const alerts = removeDuplicates(deviceSmsAlerts, "sms_id");

    return alerts?.map((smsAlert) => {
      return {
        date: dateTimeDisplay(smsAlert.data_timestamp),
        title: smsAlert.description,
        description: `${smsAlert.alert_type} ${smsAlert.alert_value}`
      };
    });
  };

  // console.log({ deviceSmsAlerts });

  return (
    <section className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
      <div className="section-container lg:col-span-3" tabIndex="4">
        <h2 className="font-semibold">Device SMS Alerts</h2>
        <Timeline items={deviceSmsOutTimeLine()} />

        {deviceSmsAlerts.length === 0 ? (
          <p className="inline-flex gap-2">
            {" "}
            <SmsMessageIcon classes={"fill-current"} /> No SMS Alerts
          </p>
        ) : null}
      </div>
    </section>
  );
}

export default DeviceSmsAlertView;
