import React, {useEffect,useState} from "react";
import zxcvbn from "zxcvbn";

const PasswordStrengthMeter = ({ password, setStrength }) => {

  const [passwordTestResult, setPasswordTestResult] = useState();
  const [scorePercent,setScorePercent] =useState();

  useEffect(() => {
    const testResult = zxcvbn(password);
    setPasswordTestResult(testResult);
    setScorePercent((testResult?.score|0) * 25);
    setStrength(testResult);

  },[password])

  
  const setProgressLabel = () => {

    if (password === "")
    {
      return "";
    }

    switch (passwordTestResult?.score) {
      case 0:
        return "Very Weak";
      
      case 1:
        return "Weak";        
      
      case 2:
        return "Fair";
      
      case 3:
        return "Good";
      case 4:
          return "Strong";
    
      default:
        return "";
    }
  }
  
  const setProgressColor = () => {
    switch (passwordTestResult?.score) {
      case 0:
        return "#828282";
      
      case 1:
        return "#EA1111";        
      
      case 2:
        return "#FFAD00";
      
      case 3:
        return "#9BC159";
      case 4:
          return "#00B500";
    
      default:
        return "none";
    }
  }
  const changePasswordColor = () => ({
    width: `${scorePercent}%`,
    background: setProgressColor()
  })

  return ( password ?
    <div className="text-right">
      <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
        <div className="bg-blue-600 h-2.5 rounded-full" style={changePasswordColor()}></div>
      </div>
      <p style={{ color: setProgressColor() }}>{setProgressLabel() }</p>
    </div> : ""
  )
}

export default PasswordStrengthMeter