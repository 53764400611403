import React from 'react'
import { NavLink } from "react-router-dom";

function Timeline({items}) {
  return  items ? <DisplayTimeLine items={items} /> : <></>
}

function DisplayTimeLine({items}) {
    return (
   
        <ol className="relative border-l border-gray-200 dark:border-gray-700">      
            {items.map((item,i) => (<DisplayTimeLineItem item={item} key={i} />))}    
        </ol>
        
          )
}

function DisplayTimeLineItem({ item }) {
    const {date,title,subTitle,description,link} = item;
    return (<li className="mb-10 ml-4">
    <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-other dark:border-gray-900 dark:bg-gray-700"></div>
        <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">{date}</time>
        {title ? <h3 className="text-xl font-bold text-primary">{title}</h3> : null}
        {subTitle ? <h4 className="text-lg font-semibold text-secondary">{subTitle}</h4> : null}
        {description ? <p className="mb-4 text-base font-normal text-tertiary ">{description }</p> : null}
        {link ? 
            <NavLink end to={link.path} className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 border rounded-lg border-secondary bg-other hover:bg-brand hover:text-other focus:z-10 focus:ring-4 focus:outline-none focus:text-brand ">
                {link.title}
</NavLink> : null}
</li>)
}

export default Timeline