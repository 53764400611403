import React from "react";
import { Link, NavLink } from "react-router-dom";
import { SidebarMenuIcon } from "./SidebarMenuIcon";

const MinimizedSingleOptionMenu = ({ menuName, menuIcon, isActiveItem, url }) => {
  return (
    <div className="flex items-center justify-center w-full group">
      <div className={`p-2 rounded-md  ${isActiveItem ? "bg-brand group-hover:sidebar-brand" : "group-hover:bg-sidebar-active"}`}>
        <Link to={url}>
          <SidebarMenuIcon isActive={isActiveItem} menuIcon={menuIcon} />{" "}
        </Link>
      </div>

      <div className="absolute hidden px-4 py-2 mt-1 rounded-xl  left-[5.5rem] z-60 group-hover:block bg-sidebar-active">
        <div className={" py-2 relative"}>
          <svg className="absolute fill-sidebar-active -left-[1.8rem]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M8 12l6-6v12z" />
          </svg>

          <ul>
            <li className="mb-2 last:mb-0">
              <NavLink
                end
                to={url}
                className={({ isActive }) =>
                  "block hover:border-l-2 hover:border-brand  pl-1  transition duration-150 truncate group-hover:text-sidebar-other " + (isActive ? "!text-brand" : "text-sidebar-link")
                }
              >
                <span className="text-sm font-medium duration-200 lg:sidebar-expanded:opacity-100 2xl:opacity-100">{menuName}</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MinimizedSingleOptionMenu;
