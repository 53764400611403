import React, {useEffect, useState} from 'react'
import Stepper from 'react-stepper-horizontal';
import { Layout, OnError } from "../../components";
import CsvToJsonInput from "../../components/CsvToJsonInput";
import { camelCaseToTitleCase,dateTimeDisplay,stringToCurrency,pluralOf } from "../../core/utilities";
import { useCreateVehiclesFuelTransactionsMutation } from "../../api/rtk/vehicleFuelApi";
import { Link } from "react-router-dom";
import Api from "../../api";

export default function ImportStdFuelAnalysis() {

    const [jsonData, setJsonData] = useState();
    const [importStarted, setImportStarted] = useState(false);
    const [importError, setImportError] = useState();
    const { data:vehiclesRegNumbersAndFuelTrans=[],  isError, error } = Api.vehicles.useGetAllFuelTransactionAndVehiclesRegistrationNumbersQuery();

    const handleOnStarted = () => {
        setImportStarted(true);
    }

    const handleOnComplete = (jsonFileData) => {
        setJsonData(jsonFileData);
    }

    const handleOnImportError = (error) => { 
      
        setImportError(error);
    }



    const ignoreColumns =  /(Product|Override)/
    const colParser = {
        "fuelTransactionDateTime": function (item) { return new Date(item); },
        "fuelTransactionCardNumber": "omit",
        "fuelTransactionAccountNumber": "omit",
        "fuelTransactionDate": "omit",
        "fuelTransactionTime": "omit",
        "fuelTransactionDeclineReason": "omit",
        "fuelTransactionOverrideButton": "omit",
        "fuelTransactionTransactionOverridable":"omit",
        "fuelTransactionReversed": "omit",
        "fuelTransactionValueOfTransaction": "number",
        "fuelTransactionOdometerReading": "number",
        "fuelTransactionFuelQuantity": "number",
        "fuelTransactionFuelAmount": "number",
        "fuelTransactionFuelCost": "number"
    };
    
    const displayMessage = `You are about to import Data from Standard Bank into the system. Make sure that the file (csv) is for this client.
    You will not be able to do the import if the file was imported before. Please click the Upload button to start the process`;
    const headers = ["fuelTransactionDateTime","fuelTransactionDate","fuelTransactionTime","fuelTransactionAccountNumber","fuelTransactionCardNumber","fuelTransactionRegistrationNumber","fuelTransactionVehicleDescription","fuelTransactionMerchantName","fuelTransactionStatus","fuelTransactionOverrideButton","fuelTransactionDeclineReason","fuelTransactionValueOfTransaction","fuelTransactionOverride","fuelTransactionReversed","fuelTransactionOdometerReading","fuelTransactionFuelCost","fuelTransactionFuelQuantity","fuelTransactionFuelAmount","fuelTransactionTransactionOverridable","fuelTransactionTransactionOverrideLimit","fuelTransactionProductCode1","fuelTransactionProductDescription1","fuelTransactionProductAmount1","fuelTransactionProductCode2","fuelTransactionProductDescription2","fuelTransactionProductAmount2","fuelTransactionProductCode3","fuelTransactionProductDescription3","fuelTransactionProductAmount3","fuelTransactionProductCode4","fuelTransactionProductDescription4"];
    
    const matchHeadingTo = `"DateTime","Date","Time","Account number","Card number","Registration number","Vehicle description","Merchant name","Status","Override button","Decline reason","Value of transaction","Override?","Reversed?","Odometer reading","Fuel cost","Fuel quantity","Fuel amount","Transaction overridable","Transaction override Limit","Product code","Product description","Product amount","Product code","Product description","Product amount","Product code","Product description","Product amount","Product code","Product description"`;
   
    if (vehiclesRegNumbersAndFuelTrans && vehiclesRegNumbersAndFuelTrans.length > 0) {
        console.log(vehiclesRegNumbersAndFuelTrans);
    }
    
    return (
      isError ? <OnError error={error} /> :
      <Layout>
        <header className="flex flex-row justify-between px-5 rounded-sm shadow-lg bg-other col-span-full xl:col-span ">
                    {/* link to driver listing page and driver name */}
        
        <div className="flex flex-row items-center gap-2 py-4 font-semibold text-primary">
          Import Standard Bank Fuel Analysis 
        </div>
        </header>
          <CsvToJsonInput onComplete={handleOnComplete} onStarted={handleOnStarted} onError={handleOnImportError} colParser={colParser} headers={headers} ignoreColumns={ignoreColumns} matchHeadingTo={matchHeadingTo} displayMessage={displayMessage}  /> 
          <ImportFuelProgress importStarted={importStarted} importError={importError} data={jsonData} vehiclesRegNumbersAndFuelTrans={vehiclesRegNumbersAndFuelTrans} />
    </Layout>
  )
}

function ImportFuelProgress({ importStarted, importError, vehiclesRegNumbersAndFuelTrans, data }) { 
    
    const [activeStage, setActiveStage] = useState(1);
    const [stageData,setStageData] = useState();
    const [activeStageError, setActiveStageError] = useState(null);
    const [isComplete, setIsComplete] = useState(false);
    const [completeInfo, setCompleteInfo] = useState();
    const [saveError, setSaveError] = useState();
    const [saveFuelTransactions] = useCreateVehiclesFuelTransactionsMutation(); 
    

    const handleOnSave = async(vehiclesTransactions) => {
        return saveFuelTransactions(vehiclesTransactions).unwrap();
    }

    const handleSaveComplete = (completeInfo) => {
        setIsComplete(true);
        setCompleteInfo(completeInfo);
        console.log({ completeInfo });
    }

    useEffect(() => {
        // console.log({data,activeStage, activeError, importStarted})
        if (activeStage === 1 && data) {
            
            firstStageImport(data, vehiclesRegNumbersAndFuelTrans).then((importData) => {
                setStageData(importData);
                setActiveStage(2);
                
            }).catch((error) => {
                setActiveStageError({
                    errorInfo: error,
                    errorMessage: "We could not complete the import process because the following vehicle information is missing from the dashboard."
                });
                // console.error(error);
            })
        }
        else if (activeStage === 2 && stageData) {
            secondStageImport(stageData, vehiclesRegNumbersAndFuelTrans).then((importData) => {
                setStageData(importData);
                setActiveStage(3);
                
            }).catch((error) => {
                
                setActiveStageError({
                    errorInfo: error,
                    errorMessage: "We could not complete the import process because the following vehicle information already has fuel import data the dashboard."
                });
                
                // console.error(error);
            })
        }
        else if (activeStage === 3 && stageData) {
            thirdStageSaveImport(stageData, vehiclesRegNumbersAndFuelTrans, handleOnSave, handleSaveComplete).then((processData) => {
                console.log(processData);
              
                if (processData.withErrors) {
                    const { processed, withErrors } = processData;
                    const firstError = processData.errors[0];
                    const errorDescription = firstError.status ? `Error Status Code:${firstError.status}` : JSON.stringify(firstError.error);
                    const errorInfo = {error: `${pluralOf("record",withErrors)} of ${pluralOf("record",processed)} could not be saved. Please contact RIIOT regarding a solution to this Error: ${errorDescription}`,
                    errorType: "DB Save Failed"}
                    setSaveError(errorInfo);
                    
                }
                else {
                    setActiveStage(4);
                }
                
            }).catch((error) => {
                setIsComplete(false);
                console.error(error);
           
                setSaveError({errorType:"Transaction to DB",error:error.errorMessage });
            })
        }

        
    }, [activeStage,data])
    
    const showProgress = importStarted && !isComplete && !activeStageError && !importError && !saveError;
    console.log({ importStarted, activeStageError, importError, saveError });

    return (
        <div>
            {showProgress ?
                <Stepper steps={[{ title: 'Import Csv' }, { title: 'Process Data' }, { title: 'Update Analysis' }, { title: 'Complete' }]} activeStep={activeStage - 1} />
                : importError ? <DisplayImportErrorMessage importError={importError} />    
                : saveError ?  <DisplayImportErrorMessage importError={saveError} />  
                : activeStageError ? <DisplayImportErrorInfo activeError={activeStageError} /> 
                : isComplete ? <DisplayImportCompleteInfo completeInfo={completeInfo}/>
                : null
            }
        </div>)
    
}

function DisplayImportCompleteInfo({completeInfo})
{
    const { saved, count } = completeInfo;
    const completeMessage = `Fuel Transaction data has been completed.
    ${pluralOf("record",count)} considered and ${pluralOf("record",saved)} saved successfully.`;

return <div>
    <h3 className="p-8 font-semibold text-green-500">{completeMessage}</h3>
    <LinkToFuelAnalysis/>
</div>
}

function DisplayImportErrorMessage({ importError }) {

    const { error, errorType } = importError;

    return <div>
        <h3 className="p-4 font-semibold text-red-500">{errorType}</h3>
        <p className="m-6 text-primary">{error}</p>
        <LinkToFuelAnalysis/>
    </div>
}

function DisplayImportErrorInfo({activeError}) {
    const { errorInfo, errorMessage } = activeError;
     const columnNames = errorInfo ?  Object.keys(errorInfo[0]).map(column => camelCaseToTitleCase(column)) : [];
 
    return (<>
        <h3 className="p-8 font-semibold text-red-500">{errorMessage}</h3>
        <table className="border border-green-300 detail-table">
            <thead>
                <tr>
                {columnNames.map((columnName,i) => {
                    return <th key={i+ columnName} className="p-2 font-semibold text-left ">{columnName }</th>
                })}
                </tr>
            </thead>
            <tbody className="p-3">
                {errorInfo.map((error,eIndex) => {
                    return <>
                        <tr  key={eIndex + error} className="hover:bg-brand text-primary hover:text-other">
                            {Object.values(error).map((errValue,i) => { return <td key={i + errValue}>{errValue}</td> })}
                        </tr>
                    </>
                })}
            </tbody>
        </table>
        <LinkToFuelAnalysis/>
    </>)
    
    
}

function LinkToFuelAnalysis() {
    
    return <div className="flex flex-col w-1/3 gap-4 mt-10 ml-14">
            <Link to={"/insights"}>
                <button type="button" className="btn-primary">
                Return to Fuel Analytics
                </button>
            </Link>
            </div>
}

async function firstStageImport(jsonData, vehiclesRegNumberAndLastTransDate) {

    return new Promise((resolve, reject) => {
        const missingVehicleRegistrationNumbers = [];
        const dbRegistrationNumbers = vehiclesRegNumberAndLastTransDate.map(vehicle => vehicle.veh_registration_no);
        
        jsonData.sort((a, b) => { return a.fuelTransactionDateTime - b.fuelTransactionDateTime });

        const vehiclesFuel = jsonData.reduce((acc, item) => {
            const { fuelTransactionRegistrationNumber, fuelTransactionDateTime, fuelTransactionOdometerReading, fuelTransactionStatus } = item;
            let isMissingDbVehicle = false;
            

            //check if registration number is missing or not...
            if (!dbRegistrationNumbers.includes(fuelTransactionRegistrationNumber)) {
                isMissingDbVehicle = true;
                if (!missingVehicleRegistrationNumbers.find(vehicle => vehicle.registrationNumber === fuelTransactionRegistrationNumber)) {
                    missingVehicleRegistrationNumbers.push({ registrationNumber: fuelTransactionRegistrationNumber, vehicleDescription: item.fuelTransactionVehicleDescription });
                }
            }
            
            if (!isMissingDbVehicle) {
         
    
                if (fuelTransactionRegistrationNumber && fuelTransactionDateTime && fuelTransactionOdometerReading && fuelTransactionStatus === "Accepted") {
                    if (!acc[fuelTransactionRegistrationNumber]) {
                        acc[fuelTransactionRegistrationNumber] = [];
                    }
    
                    acc[fuelTransactionRegistrationNumber].push(item);
                }
            }

    
            return acc;

        },{});

        console.log({missingVehicleRegistrationNumbers})

        // TODO: Replace the line below once BLM Missing Registration Numbers are sroted.
        //TODO: change this so it fails as it should......
        //if (missingVehicleRegistrationNumbers.length > 0) { 
        if (false) {

            reject(missingVehicleRegistrationNumbers)
        }
        else {
            
            resolve(vehiclesFuel);
        }

    
    })
}


async function secondStageImport(vehicleFuelData, vehiclesRegNumberAndLastTransDate) {

    console.log({ vehiclesRegNumberAndLastTransDate });
    return new Promise((resolve, reject) => {
        const alreadyUpdatedVehicles = [];
        const dbRegNumberAndLastTransDate = vehiclesRegNumberAndLastTransDate.map((vehicle) => {
            return { transactionDate:vehicle.last_fuel_transaction_on ? new Date(vehicle.last_fuel_transaction_on) : null, registrationNumber: vehicle.veh_registration_no,id: vehicle.id };
        });
        
        const vehicleInfoKeys = Object.keys(vehicleFuelData);

        vehicleInfoKeys.forEach((registrationNumber) => { 
    
        vehicleFuelData[registrationNumber].map((transaction) => {

            //console.log({ dbRegNumberAndLastTransDate,registrationNumber });
            const dbVehicle = dbRegNumberAndLastTransDate.find(vehicle => vehicle.registrationNumber === registrationNumber);
            //check if any transaction date is bigger than the last db transaction date..
        
            console.log({ dbVehicle,alreadyUpdatedVehicles,transactionDate: transaction.fuelTransactionDateTime,transaction});
            if (dbVehicle.transactionDate && dbVehicle.transactionDate.getTime() >= transaction.fuelTransactionDateTime.getTime()) {

                if (!alreadyUpdatedVehicles.find(vehicle => vehicle.registrationNumber === registrationNumber)) {

                    alreadyUpdatedVehicles.push({
                        registrationNumber,
                        transactionAmount: stringToCurrency(transaction.fuelTransactionValueOfTransaction),
                        vehicleDescription: transaction.fuelTransactionVehicleDescription,
                        transactionDate: dateTimeDisplay(transaction.fuelTransactionDateTime),
                        merchantName: transaction.fuelTransactionMerchantName
                    });
                }
            }

        })
    })
      
        if (alreadyUpdatedVehicles.length > 0) { 

            reject(alreadyUpdatedVehicles)
        }
        else {
            
            resolve(vehicleFuelData);
        }

    
    })
}

async function thirdStageSaveImport(vehicleFuelData, vehiclesRegNumberAndLastTransDate, onSave, onSaveComplete) {

    
    return new Promise(async (resolve, reject) => {

        

        const dbRegNumberAndLastTransDate = vehiclesRegNumberAndLastTransDate.map((vehicle) => {
            return { transactionDate: vehicle.last_fuel_transaction_on, registrationNumber: vehicle.veh_registration_no, id: vehicle.id };
        });
        
        const vehicleInfoKeys = Object.keys(vehicleFuelData);
        console.log({ vehicleFuelData });

        const vehiclesTransactions = vehicleInfoKeys.map((registrationNumber) => {
            const dbVehicle = dbRegNumberAndLastTransDate.find(vehicle => vehicle.registrationNumber === registrationNumber);
            const vehicleTransactions = vehicleFuelData[registrationNumber].map((transaction) => {
                return {
                    vehicleId: dbVehicle.id,
                    transactionDateTime: transaction.fuelTransactionDateTime,
                    odometer: transaction.fuelTransactionOdometerReading,
                    transactionAmount: transaction.fuelTransactionValueOfTransaction,
                    merchantName: transaction.fuelTransactionMerchantName,
                    fuelCostPerLitre: transaction.fuelTransactionFuelCost,
                    fuelQuantity: transaction.fuelTransactionFuelQuantity,
                }
            });
        
            return { vehicle: dbVehicle, vehicleTransactions };
        });

        const mapPromise = onSave(vehiclesTransactions)
           

        mapPromise.then(async (results) => {
            let errorsFound = false;
            const processTransactionInfo = { saved: 0, processed: 0, withErrors: 0, errors: [] };
            results.forEach((result) => {
                processTransactionInfo.processed++;
                if (result.saved) {
                    processTransactionInfo.saved++;
                }
                else {
                    const error = result.error.data ? result.error.data : result.error;
                    const errorStatus = result.error.status ? result.error.status : undefined;
                    processTransactionInfo.withErrors++;
                    processTransactionInfo.errors.push({ error: error, status: errorStatus });
                    errorsFound = true;
                }
            })

            if (!errorsFound) {
                onSaveComplete();
            }
            resolve(processTransactionInfo);
        }).catch((err) => { 
            console.error(err);
            reject({ errorMessage: `Transactions could not be saved. Please try again later. Error Status : ${err.status}` });
        });
        
        
    })
}