import React from "react";
import InfoCard from "./InfoCard";

const VehicleWearTear = () => {
  return (
    <>
      <section className="section-container">
        <h2 className="font-bold">
          Vehicle Wear and Tear Predictions: <span className="px-3 py-1 text-sm bg-brand text-other rounded-xl">Updated 8 sec ago</span>
        </h2>

        <div className="grid grid-cols-4 mt-4">
          <div>
            <h3 className="font-bold">Normal</h3>
            <div className="flex flex-col gap-1">
              <ul className="ml-8 list-disc">
                <li>
                  Wheel Bearings: <span className="font-semibold capitalize text-brand">12100 Km</span>
                </li>
                <li>
                  Ball Joints: <span className="font-semibold capitalize text-brand">36543 Km</span>
                </li>
                <li>
                  Brake Discs: <span className="font-semibold capitalize text-brand">12369 Km</span>
                </li>
                <li>
                  Brake Pads: <span className="font-semibold capitalize text-brand">8912 Km</span>
                </li>
                <li>
                  Clutch: <span className="font-semibold capitalize text-brand">75123 Km</span>
                </li>
              </ul>
            </div>
          </div>

          <div>
            <h3 className="font-semibold">Actual</h3>
            <div className="flex flex-col gap-1">
              <ul className="ml-8 list-disc">
                <li>
                  Wheel Bearings: <span className="font-semibold capitalize text-brand">52100 Km</span>
                </li>
                <li>
                  Ball Joints: <span className="font-semibold capitalize text-brand">16543 Km</span>
                </li>
                <li>
                  Brake Discs: <span className="font-semibold capitalize text-brand">22369 Km</span>
                </li>
                <li>
                  Brake Pads: <span className="font-semibold capitalize text-brand">5912 Km</span>
                </li>
                <li>
                  Clutch: <span className="font-semibold capitalize text-brand">15123 Km</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="flex flex-col gap-2 mt-4">
        <h2 className="font-semibold text-primary ">Recommended Service Centers</h2>
        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
          <InfoCard centerName={"Toyota Hazyview"} address={"Stand 1511 Main Street Ext 9, Hazyview, 1242"} phone={"087-286-2700"} />
          <InfoCard centerName={"Bidvest McCarthy Nelspruit"} address={"7 Mystic Crescent, Riverside, Nelspruit, Mpumalanga"} phone={"011-450 -450"} />
        </div>
      </section>
    </>
  );
};

export default VehicleWearTear;
