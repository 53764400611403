import { useState } from "react";
import { Pie } from "@visx/shape";
import { Group } from "@visx/group";
import { Text } from "@visx/text";
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { pluralOf } from "../../core/utilities";


export default function DoughnutGraph({ graphData,entityName, onItemClick  }) {

    return graphData ?
        <ParentSize>
            {({ width, height }) => <DisplayGraphData graphData={graphData} entityName={entityName} width={width} height={height} onItemClick={onItemClick}/>}
        </ParentSize> : null;
    

}
const defaultMargin = { top: 10, right: 10, bottom: 10, left: 10 };

function DisplayGraphData({graphData,  entityName, width, height, margin = defaultMargin, onItemClick}) {
    const [active, setActive] = useState(null);
    const totalValue = graphData.reduce((acc, data) => acc + data.value, 0);

    if (width < 10) return null;
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    const radius = Math.min(innerWidth, innerHeight) / 2;
    const centerY = innerHeight / 2;
    const centerX = innerWidth / 2;
    const donutThickness = 7;
    const spreadItems = graphData.length <= 3;
    const totalFontSize = Math.min(height,width) / 10;
    const activeFontSize = Math.min(height,width) / 12;
    const itemsFontSize = Math.min(height,width) / 14;
    const dyOffset = Math.min(height,width) / 9.5;

  // console.log({width,height,itemsFontSize,totalFontSize,dyOffset});
  
    return (
      <main>
        <svg width={width} height={height}>
        <Group top={centerY + margin.top} left={centerX + margin.left}>
            <Pie
              data={graphData}
              pieValue={(data) => data.value}
              
              outerRadius={({ data }) => {
                return active && active.label === data.label ? radius + 1 : radius;
                
              }}
              innerRadius={({ data }) => {
                return active && active.label === data.label ? radius - donutThickness - 1 : radius - donutThickness;
               
              }}
              oouterRadius={radius}
              oinnerRadius={radius - donutThickness}
              cornerRadius={2}          
              padAngle={0.01}
            >
              {(pie) => {
                
                return pie.arcs.map((arc) => {
                  return (
                    <g
                    key={arc.data.label}
                    onMouseEnter={() => setActive(arc.data)}
                    onMouseLeave={() => setActive(null)}
                    onClick={() => onItemClick && onItemClick(arc.data)}
                  >
                    <path d={pie.path(arc)} fill={arc.data.colour}></path>
                  </g>
                  );
                });
              }}
            </Pie>
  
            {active ? (
              <>
                <Text
                  textAnchor="middle"
                  fill={active.colour}
                  fontSize={activeFontSize}
                >
                  {pluralOf(`${active.label} ${entityName}`,active.value)}
                </Text>
                <Text
                  textAnchor="middle"
                  fill={active.colour}
                  dy={dyOffset}
                  fontSize={activeFontSize}
                >
                  {`${Math.round((100 * active.value)/totalValue,2)}%`}
                </Text>
              </>
            ) :
              spreadItems ? (
                <>
                  <Text textAnchor="middle" fill="#A9A9A9" fontSize={totalFontSize} dy={0-dyOffset}>
                  {pluralOf(entityName,totalValue)}
                  </Text>
                  {graphData.map((data,index) => {
                    return <Text key={index} textAnchor="middle" fill={ data.colour} fontSize={itemsFontSize} dy={0 + (dyOffset * index)}>
                      {`${data.value} ${data.label}`}
                    </Text>

                  })}
                </>
              ):
              (
              <>
 
                <Text textAnchor="middle" fill="#A9A9A9" fontSize={totalFontSize}>
                  {pluralOf(entityName,totalValue)}
                </Text>
              </>
            )}
          </Group>
        </svg>
      </main>
    );


}
