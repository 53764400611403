import { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { RBAC } from "rbac";
import { selectCurrentUser } from "../features/auth/authSlice";


function isUserSignedIn(user) {
    const {isSignedIn,token } = user;
    return token && isSignedIn ? true : false;
  }

async function doesUserHaveAccess(user,feature) {

  if (feature && user?.role) {
    

    const role = user.role
      const { roles, permissions, grants } = role;
      const rbac = new RBAC({ roles, permissions, grants });
      await rbac.init();
      const userCan = await rbac.can(roles[0], feature.action, feature.name);
      // console.log({roleInfo, roles, permissions, grants,feature,userCan });

      return userCan;
    }
    else if (user && !feature) {
      return true;
  }
    else
    {

      return false;
    }
  }

function useHasAccessToFeature(feature) {

    const user = useSelector(selectCurrentUser);
    const [isSignedIn] = useState(isUserSignedIn(user));
    const [hasAccess, setHasAccess] = useState(doesUserHaveAccess(user,feature));
    
    useEffect(() => {
 
        if (isSignedIn) {
          if (feature) {
            doesUserHaveAccess(user, feature).then(haveAccess => {
              // console.log({user,feature, haveAccess});
              setHasAccess(haveAccess);
            })
          }
          else {
            setHasAccess (true);
          }
    
        }
      },[feature,isSignedIn,user]);


    return { hasAccess, isSignedIn }
}

export default useHasAccessToFeature