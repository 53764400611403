import { useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar,getElementAtEvent } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarGraph = ({ title, onItemClick , graphData = {}, backgroundColor = [] }) => {
  const chartRef = useRef();
  
  const handleOnClick = (event) => {

    if (onItemClick) {
      const element = getElementAtEvent(chartRef.current, event);
      if (element && element.length > 0) {
        const parsed = element[0].element.$context.element.$context.parsed;
        const { x: row, y: value } = parsed;
        onItemClick(row, value);
      }
      
      
    }
   
  }
  
  const {labels =[], values =[]} = graphData;
 
  const data = {
        labels ,
        datasets: [{
          label: title,
          data: values,
          backgroundColor,
        //   borderColor: [
        //     'rgb(255, 99, 132)',
        //   ],
          borderWidth: 1
        }]
      };

  const config = {
          responsive: true,
          plugins: {
            legend: {
              display: false,
              position: "top"
            },
            title: {
              display: true,
              text: title,
              position: "left"
            }

        },
        borderRadius: 8,
        scales: {
            x: {
              grid: {
                display: false
              }
            },
            y: {
              grid: {
                display: false
              }
            }
          }
      };
  return ( 
        <div className='mx-auto'>
            <Bar data={data} options={config} onClick={handleOnClick} ref={chartRef}/>
        </div>
     );
}
 
export default BarGraph;