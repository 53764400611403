import React, { useState } from "react";
import { Link } from "react-router-dom";
import Api from "../../api";
import { useResendUserRegistrationEmailMutation, useResetUserRegistrationKeyExpiryMutation } from "../../api/rtk/userRegistrationsApi";
import { AlertDialog, ConfirmationDialog, InfoDialog, Layout, NoRecords, OnError, PageFilter, Pagination, PrimaryHeader } from "../../components";
import Filter from "../../components/filter/Filter";
import FilterButton from "../../components/filter/FilterButton";
import { Table, TableColumnOptions } from "../../components/table";
import UserRoleAccess from "../../constant/userRoleAccess";
import { isValidArrayWithData } from "../../core/utilities";
import FilterOptions from "../../filter/FilterOptions";
import useHasAccessToFeature from "../../hooks/useHasAccessToFeature";
import usePageFilter from "../../hooks/usePageFilter";
import TablesSkeleton from "../../skeletons/TablesSkeleton";
import ProcessFlowDialog, { UserProcessFlowInit } from "../../components/flow/ProcessFlowDialog";
import { ProcessFlowStage } from "../../constant";

export default function UserRegistrations() {
  const { data: userRegistrations, isLoading, isError, error } = Api.userRegistrations.useGetAllUserRegistrationsQuery();
  const { hasAccess: hasAccessToCreateUser } = useHasAccessToFeature(UserRoleAccess.userCreate);
  const userRegistrationsFound = isValidArrayWithData(userRegistrations);

  return isError ? (
    <OnError error={error} />
  ) : isLoading ? (
    <TablesSkeleton isLoading={isLoading} title="User Registrations" />
  ) : userRegistrationsFound ? (
    <DisplayUserRegistrations userRegistrations={userRegistrations} canCreateUser={hasAccessToCreateUser} />
  ) : (
    <NoRecords title="User Registrations" message="No User Registrations found.">
      <NewUserButton canCreateUser={hasAccessToCreateUser} />
    </NoRecords>
  );
}

function DisplayUserRegistrations({ userRegistrations, canCreateUser }) {
  const [displayData, setDisplayData] = useState(userRegistrations);
  const [isFilter, setIsFilter] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isLoadingInfo, setIsLoadingInfo] = useState({ status: false });
  const [userProcessFlow, setUserProcessFlow] = useState(UserProcessFlowInit)
  const [commandConfirmation, setCommandConfirmation] = useState({ confirm: false });
  const [alertInfo, setAlertInfo] = useState({ show: false });
  const [info, setInfo] = useState({ show: false });
  const [resetUserRegistrationKeyExpiry] = useResetUserRegistrationKeyExpiryMutation();
  const [resendUserRegistrationEmail] = useResendUserRegistrationEmailMutation();

  // used with the pagination
  const {  numberOfItemsOnCurrentPage, itemsPerPage, currentPage, numberOfPages, totalNumberOfItems, handleItemsPerPageChange, handlePageClick } = usePageFilter({ displayData });

  const handleFilterDataChange = (filteredData) => {
    setIsFiltered(filteredData.length !== userRegistrations.length);
    setDisplayData(filteredData);
  };

  const filterOptions = () => {
    const option = new FilterOptions();
    return option.getUserRegistrations();
  };

  const handleTableButtonClick = async (clickedRow) => {
    const { buttonKey, id } = clickedRow;

    const confirmMessage = `Are you sure you want to ${buttonKey === "resend_email" ? `resend the confirmation email to ${id}?` : `reset the registration expiry date for email: ${id}?` }`

    setUserProcessFlow({stage: ProcessFlowStage.Confirm, confirmMessage, processId: buttonKey, processData: {id}})
  };

  const onUserProcessConfirm = async({ confirm, stage, processId }) => {

    const isResendEmail = processId === "resend_email"


    if (confirm.yes) {
      const { id } = userProcessFlow.processData;
      const processMessage = `Processing Registration ${isResendEmail ? "Email" : "Expiry"} Resend for ${id}` 
      setUserProcessFlow({ stage: ProcessFlowStage.Processing, processMessage });

      if (isResendEmail) {
        const response = await resendUserRegistrationEmail(id).unwrap();

        const message = response.success ? "Registration Email Successfully Resent" : "Registration Email could not Resent!";
        console.log({ response });
        setUserProcessFlow({ stage: response.success ? ProcessFlowStage.Info : ProcessFlowStage.Alert, alertMessage: message , infoMessage: message });
      }
      else
      {
        const response = await resetUserRegistrationKeyExpiry(id).unwrap();
        
        }

    }
    else {
      console.log({ confirm, stage, processId });
      setUserProcessFlow({ stage: ProcessFlowStage.NotStarted });
    }
    
  }

  const handleNegativeConfirmation = () => {
    setCommandConfirmation({ confirm: false });
  };

  const handlePositiveConfirmation = async () => {
    const { buttonKey, id } = commandConfirmation;

    setCommandConfirmation({ confirm: false });

    if (buttonKey === "resend_email") {
      setIsLoadingInfo({ status: true, text: "Processing Registration Email Resend" });
      const response = await resendUserRegistrationEmail(id).unwrap();
      setIsLoadingInfo({ status: false });
      if (response.success) {
        setInfo({ show: true, message: "Registration Email Successfully Resent" });
      } else {
        setAlertInfo({ show: true, message: "Registration Email could not Resent!" });
      }
    } else if (buttonKey === "reset_expiry") {
      setIsLoadingInfo({ status: true, text: "Processing Registration Expiry Date Reset" });
      const response = await resetUserRegistrationKeyExpiry(id).unwrap();
      setIsLoadingInfo({ status: false });
     
      if (response.success) {
        setInfo({ show: true, message: "Registration Expiry Date Successfully Reset" });
      } else {
        setAlertInfo({ show: true, message: "Registration Expiry Date could not Reset!" });
      }
    }
  };

  // console.log({ displayData, userRegistrations });
  return (
    <Layout isLoading={isLoadingInfo.status} loadingText={isLoadingInfo.text}>
      <PrimaryHeader title="User Registrations">
        <div className="flex">
          <NewUserButton canCreateUser={canCreateUser} />
          <FilterButton isFilter={isFilter} setIsFilter={setIsFilter} />
        </div>
      </PrimaryHeader>
      <ConfirmationDialog show={commandConfirmation.confirm} onNo={handleNegativeConfirmation} onYes={handlePositiveConfirmation} confirmationMessage={commandConfirmation.message} />
      <AlertDialog show={alertInfo.show} alertMessage={alertInfo.message} onClick={() => setAlertInfo({ show: false })} />
      <InfoDialog show={info.show} infoMessage={info.message} onClick={() => setInfo({ show: false })} />
      <ProcessFlowDialog
        processId={userProcessFlow.processId}
        processStage={userProcessFlow.stage}
        alertMessage={userProcessFlow.alertMessage}
        confirmMessage={userProcessFlow.confirmMessage}
        infoMessage={userProcessFlow.infoMessage}
        processingMessage = {userProcessFlow.processMessage}
        onConfirm={onUserProcessConfirm} />
      <Filter enabled={isFilter} data={userRegistrations} filterOptions={filterOptions()} handleFilterDataChange={handleFilterDataChange} />
      <section className="detail-section">
        <PageFilter
          itemsPerPage={itemsPerPage}
          setItemsPerPage={handleItemsPerPageChange}
          numberOfItemsOnCurrentPage={numberOfItemsOnCurrentPage}
          recordsName={"User Registrations"}
          isFiltered={isFiltered}
          totalNumberOfItems={totalNumberOfItems}
        />
        <Table
          data={displayData}
          pagingInfo={{ dataPerPage: itemsPerPage, currentPage }}
          tableColumns={TableColumnOptions.userRegistrations}
          initialSort={{ key: "email_address", order: "asc" }}
          onButtonClick={handleTableButtonClick}
          includeSequentialId={true}
        />

        <Pagination handlePageClick={handlePageClick} numberOfPages={numberOfPages} />
      </section>
    </Layout>
  );
}

function NewUserButton({ canCreateUser }) {
  return (
    <>
      {canCreateUser ? (
        <Link to="/users/registrations/new-user">
          <button type="button" className="inline-flex items-center gap-4 px-3 mx-3 text-sm border rounded-md border-brand text-primary hover:text-brand py-1.5">
            Create User
          </button>
        </Link>
      ) : null}
    </>
  );
}
