import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Layout, LeftArrowIcon, PageFilter, Pagination, RightFilledIcon } from "../../components";
import usePageFilter from "../../hooks/usePageFilter";
import FilterButton from "../../components/filter/FilterButton";

//! Sample data below
// sample data
const data = [
  {
    device_id: "432020320",
    vin: "JTFSX22P70665474",
    reg_number: "DS83BMGP",
    make: "TOYOTA",
    model: "QP",
    dtc_code: "P0016",
    issues: "Throttle Actuator Control Motor Current Range Performance",
    rating: "Critical",
    last_reported: "Jun 10, 2022 4:50 PM"
  },
  {
    device_id: "3743290",
    vin: "JTFSX22P70665474",
    reg_number: "DS83BMGP",
    make: "TOYOTA",
    model: "QP",
    dtc_code: "P0016",
    issues: "Throttle Actuator Control Motor Current Range Performance",
    rating: "Critical",
    last_reported: "Jun 10, 2022 4:50 PM"
  },
  {
    device_id: "34323454",
    vin: "JTFSX22P70665474",
    reg_number: "DS83BMGP",
    make: "TOYOTA",
    model: "QP",
    dtc_code: "P0016",
    issues: "Throttle Actuator Control Motor Current Range Performance",
    rating: "Critical",
    last_reported: "Jun 10, 2022 4:50 PM"
  },
  {
    device_id: "67756766",
    vin: "JTFSX22P70665474",
    reg_number: "DS83BMGP",
    make: "TOYOTA",
    model: "QP",
    dtc_code: "P0016",
    issues: "Throttle Actuator Control Motor Current Range Performance",
    rating: "Critical",
    last_reported: "Jun 10, 2022 4:50 PM"
  },
  {
    device_id: "34345462",
    vin: "JTFSX22P70665474",
    reg_number: "DS83BMGP",
    make: "TOYOTA",
    model: "QP",
    dtc_code: "P0016",
    issues: "Throttle Actuator Control Motor Current Range Performance",
    rating: "Critical",
    last_reported: "Jun 10, 2022 4:50 PM"
  },
  {
    device_id: "897866776",
    vin: "JTFSX22P70665474",
    reg_number: "DS83BMGP",
    make: "TOYOTA",
    model: "QP",
    dtc_code: "P0016",
    issues: "Throttle Actuator Control Motor Current Range Performance",
    rating: "Critical",
    last_reported: "Jun 10, 2022 4:50 PM"
  },
  {
    device_id: "00001",
    vin: "JTFSX22P70665474",
    reg_number: "DS83BMGP",
    make: "TOYOTA",
    model: "QP",
    dtc_code: "P0016",
    issues: "Throttle Actuator Control Motor Current Range Performance",
    rating: "Critical",
    last_reported: "Jun 10, 2022 4:50 PM"
  },
  {
    device_id: "00001",
    vin: "JTFSX22P70665474",
    reg_number: "DS83BMGP",
    make: "TOYOTA",
    model: "QP",
    dtc_code: "P0016",
    issues: "Throttle Actuator Control Motor Current Range Performance",
    rating: "Critical",
    last_reported: "Jun 10, 2022 4:50 PM"
  },
  {
    device_id: "00001",
    vin: "JTFSX22P70665474",
    reg_number: "DS83BMGP",
    make: "TOYOTA",
    model: "QP",
    dtc_code: "P0016",
    issues: "Throttle Actuator Control Motor Current Range Performance",
    rating: "Critical",
    last_reported: "Jun 10, 2022 4:50 PM"
  },
  {
    device_id: "00001",
    vin: "JTFSX22P70665474",
    reg_number: "DS83BMGP",
    make: "TOYOTA",
    model: "QP",
    dtc_code: "P0016",
    issues: "Throttle Actuator Control Motor Current Range Performance",
    rating: "Critical",
    last_reported: "Jun 10, 2022 4:50 PM"
  },
  {
    device_id: "00001",
    vin: "JTFSX22P70665474",
    reg_number: "DS83BMGP",
    make: "TOYOTA",
    model: "QP",
    dtc_code: "P0016",
    issues: "Throttle Actuator Control Motor Current Range Performance",
    rating: "Critical",
    last_reported: "Jun 10, 2022 4:50 PM"
  },
  {
    device_id: "00001",
    vin: "JTFSX22P70665474",
    reg_number: "DS83BMGP",
    make: "TOYOTA",
    model: "QP",
    dtc_code: "P0016",
    issues: "Throttle Actuator Control Motor Current Range Performance",
    rating: "Critical",
    last_reported: "Jun 10, 2022 4:50 PM"
  },
  {
    device_id: "00001",
    vin: "JTFSX22P70665474",
    reg_number: "DS83BMGP",
    make: "TOYOTA",
    model: "QP",
    dtc_code: "P0016",
    issues: "Throttle Actuator Control Motor Current Range Performance",
    rating: "Critical",
    last_reported: "Jun 10, 2022 4:50 PM"
  },
  {
    device_id: "00001",
    vin: "JTFSX22P70665474",
    reg_number: "DS83BMGP",
    make: "TOYOTA",
    model: "QP",
    dtc_code: "P0016",
    issues: "Throttle Actuator Control Motor Current Range Performance",
    rating: "Critical",
    last_reported: "Jun 10, 2022 4:50 PM"
  },
  {
    device_id: "00001",
    vin: "JTFSX22P70665474",
    reg_number: "DS83BMGP",
    make: "TOYOTA",
    model: "QP",
    dtc_code: "P0016",
    issues: "Throttle Actuator Control Motor Current Range Performance",
    rating: "Critical",
    last_reported: "Jun 10, 2022 4:50 PM"
  },
  {
    device_id: "00001",
    vin: "JTFSX22P70665474",
    reg_number: "DS83BMGP",
    make: "HONDA",
    model: "QP",
    dtc_code: "P0016",
    issues: "Throttle Actuator Control Motor Current Range Performance",
    rating: "Critical",
    last_reported: "Jun 10, 2022 4:50 PM"
  },
  {
    device_id: "00001",
    vin: "JTFSX22P70665474",
    reg_number: "DS83BMGP",
    make: "TOYOTA",
    model: "QP",
    dtc_code: "P0016",
    issues: "Throttle Actuator Control Motor Current Range Performance",
    rating: "Critical",
    last_reported: "Jun 10, 2022 4:50 PM"
  },
  {
    device_id: "00001",
    vin: "JTFSX22P70665474",
    reg_number: "DS83BMGP",
    make: "TOYOTA",
    model: "QP",
    dtc_code: "P0016",
    issues: "Throttle Actuator Control Motor Current Range Performance",
    rating: "Critical",
    last_reported: "Jun 10, 2022 4:50 PM"
  },
  {
    device_id: "00001",
    vin: "JTFSX22P70665474",
    reg_number: "DS83BMGP",
    make: "HONDA",
    model: "QP",
    dtc_code: "P0016",
    issues: "Throttle Actuator Control Motor Current Range Performance",
    rating: "Critical",
    last_reported: "Jun 10, 2022 4:50 PM"
  },
  {
    device_id: "00001",
    vin: "JTFSX22P70665474",
    reg_number: "DS83BMGP",
    make: "HONDA",
    model: "QP",
    dtc_code: "P0016",
    issues: "Throttle Actuator Control Motor Current Range Performance",
    rating: "Critical",
    last_reported: "Jun 10, 2022 4:50 PM"
  },
  {
    device_id: "00001",
    vin: "JTFSX22P70665474",
    reg_number: "DS83BMGP",
    make: "TOYOTA",
    model: "QP",
    dtc_code: "P0016",
    issues: "Throttle Actuator Control Motor Current Range Performance",
    rating: "Critical",
    last_reported: "Jun 10, 2022 4:50 PM"
  }
];

export default function VehicleHealth_Issues() {
  const [displayData] = useState(data);
  const [isFilter, setIsFilter] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const { itemsPerPage, numberOfPages, totalNumberOfItems, handleItemsPerPageChange, handlePageClick } = usePageFilter({ displayData });

  return (
    <Layout>
      <header className="header-justify-between">
        <div className="px-5 py-4 ">
          <div className="inline-flex items-center gap-2 text-primary">
            <Link to="/">
              <p className="inline-flex items-center font-semibold group">
                <LeftArrowIcon classes="h-4 w-4 group-hover:-translate-x-2 fill-current" />
                Dashboard /
              </p>
            </Link>
            <h2 className="inline-flex gap-2 font-normal">List of Active Dtcs</h2>
          </div>
        </div>
        <FilterButton isFilter={isFilter} setIsFilter={setIsFilter}/>
      </header>

      <section className="flex flex-col gap-8 ">
        <PageFilter itemsPerPage={itemsPerPage} setItemsPerPage={handleItemsPerPageChange} recordsName={"Vehicles"} totalNumberOfItems={totalNumberOfItems} />

        <div className="p-3 overflow-x-auto section-container">
          <table className="detail-table">
            {/* Table header */}
            <thead>
              <tr>
                <th className="p-2 font-semibold text-left "></th>
                <th className="p-2 font-semibold text-left ">Device ID</th>
                <th className="p-2 font-semibold text-left ">VIN</th>
                <th className="p-2 font-semibold text-left ">Registration Number</th>
                <th className="p-2 font-semibold text-left ">Make</th>
                <th className="p-2 font-semibold text-left ">Model</th>
                <th className="p-2 font-semibold text-left ">DTC Code</th>
                <th className="p-2 font-semibold text-left ">Issues</th>
                <th className="p-2 font-semibold text-left ">Rating</th>
                <th className="p-2 font-semibold text-left ">Last Reported</th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody>
              {/* Row */}
              {displayData?.map((record, index) => (
                <tr key={index} className="hover:bg-brand text-primary hover:text-other">
                  <td className="p-2 ">
                    <RightFilledIcon classes={"fill-current"} />
                  </td>
                  <td className="p-2">{record?.device_id}</td>
                  <td className="p-2">{record?.vin}</td>
                  <td className="p-2">{record?.reg_number}</td>
                  <td className="p-2">{record?.make}</td>
                  <td className="p-2">{record?.model}</td>
                  <td className="p-2">{record?.dtc_code}</td>
                  <td className="p-2">{record?.issues}</td>
                  <td className="p-2">{record?.rating}</td>
                  <td className="p-2">{record?.last_reported.dateTime}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Pagination handlePageClick={handlePageClick} numberOfPages={numberOfPages} />
      </section>
    </Layout>
  );
}
