import vehicles from "./rtk/vehiclesApi";
import * as vehicleServices from "./rtk/vehicleServicesApi";
import * as vehicleMaintenanceProviders from "./rtk/vehicleMaintenanceProvidersApi";
import * as vehicleFuel from "./rtk/vehicleFuelApi";
import * as drivers from "./rtk/driversApi";
import * as devices from "./rtk/devicesApi";
import * as fuelAnalytics from "./rtk/fuelAnalyticsApi";
import * as simCards from "./rtk/simCardsApi";
import * as simControl from "./rtk/simControlApi";
import * as sms from "./rtk/smsApi";
import * as hubspotTickets from "./rtk/hubspotTicketsApi";
import * as authenticate from "./rtk/baseApi";
import * as users from "./rtk/usersApi";
import * as userRoles from "./rtk/userRolesApi";
import * as userRegistrations from "./rtk/userRegistrationsApi";


const Api = {
   authenticate,
   devices,
   drivers,
   fuelAnalytics,
   hubspotTickets,
   simCards,
   simControl,
   sms,
   users, 
   userRoles,
   userRegistrations,
   vehicleFuel,
   vehicles,
   vehicleServices,
   vehicleMaintenanceProviders
}

export default Api