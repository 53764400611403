import React from "react";
import { serviceBackgroundStageColor } from "../../core/utilities";
import ServiceCardLineDisplay, {ServiceCardConditionalLineDisplay} from "../service/ServiceCardLineDisplay";

const TicketOverviewCard = ({ name, status, makeModel, vehicleReg, serviceCenter, serviceDate }) => {
  return (
    <div className="service-card section-container">
      <span className={`alert-status ${serviceBackgroundStageColor(status)}`}>{status}</span>

      <h3 className="text-xl font-semibold">{name}</h3>
      <div className="flex flex-col gap-2 text-sm">
        <ServiceCardConditionalLineDisplay heading={"Make & Model"} details={makeModel}  />
        <ServiceCardLineDisplay heading={"Vehicle Reg"} details={vehicleReg} />
        
        <ServiceCardLineDisplay heading={"Service Center"} details={serviceCenter} />
        <ServiceCardLineDisplay heading={"Service Date"} details={serviceDate} />

      </div>

      <button className="w-full mt-4 rounded-lg btn-primary">View Details</button>
    </div>
  );
};

export default TicketOverviewCard;
