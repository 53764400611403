import React from "react";
import { NavLink } from "react-router-dom";
import { SidebarMenuIcon } from "./SidebarMenuIcon";

const ExpandedSingleOptionMenu = ({ menuName, menuIcon, isActiveItem, url }) => {
  return (
    <li className={`sidebar-li group ${isActiveItem && "sidebar-active"}`}>
      <NavLink end to={url} className={`sidebar-item group-hover:text-sidebar-other ${isActiveItem && "hover:translate-x-0.5"}`}>
        <div className="flex items-center">
          <SidebarMenuIcon isActive={isActiveItem} menuIcon={menuIcon} />
          <span className="sidebar-link-name">{menuName}</span>
        </div>
      </NavLink>
    </li>
  );
};

export default ExpandedSingleOptionMenu;
