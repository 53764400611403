import React from "react";
import { useLocation } from "react-router-dom";
import ExpandedSingleOptionMenu from "./ExpandedSingleOptionMenu";
import MinimizedSingleOptionMenu from "./MinimizedSingleOptionMenu";

function SidebarSingleOptionMenu({ rootPath, menuName, menuIcon, url, sidebarExpanded }) {
  const location = useLocation();

  const { pathname: pathName } = location;
  const isActive = (pathname) => {
    return pathName === rootPath || pathName.includes(rootPath);
  };

  return (
    <div>
      {sidebarExpanded ? (
        <ExpandedSingleOptionMenu url={url} menuName={menuName} menuIcon={menuIcon} isActiveItem={isActive(pathName)} />
      ) : (
        <MinimizedSingleOptionMenu url={url} menuName={menuName} menuIcon={menuIcon} isActiveItem={isActive(pathName)} />
      )}
    </div>
  );
}

export default SidebarSingleOptionMenu;
