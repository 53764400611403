import { serviceBackgroundStageColor } from "../../core/utilities";
import ServiceCardLineDisplay, { ServiceCardConditionalLineDisplay } from "../service/ServiceCardLineDisplay";

const RoadsideAssistanceCard = ({ name, status, vehicleReg, makeModel, description,dateAllocated,  serviceProviderAllocated }) => {
  return (
    <div className="relative overflow-hidden rounded-lg section-container">
      <span className={`alert-status ${serviceBackgroundStageColor(status)}`}>{status}</span>

      <h3 className="text-xl font-semibold">{name}</h3>
      <div className="flex flex-col gap-2 text-sm">
      <ServiceCardConditionalLineDisplay heading={"Make & Model"} details={makeModel}  />
      <ServiceCardLineDisplay heading={"Vehicle Reg"} details={vehicleReg} />
      <ServiceCardLineDisplay heading={"Description:"} details={description}  />
        <div className="flex flex-row gap-4 text-sm">
          <ServiceCardConditionalLineDisplay heading={"Service Provider"} details={serviceProviderAllocated} />
          <ServiceCardConditionalLineDisplay heading={"Date Allocated"} details={dateAllocated} />
        </div>
      </div>

      <button className="w-full mt-4 rounded-lg btn-primary">View Details</button>
    </div>
  );
};

export default RoadsideAssistanceCard;
