import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Layout, PrimaryHeader } from "../components";
import FilterSkeleton from "./FilterSkeleton";

export default function VehiclesSkeleton({ vehicleCount = 15 }) {
  return (
    <Layout isLoading={true} loadingText="Loading Vehicles">
      <PrimaryHeader title="Vehicles" />
      <SkeletonTheme baseColor="var(--color-skeleton)" highlightColor="var(--color-background)">
        <FilterSkeleton />
        <div className="grid grid-cols-2 gap-8 mt-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
          {Array(vehicleCount)
            .fill(0)
            .map((_, i) => {
              return <VehicleSkeleton key={i} />;
            })}
        </div>
      </SkeletonTheme>
    </Layout>
  );
}

function VehicleSkeleton() {
  return (
    <div className="vehicle-card">
      <Skeleton width={310} height={200} />
      <div className="flex flex-col p-4 ">
        <h3>
          <Skeleton width={200} height={30} />
          <span className="mb-4 text-sm">
            <Skeleton width={200} height={15} />
          </span>
        </h3>
        <span className="w-full mt-2">
          <Skeleton width={80} height={30} />
        </span>
      </div>

      <div className="reported-status">
        <div className={"animate-pulse w-5 h-5 rounded-full bg-gray-700"}></div>
      </div>
    </div>
  );
}
