import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Layout } from "../components";

export default function DriverProfileSkeleton({ isLoading }) {
  return (
    <Layout isLoading={isLoading} loadingText="Loading Driver">
      <SkeletonTheme baseColor="var(--color-skeleton)" highlightColor="var(--color-background)">
        <header className="flex flex-row justify-between rounded-sm shadow-lg bg-other col-span-full xl:col-span">
          {/* link to driver listing page and driver name */}
          <div className="flex flex-row items-center gap-2 px-5 py-4">
            {/* link to drivers listing page */}
            <div className="inline-flex items-center group">
              <Skeleton width={180} height={30} />
              <Skeleton width={180} height={30} />
            </div>
            {/* name and status icon */}
            <div className="inline-flex items-center">
              <h2 className="inline-flex gap-2 font-normal">
                <Skeleton width={80} height={30} />{" "}
                <span className="hidden md:block">
                  <Skeleton width={80} height={30} />{" "}
                </span>
              </h2>
              <div className=" inline-flex items-center gap-1 p-1.5 text-sm rounded-l-xl rounded-t-md bg-other text-primary">
                {/* driver does not have a status, only vehicles
              <div className={`w-4 h-4 rounded-full ${driver?.status==="online" ? "bg-green-500" : driver?.status==="pending" ? "bg-yellow-500" : "bg-red-500"}`}></div>
              */}
              </div>
            </div>
          </div>
          {/* download button */}
          <div className="flex items-center">
            {/* TODO Once the driver report has been done, enable the button below */}
            <Skeleton width={80} height={30} />
          </div>
        </header>

        <section className="grid grid-cols-1 mt-8 overflow-hidden gap-y-8 lg:gap-8 lg:grid-cols-3 text-primary">
          <div className="flex flex-col w-full gap-3">
            <div className="flex flex-col gap-2 ">
              <h2>
                <Skeleton width={80} height={30} />{" "}
              </h2>

              <div className="flex items-center gap-4 p-4 shadow bg-other ">
                <Skeleton width={150} height={150} circle={true} />

                <div>
                  <p>
                    <Skeleton width={80} height={30} />
                    <span className="font-normal">
                      <Skeleton width={80} height={30} /> <Skeleton width={80} height={30} />
                    </span>
                  </p>
                  <p>
                    <Skeleton width={80} height={30} />{" "}
                    <span className="font-normal">
                      <Skeleton width={80} height={30} />{" "}
                    </span>
                  </p>
                  <p>
                    <Skeleton width={80} height={30} />{" "}
                    <span className="font-normal">
                      <Skeleton width={80} height={30} />{" "}
                    </span>{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <h2>
                <Skeleton width={80} height={30} />
              </h2>
              <div className="p-4 shadow bg-other ">
                <p>
                  <span className="font-normal">
                    <Skeleton width={80} height={30} />{" "}
                  </span>
                </p>
                <p>
                  <Skeleton width={80} height={30} />{" "}
                  <span className="font-normal">
                    <Skeleton width={80} height={30} />{" "}
                  </span>
                </p>
                <p>
                  <Skeleton width={80} height={30} />{" "}
                  <span className="font-normal">
                    <Skeleton width={80} height={30} />{" "}
                  </span>{" "}
                </p>
              </div>
            </div>
          </div>

          {/* pie chart */}
          <div className="flex flex-col col-span-2 gap-2">
            <h2>
              <Skeleton width={80} height={30} />{" "}
            </h2>
            <div className="grid grid-cols-1 gap-10 p-4 shadow md:gap-4 md:grid-cols-2 2xl:grid-cols-4 xl:grid-cols-3 bg-other">
              <Skeleton width={80} height={30} />
              <Skeleton width={80} height={30} />
              <Skeleton width={80} height={30} />
              <Skeleton width={80} height={30} />
            </div>
          </div>
        </section>
      </SkeletonTheme>
    </Layout>
  );
}
