import React from "react";
import { Link } from "react-router-dom";
import ServiceFilter from "../service/ServiceFilter";
import FilterButton from "../filter/FilterButton";

export default function ServiceHeader({ title, hasAccessToSynchronize, handleTicketsSync, isFilter, setIsFilter, allTickets, updateTickets }) {
  return (
    <header className="flex flex-row justify-between px-5 rounded-sm shadow-lg bg-other col-span-full xl:col-span ">
      {/* link to driver listing page and driver name */}
      <div className="flex flex-row items-center gap-2 py-4 font-semibold text-primary">
        <Link to="/vehicles">{title} </Link>

        <ServiceFilter allTickets={allTickets} setCurrentTickets={updateTickets} />
        <div className="flex flex-row">
          {hasAccessToSynchronize && (
            <div className="flex ml-40">
              <button type="button" onClick={handleTicketsSync} className="inline-flex items-center gap-4 px-3 mx-3 text-sm border rounded-md border-brand text-primary hover:text-brand py-1.5">
                Synchronize Hubspot
              </button>
            </div>
          )}

        <FilterButton isFilter={isFilter} setIsFilter={setIsFilter}/>
        </div>
      </div>
      {/* download button //TODO: Add this as stage 2 : Create the report
            <div className='flex items-center'>
                <button className="inline-flex items-center justify-center w-full gap-1 text-other btn-primary"><DownloadIcon style={'h-4 w-4 fill-current'}/> Download Report (PDF)</button>
            </div>
        */}
    </header>
  );
}
