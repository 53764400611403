import React from "react";

import { AlertIcon } from "../icons/SidebarIcons";
import Timeline from "../Timeline";
import { dateTimeDisplay } from "../../core/utilities";

import DeviceOverViewConnectivityCards from "../device/cards/DeviceOverViewConnectivityCards";

function VehicleSmsTracking({vehicleSmsTracking }) {

    const { smsTrackingInfo, device } = vehicleSmsTracking;

    const vehicleSmsTrackingTimeLine = () => {
        return smsTrackingInfo.map((smsTracking) => {
    
          return {
            date: dateTimeDisplay(smsTracking.eventDate),
            title: `${smsTracking.description}`,
            subTitle: `Initiated at ${dateTimeDisplay(smsTracking.created_at,true)}${smsTracking.isDelivered ? `${smsTracking.isResponseReceived? "," : " and"} delivered at ${dateTimeDisplay(smsTracking.send_sms_delivered_at,true)}` : ""}${smsTracking.isResponseReceived ? ` and received response at ${dateTimeDisplay(smsTracking.completed_at,true)}` : ""}.`,
            
          }
          
      });
  }
  console.log({smsTrackingInfo})
  return (
      <section className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          <DeviceOverViewConnectivityCards device={device} />
          <div className="section-container lg:col-span-3" tabIndex="4">
        <h2 className="font-semibold">Sms Tracking</h2>
        <Timeline items={vehicleSmsTrackingTimeLine()}/>
       
        {smsTrackingInfo.length === 0 ? (
          <p className="inline-flex gap-2">
            {" "}
            <AlertIcon classes={"fill-current"} /> No Sms Tracking Found !
          </p>
        ) : null}
      </div>
          </section>
  )
}

export default VehicleSmsTracking