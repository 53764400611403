import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Layout, PrimaryHeader } from "../components";
import FilterSkeleton from "./FilterSkeleton";

export default function TablesSkeleton({ title, rowCount = 15 }) {
  return (
    <Layout isLoading={true} loadingText={`Loading ${title || "records"}`}>
      <PrimaryHeader title={title} />
      <SkeletonTheme baseColor="var(--color-skeleton)" highlightColor="var(--color-background)">
        <FilterSkeleton />
        <div className="p-3 overflow-x-auto section-container">
          <table className="detail-body">
            {/* Table header */}
            <thead>
              <tr>
                <td>
                  <Skeleton height={30} />
                </td>
              </tr>
            </thead>
            {/* Table body */}
            <tbody>
              {/* Row */}
              {Array(rowCount)
                .fill(0)
                .map((_, i) => {
                  return <RowSkeleton row={i} key={i} />;
                })}
            </tbody>
          </table>
        </div>
      </SkeletonTheme>
    </Layout>
  );
}

function RowSkeleton({ row }) {
  return (
    <tr key={row}>
      <td key={row} className="p-2">
        <Skeleton height={20} key={row} />
      </td>
    </tr>
  );
}
