import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function VehicleOverviewSkeleton() {
  return (
    <section className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
      <SkeletonTheme baseColor="var(--color-skeleton)" highlightColor="var(--color-background)">
        <div className="section-container">
          <h2>
            {" "}
            <Skeleton width={80} height={30} />
          </h2>
          <ul className="ml-8 list-disc">
            <li>
              <Skeleton width={80} height={30} />{" "}
              <span>
                {" "}
                <Skeleton width={80} height={30} />
              </span>
            </li>
            {/* //TODO: Figure out where to get the data for Drive and Gear and Parking Brake
          <li>
            Drive: <span>{vehicle.overview.drive}</span>
          </li>
          <li>
            Gear: <span>{vehicle.overview.gear}</span>
          </li>
              
          <li>
            Parking Brake: <span>{vehicle.overview.parkingBrake}</span>
        </li>
        */}
            <li>
              <Skeleton width={80} height={30} />{" "}
              <span>
                {" "}
                <Skeleton width={80} height={30} />
              </span>
            </li>
          </ul>
        </div>

        <div className="section-container">
          <div>
            <p>
              {" "}
              <Skeleton width={80} height={30} />{" "}
            </p>
            <span>
              {" "}
              <Skeleton width={80} height={30} />
            </span>
          </div>

          {/* //TODO: Figure out where to get the data for Accelarot and  Brake              
        <div>
          <p>Accelerator</p>
          <div className="w-full bg-secondary rounded-full h-2.5">
            <div className="mt-1 bg-brand h-2.5 rounded-full" style={{ width: vehicle.overview.accelerator }}></div>
          </div>
        </div>

        <div>
          <p>Brake</p>
          <div className="w-full bg-secondary rounded-full h-2.5">
            <div className="mt-1 bg-brand h-2.5 rounded-full" style={{ width: vehicle.overview.brake }}></div>
          </div>
        </div>
      */}
        </div>

        <div className="section-container">
          <div className="flex flex-row items-center gap-2">
            <Skeleton width={80} height={30} />
            <p>
              <Skeleton width={80} height={30} />{" "}
              <span>
                {" "}
                <Skeleton width={80} height={30} />
              </span>
            </p>
          </div>
          <div>
            <p>
              <Skeleton width={80} height={30} />{" "}
              <span>
                {" "}
                <Skeleton width={80} height={30} />
              </span>
            </p>
            <Skeleton width={80} height={30} />
          </div>
        </div>

        <div className="section-container lg:col-span-3">
          <h2>
            <Skeleton width={80} height={30} />
          </h2>
          <ul className="ml-8 list-disc">
            {Array(3)
              .fill(0)
              .map((_, i) => (
                <li key={i}>
                  {" "}
                  <Skeleton width={80} height={30} />
                </li>
              ))}
          </ul>

          <p className="inline-flex gap-2">
            {" "}
            <Skeleton width={80} height={30} />
          </p>
        </div>
      </SkeletonTheme>
    </section>
  );
}
