import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Layout } from "../components";
import FilterSkeleton from "./FilterSkeleton";

function GraphsSkeleton({ graphCount = 4, loadingText }) {
  return (
    <Layout isLoading={true} loadingText={loadingText}>
      <SkeletonTheme baseColor="var(--color-skeleton)" highlightColor="var(--color-background)">
        <FilterSkeleton />
        <div className="grid grid-cols-2 gap-8 mt-2 md:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
          {Array(graphCount)
            .fill(0)
            .map((_, i) => {
              return <GraphSkeleton key={i} />;
            })}
        </div>
      </SkeletonTheme>
    </Layout>
  );
}

function GraphSkeleton() {
  return (
    <div className="">
      <Skeleton width={300} height={200} />
    </div>
  );
}

export default GraphsSkeleton;
