import ReactPaginate from "react-paginate";
const Pagination = ({handlePageClick, numberOfPages}) => {

    const container = "flex flex-row items-center gap-4"
    const pageItem = "hover:cursor-pointer text-primary"
    const pageLink = "hover:border-b hover:border-b-primary pagination-bottom-link"
    const activeLink = "border-2 border-brand"
   
    return ( 
        <div className="flex flex-row justify-end">
  {numberOfPages > 1 &&
        <ReactPaginate
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={3}
            pageCount={numberOfPages}           
            previousLabel="< previous"
            previousClassName={pageItem}
            nextLabel="next >"
            nextClassName={pageItem}
            nextLinkClassName={pageLink}
            pageClassName={pageItem}
            pageLinkClassName={pageLink}
            previousLinkClassName={pageLink}
            breakLabel="..."
            breakClassName={pageItem}
            breakLinkClassName={pageLink}
            containerClassName={container}
            activeClassName={activeLink}
            renderOnZeroPageCount={null}
        />}
        
        </div>
    );
}
 
export default Pagination;