import { configureStore,combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer, FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER } from "redux-persist";

import settingsReducer from "../features/settings/settingsSlice";
import authReducer from "../features/auth/authSlice";
import { api } from "../api/rtk/api";
import { baseApi } from "../api/rtk/baseApi";

const persistConfig = {
  key: "root",
  version:1,
  storage: storage
};

const rootReducer = combineReducers({
  settings:  persistReducer({key:"settings",storage},settingsReducer),
  auth: persistReducer(persistConfig,authReducer),
  api: api.reducer,
  authApi: baseApi.reducer
});


export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    }}).concat(api.middleware).concat(baseApi.middleware),
  devTools: process.env.NODE_ENV !== "production"
});

export const persistor = persistStore(store);
