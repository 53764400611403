import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../api";
import { Driver, Layout, NoRecords, OnError, PageFilter, Pagination, PrimaryHeader } from "../../components";
import Filter from "../../components/filter/Filter";
import FilterButton from "../../components/filter/FilterButton";
import SystemFeatures from "../../constant/systemFeatures";
import { isValidArrayWithData } from "../../core/utilities";
import { getFilterOptionsFeature, getIsFilterFeature, getPerPageFeature, setIsFilterFeature, setPerPageFeature } from "../../features/settings/settingsSlice";
import FilterOptions from "../../filter/FilterOptions";
import usePageFilter from "../../hooks/usePageFilter";
import DriversSkeleton from "../../skeletons/DriversSkeleton";

const PROCESS_FEATURE = SystemFeatures.DRIVERS;
export default function Drivers() {
  const { data: drivers = [], isLoading, isError, error } = Api.drivers.useGetAllDriversQuery();

  const driversFound = isValidArrayWithData(drivers);

  return isError ? (
    <OnError error={error} />
  ) : isLoading ? (
    <DriversSkeleton isLoading={isLoading} driverCount={15} />
  ) : driversFound ? (
    <DisplayDrivers drivers={drivers} />
  ) : (
    <NoRecords title="Drivers" message="No Drivers found." />
  );
}

function DisplayDrivers({ drivers, isLoading = false }) {
  const currentItemsPerPage = useSelector(getPerPageFeature(PROCESS_FEATURE));
  const currentIsFilter = useSelector(getIsFilterFeature(PROCESS_FEATURE));
  const defaultFilterOptions = useSelector(getFilterOptionsFeature(PROCESS_FEATURE));
  const [displayData, setDisplayData] = useState(drivers);
  const [isFilter, setIsFilter] = useState(currentIsFilter);
  const [isFiltered, setIsFiltered] = useState(false);
  const { itemsOnCurrentPage, numberOfItemsOnCurrentPage, itemsPerPage, numberOfPages, totalNumberOfItems, handleItemsPerPageChange, handlePageClick } = usePageFilter({
    displayData,
    currentItemsPerPage
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPerPageFeature({ name: PROCESS_FEATURE, perPage: itemsPerPage }));
  }, [dispatch, itemsPerPage]);

  useEffect(() => {
    dispatch(setIsFilterFeature({ name: PROCESS_FEATURE, isFilter }));
  }, [dispatch, isFilter]);

  const handleFilterDataChange = (filteredData) => {
    setIsFiltered(filteredData.length !== drivers.length);
    setDisplayData(filteredData);
  };

  const filterOptions = () => {
    const options = new FilterOptions();
    return options.getDriverOptions();
  };

  return (
    <Layout isLoading={isLoading} loadingText="Loading Drivers">
      <PrimaryHeader title="Drivers">
        <div className="flex">
          <FilterButton isFilter={isFilter} setIsFilter={setIsFilter} />
        </div>
      </PrimaryHeader>

      <Filter enabled={isFilter} data={drivers} filterOptions={filterOptions()} handleFilterDataChange={handleFilterDataChange} feature={PROCESS_FEATURE} defaultFilterOptions={defaultFilterOptions} />

      <section className="detail-section">
        <PageFilter
          itemsPerPage={itemsPerPage}
          setItemsPerPage={handleItemsPerPageChange}
          numberOfItemsOnCurrentPage={numberOfItemsOnCurrentPage}
          isFiltered={isFiltered}
          recordsName={"Drivers"}
          totalNumberOfItems={totalNumberOfItems}
        />

        <div className="grid grid-cols-2 gap-8 mt-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
          {itemsOnCurrentPage?.map((driver, index) => (
            <Driver key={driver?.id} id={driver?.id} driver={driver} />
          ))}
        </div>

        <Pagination handlePageClick={handlePageClick} numberOfPages={numberOfPages} />
      </section>
    </Layout>
  );
}
