import React from "react";
import { AlertIcon, DialogCloseIcon } from "../icons";

function AlertDialog({ onClick, show, alertMessage }) {
  return show ? <ShowAlert onClick={onClick} alertMessage={alertMessage} /> : "";
}

function ShowAlert({ onClick, alertMessage }) {
  return (
    <div className="modal-dialog">
      <div className="flex items-center justify-center h-screen">
        <div id="popup-modal" tabIndex="-1" className="flex-col justify-center p-4 text-primary">
          <div className="w-full h-full max-w-md opacity-100 md:h-auto">
            <div className="relative rounded-lg shadow bg-background ">
              <button
                type="button"
                onClick={onClick}
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-toggle="popup-modal"
              >
                <DialogCloseIcon />
                <span className="sr-only">Close modal</span>
              </button>
              <div className="p-6 text-center">
                <AlertIcon color="var(--color-primary)"/>
                <h3 className="mb-5 text-lg font-normal text-primary">{alertMessage}</h3>

                <div className="flex justify-center gap-8">
                  <button data-modal-toggle="popup-modal" type="button" onClick={onClick} className="btn-primary">
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlertDialog;
