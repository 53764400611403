import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Layout } from "../components";
import LiveMovementSkeleton from "./LiveMovementSkeleton";
import VehicleOverviewSkeleton from "./VehicleOverviewSkeleton";
import WearTearSkeleton from "./WearTearSkeleton";

export default function VehicleDetailsSkeleton({ isLoading }) {
  return (
    <Layout isLoading={isLoading} loadingText="Loading Vehicle">
      <header className="flex flex-row justify-between pr-4 rounded-sm shadow-lg bg-other col-span-full xl:col-span">
        <SkeletonTheme baseColor="var(--color-skeleton)" highlightColor="var(--color-background)">
          <div className="flex flex-row items-center gap-2 px-5 py-4">
            <p className="inline-flex items-center group">
              <Skeleton width={80} height={30} />
            </p>

            <p className="inline-flex items-center group">
              <Skeleton width={80} height={30} />
            </p>
            <h2 className="inline-flex gap-2 font-normal">
              <Skeleton width={80} height={30} />{" "}
              <span className="hidden md:block">
                <Skeleton width={80} height={30} />
              </span>
            </h2>
          </div>
          {/* buttons */}
          <div className="flex flex-col gap-4 md:items-center md:flex-row">
            <div>
              <Skeleton width={80} height={30} />
            </div>

            <div>
              <Skeleton width={80} height={30} />
            </div>
            {/* TODO  Once we have Wear & Tear data enable the button below  */}
            <div>
              <Skeleton width={80} height={30} />
            </div>
          </div>
        </SkeletonTheme>
      </header>

      <VehicleOverviewSkeleton isLoading={isLoading} />
      <LiveMovementSkeleton isLoading={isLoading} />
      <WearTearSkeleton isLoading={isLoading} />
    </Layout>
  );
}
