export default class TeltonikaSmsCommands
{
     smsLogin = "";
    smsPassword = "";
    
    static fullCommand(command) {
        return `${this.smsLogin} ${this.smsPassword} ${command}`;
    }

    static get FormatSdCard() {
        
        return "sdformat";
    }
    static get GetInfo() {
        
        return "getinfo";
    }

    static get GetGoogleGps() {
        
        return "ggps";
    }

    static get GetGps() {
        
        return "getgps";
    }

    static get FotaWebConnect() {
        
        return "web_connect";
    }

    static get SetDeviceOdometer() {
        return "odoset:"
    }

}