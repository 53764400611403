import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Layout, PrimaryHeader } from "../components";
import DownArrowIcon from "../components/icons/DownArrowIcon";
import FilterSkeleton from "./FilterSkeleton";

export default function DriversSkeleton({ driverCount = 15 }) {
  return (
    <Layout isLoading={true} loadingText="Loading Drivers">
      <PrimaryHeader title="Drivers" />
      <SkeletonTheme baseColor="var(--color-skeleton)" highlightColor="var(--color-background)">
        <FilterSkeleton />
        <div className="grid grid-cols-2 gap-8 mt-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
          {Array(driverCount)
            .fill(0)
            .map((_, i) => {
              return <DriverSkeleton key={i} />;
            })}
        </div>
      </SkeletonTheme>
    </Layout>
  );
}

function DriverSkeleton() {
  return (
    <div className="driver-card">
      <Skeleton width={150} height={150} circle={true} />
      <h3>
        <Skeleton width={200} height={30} />
      </h3>
      <p>
        <Skeleton width={100} height={20} />
      </p>
      <DownArrowIcon classes={"fill-primary group-hover:fill-other group-hover:animate-bounce opacity-80"} />
      <div className="reported-status">
        <div className="w-5 h-5 bg-gray-600 rounded-full animate-pulse"></div>
      </div>
    </div>
  );
}
