import React, {useRef,useState} from "react";
import ReactSelect from "react-select";
import makeAnimated from "react-select/animated";
import { pluralOf } from "../core/utilities";
const animatedComponents = makeAnimated();


export default function InputSelect({required=false, value, setSelectedOption, allowMultiples, selectionOptions, className, placeholder, name, itemName, allowSelectAll,isValidNewOptionHandle,allowCreate=false, ref = null}) {
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isByItemSelect, setIsByItemSelect] = useState(false);
  const isDisabled = selectionOptions ? selectionOptions.length === 0 : false;
  const valueRef = useRef(value);


  const selectAllOption = {
    value: "<SELECT_ALL>",
    label: `All ${itemName ? pluralOf(itemName) : "items"}`
  };

  
  const isSelectAllSelected = () => {
    if (!valueRef.current || selectionOptions) {
    return false;
    }
    return valueRef.current.length === selectionOptions.length;
  }

  const closeMenuOnSelect = () => { 
    
    
    if (allowSelectAll && isAllSelected) {

      return true;
    }
    else {
      return allowMultiples ? selectionOptions && selectionOptions.length <= 1 : true;
    }
  }

  const isOptionSelected = option => {
    if (!valueRef.current || option) { 
      return false;
    }
   return valueRef.current.some(({ value }) => value === option.value) ||  isSelectAllSelected();
  }
  
  const getOptions = () => {
    if (allowSelectAll) {

      if (isAllSelected) {
        return [selectAllOption];
      }
      else if (selectionOptions && (selectionOptions.length === 1 || isByItemSelect))  {
        return selectionOptions;
      }
      else {
        return  [selectAllOption, ...selectionOptions]
      }
    }
    else {
      return selectionOptions;
    }
  };

  const getValue = () => {
   return isSelectAllSelected() ? [selectAllOption] : value;
  }

  const onChange = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta;
    const selectedItems = newValue ? newValue.length : 0;

    if (action === "select-option" && option?.value === selectAllOption.value) {
      setIsAllSelected(true);
      setSelectedOption(selectionOptions, actionMeta);
    } else if (
      (action === "deselect-option" &&
        option?.value === selectAllOption.value) ||
      (action === "remove-value" &&
        removedValue.value === selectAllOption.value)
    ) {
      setIsAllSelected(false);
      setIsByItemSelect(false);
      setSelectedOption([], actionMeta);
    } else if (
      actionMeta.action === "deselect-option" &&
      isSelectAllSelected()
    ) {
      setIsByItemSelect(false);
      
      setSelectedOption(
        selectionOptions.filter(({ value }) => value !== option?.value),
        actionMeta
      );
    } else {
      setIsByItemSelect(selectedItems > 0);
      setSelectedOption(newValue || [], actionMeta);
      if (action === "clear") {
        setIsAllSelected(false);
      }
    }
  };

  const customStyles = {
    indicatorsContainer: (provided, state) => ({
      ...provided,
      backgroundColor: "var(--color-secondary)",
      borderColor: "var(--color-secondary)"
      
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "var(--color-primary)"
      
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      color: "var(--color-delete)",
      "&:hover": {
        backgroundColor: "var(--color-delete)",
        color: "var(--color-other)"
      }
      
    }),
    control: (baseStyles, state) => ({
      ...baseStyles,
    cursor: "pointer",
    boxShadow: state.isFocused ? 0 : 0,
    borderWidth: "2px",
    backgroundColor: "var(--color-background)",
    borderColor: state.isFocused
      ? "var(--color-brand)"
      : "var(--color-secondary)",
    "&:hover": {
      borderColor: state.isFocused
        ? "var(--color-brand)"
        : "var(--color-primary)"
    }}),

    input: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "var(--color-brand)" : "var(--color-secondary)",
      borderWidth: 0,
      boxShadow: state.isFocused ? "0px 20px 5px rgba(0,0,0,0.7)" : "0px 0px 0px rgba(0,0,0,0)",
      color: "var(--color-primary)"
      
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: "var(--color-brand)",
      color: "var(--color-other)"
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: "var(--color-other)"
    }),
    menuList: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0
   }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "var(--color-brand)" : "var(--color-background)",
      color: "var(--color-primary)",
      "&:hover": {
        backgroundColor: state.isFocused ? "var(--color-brand)" : "var(--color-background)",
        color: "var(--color-other)"
      }
    })
  }

  return (
    <ReactSelect
      ref={ref}
      required={required}
      styles={customStyles}
      placeholder={placeholder}
      isSearchable={true}
      defaultValue={value}
      value={getValue()}
      onChange={onChange}
      isMulti={allowMultiples}
      options={getOptions()}
      components={animatedComponents}
      className={className}
      isDisabled={isDisabled}
      closeMenuOnSelect={closeMenuOnSelect()}
      name={name}
      isOptionSelected={isOptionSelected}
      isCreatable={allowCreate}
      isValidNewOption={isValidNewOptionHandle}
      
      />
  )
}
