import { api } from "./api";

const fuelAnalyticsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCustomerMonthlyFuelAnalytics: builder.query({
      query: () => "/fuelAnalytics/customerMonthly"
    }),
    getCustomerYearlyFuelAnalytics: builder.query({
        query: () => "/fuelAnalytics/customerYearly"
    }),
    getCustomerLastTwelveMonthsFuelAnalytics: builder.query({
      query: () => "/fuelAnalytics/customerLastTwelveMonths"
    }),    
    getAllVehiclesLifetimeFuelAnalytics: builder.query({
      query: () => "/fuelAnalytics/lifetime"
    })
  })
});

export const { useGetCustomerMonthlyFuelAnalyticsQuery,
  useGetCustomerYearlyFuelAnalyticsQuery,
    useGetCustomerLastTwelveMonthsFuelAnalyticsQuery,
  useGetAllVehiclesLifetimeFuelAnalyticsQuery } = fuelAnalyticsApi;

export default fuelAnalyticsApi;