import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function WearTearSkeleton() {
  return (
    <>
      <section className="section-container">
        <SkeletonTheme baseColor="#9297A1" highlightColor="#9297A1">
          <h2>
            <Skeleton width={80} height={30} />{" "}
            <span>
              {" "}
              <Skeleton width={80} height={30} />
            </span>
          </h2>

          <div className="grid grid-cols-4 mt-4">
            <div>
              <h3>
                {" "}
                <Skeleton width={80} height={30} />
              </h3>
              <div className="flex flex-col gap-1">
                <ul className="ml-8 list-disc">
                  <li>
                    <Skeleton width={80} height={30} />{" "}
                    <span>
                      {" "}
                      <Skeleton width={80} height={30} />
                    </span>
                  </li>
                  <li>
                    <Skeleton width={80} height={30} />{" "}
                    <span>
                      {" "}
                      <Skeleton width={80} height={30} />
                    </span>
                  </li>
                  <li>
                    <Skeleton width={80} height={30} />{" "}
                    <span>
                      {" "}
                      <Skeleton width={80} height={30} />
                    </span>
                  </li>
                  <li>
                    <Skeleton width={80} height={30} />{" "}
                    <span>
                      {" "}
                      <Skeleton width={80} height={30} />
                    </span>
                  </li>
                  <li>
                    <Skeleton width={80} height={30} />{" "}
                    <span>
                      {" "}
                      <Skeleton width={80} height={30} />
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div>
              <h3>
                {" "}
                <Skeleton width={80} height={30} />
              </h3>
              <div className="flex flex-col gap-1">
                <ul className="ml-8 list-disc">
                  <li>
                    <Skeleton width={80} height={30} />
                    <span>
                      {" "}
                      <Skeleton width={80} height={30} />
                    </span>
                  </li>
                  <li>
                    <Skeleton width={80} height={30} />{" "}
                    <span>
                      {" "}
                      <Skeleton width={80} height={30} />
                    </span>
                  </li>
                  <li>
                    <Skeleton width={80} height={30} />{" "}
                    <span>
                      {" "}
                      <Skeleton width={80} height={30} />
                    </span>
                  </li>
                  <li>
                    <Skeleton width={80} height={30} />{" "}
                    <span>
                      {" "}
                      <Skeleton width={80} height={30} />
                    </span>
                  </li>
                  <li>
                    <Skeleton width={80} height={30} />{" "}
                    <span>
                      {" "}
                      <Skeleton width={80} height={30} />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </SkeletonTheme>
      </section>

      <section className="flex flex-col gap-2 mt-4">
        <SkeletonTheme baseColor="var(--color-skeleton)" highlightColor="var(--color-background)">
          <h2>
            {" "}
            <Skeleton width={80} height={30} />
          </h2>
          <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
            <Skeleton width={80} height={30} />
            <Skeleton width={80} height={30} />
          </div>
        </SkeletonTheme>
      </section>
    </>
  );
}
