import { HomeIcon } from "./icons";
import ConditionalNavLink from "./ConditionalNavLink";

function Breadcrumb({ items }) {
  return (
    
<nav className="flex pl-5" aria-label="Breadcrumb">
    <ol className="inline-flex items-center space-x-1 md:space-x-3">
        {items.map((item, i) => (<DisplayBreadcrumbItem item={item} isFirstItem={i === 0} key={i} />))}
  </ol>
</nav>

  )
}

function DisplayBreadcrumbItem({item,isFirstItem}) {
   
    return  <li className="inline-flex items-center">
        <ConditionalNavLink path={item.path} classes="inline-flex items-center text-sm font-medium text-primary hover:text-brand">
            {isFirstItem ? <HomeIcon classes={"w-3 h-3 mr-2.5"} /> : 
                    <svg className="w-3 h-3 mx-1 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                  </svg>}
      {item.name}
    </ConditionalNavLink>
  </li>
}
export default Breadcrumb