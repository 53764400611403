import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Api from "../../api";
import { Layout, LeftArrowIcon, NoRecords, OnError, PageFilter, Pagination } from "../../components";
import Filter from "../../components/filter/Filter";
import { Table, TableColumnOptions } from "../../components/table";
import SystemFeatures from "../../constant/systemFeatures";
import { isValidArrayWithData } from "../../core/utilities";
import { getFilterOptionsFeature, getIsFilterFeature, getPerPageFeature, setIsFilterFeature, setPerPageFeature } from "../../features/settings/settingsSlice";
import FilterOptions from "../../filter/FilterOptions";
import usePageFilter from "../../hooks/usePageFilter";
import FilterButton from "../../components/filter/FilterButton";
import Constant from "../../constant";


const liveFeedMaxDaysToInclude = Constant.System.Dashboard.Realtime.MaximumDaysToInclude;

export default function DashboardLiveDataFeed() {
  const { data: vehiclesDashboardLiveDataFeed=[], isLoading, isError, error } = Api.vehicles.useGetOnlineOnlyVehiclesRealtimeQuery(liveFeedMaxDaysToInclude);

  const vehiclesDashboardLiveDataFeedFound = isValidArrayWithData(vehiclesDashboardLiveDataFeed.vehicles) ;



//   console.log("Debug:",{liveFeedMaxDaysToInclude,vehiclesDashboardLiveDataFeed,isLoading, isError, error});
  return isError ? (
    <OnError error={error} />
  ) : isLoading ? (
    <DisplayDashboardLiveDataFeed liveDataFeed={[]} isLoading={isLoading}  />
  ) : vehiclesDashboardLiveDataFeedFound ? (
    <DisplayDashboardLiveDataFeed liveDataFeed={vehiclesDashboardLiveDataFeed.vehicles}/>
  ) : (
    <NoRecords title="Dashboard Live Data Feed" message="No Vehicles Dashboard Live Feed found." />
  );
}

const PROCESS_FEATURE = SystemFeatures.DASHBOARD_LIVE_DATA_FEED;

function DisplayDashboardLiveDataFeed({ liveDataFeed, isLoading=false }) {
  const currentItemsPerPage = useSelector(getPerPageFeature(PROCESS_FEATURE));
  const currentIsFilter = useSelector(getIsFilterFeature(PROCESS_FEATURE));
  const defaultFilterOptions = useSelector(getFilterOptionsFeature(PROCESS_FEATURE));
  const [displayData, setDisplayData] = useState(liveDataFeed);
  const [isFilter, setIsFilter] = useState(currentIsFilter);
  const [isFiltered, setIsFiltered] = useState(false);

  const { numberOfItemsOnCurrentPage, itemsPerPage, currentPage, numberOfPages, totalNumberOfItems, handleItemsPerPageChange, handlePageClick } = usePageFilter({
    displayData,
    currentItemsPerPage
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setDisplayData(liveDataFeed);
  }, [liveDataFeed]);

  useEffect(() => {
    dispatch(setPerPageFeature({ name: PROCESS_FEATURE, perPage: itemsPerPage }));
  }, [dispatch, itemsPerPage]);

  useEffect(() => {
    dispatch(setIsFilterFeature({ name: PROCESS_FEATURE, isFilter }));
  }, [dispatch, isFilter]);

  const handleFilterDataChange = (filteredData) => {
    setIsFiltered(filteredData.length !== liveDataFeed.length);
    setDisplayData(filteredData);
  };

  const filterOptions = () => {
    const options = new FilterOptions();
    return options.getVehicleOptions();
  };

  // console.log("Debug:", { data: liveDataFeed, displayData });

  return (
    <Layout isLoading={isLoading} loadingText="Loading Vehicles Dashboard Live Data Feed">
      <header className="header-justify-between">
        <div className="px-5 py-4 ">
          <div className="inline-flex items-center gap-2 text-primary">
            <Link to="/">
              <p className="inline-flex items-center font-semibold group">
                <LeftArrowIcon classes="h-4 w-4 group-hover:-translate-x-2 fill-current" />
                Dashboard /
              </p>
            </Link>
            <h2 className="inline-flex gap-2 font-normal">List of Live Vehicles</h2>
          </div>
        </div>
        <FilterButton isFilter={isFilter} setIsFilter={setIsFilter}/>
      </header>
      <Filter
        enabled={isFilter}
        data={liveDataFeed}
        filterOptions={filterOptions()}
        handleFilterDataChange={handleFilterDataChange}
        feature={PROCESS_FEATURE}
        defaultFilterOptions={defaultFilterOptions}
      />

      <section className="flex flex-col gap-8 ">
        <PageFilter
          itemsPerPage={itemsPerPage}
          setItemsPerPage={handleItemsPerPageChange}
          numberOfItemsOnCurrentPage={numberOfItemsOnCurrentPage}
          recordsName={"Vehicles"}
          isFiltered={isFiltered}
          totalNumberOfItems={totalNumberOfItems}
        />

        <Table data={displayData} pagingInfo={{ dataPerPage: itemsPerPage, currentPage }}
          tableColumns={TableColumnOptions.dashboardLiveDataFeed}
          initialSort={{ key: "last_reported_at", order: "desc" }} />
        <Pagination handlePageClick={handlePageClick} numberOfPages={numberOfPages} />
      </section>
    </Layout>
  );
}
