export const TableDataTypes = Object.freeze({
  Boolean: "boolean",
  EditableBoolean: "editableBoolean",
  Button: "button",
  DateTime: "dateTime",
  Date: "date",
  Hundredth: "hundredth",
  ImageVideoModal: "image.video.modal",
  Percentage: "percentage",
  VehicleRegistration: "vehicleRegistration"
});

export default TableDataTypes;
