import { api } from "./api";

export const smsApi = api.injectEndpoints({
  endpoints: (builder) => ({
  
    sendSingleSms: builder.mutation({
        query: (smsInfo) => ({
          url: "/sms/singleRecipientSend",
          method: "POST",
          body: { ...smsInfo }
        })
    }),
    sendMultipleSms: builder.mutation({
      query: (smsInfo) => ({
        url: "/sms/multipleRecipientsSend",
        method: "POST",
        body: { ...smsInfo }
      })
  })
})
});

export const { useSendSingleSmsMutation, useSendMultipleSmsMutation } = smsApi;