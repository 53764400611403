import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Layout } from "../components";
import FilterSkeleton from "./FilterSkeleton";

function ApprovedHelpSkeleton({ recordsCount = 12, loadingText }) {
  return (
    <Layout isLoading={true} loadingText={loadingText}>
      <SkeletonTheme baseColor="var(--color-skeleton)" highlightColor="var(--color-background)">
        <FilterSkeleton />
        <div className="grid grid-cols-3 gap-8 mt-2 md:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3">
          {Array(recordsCount)
            .fill(0)
            .map((_, i) => {
              return <ApproveHelpSkeleton key={i} />;
            })}
        </div>
      </SkeletonTheme>
    </Layout>
  );
}

function ApproveHelpSkeleton() {
  return (
    <div className="card">
      <h3>
        <Skeleton width={200} height={30} />
      </h3>
      <p>
        <Skeleton width={150} height={20} />
      </p>
      <p>
        <Skeleton width={150} height={20} />
      </p>
      <SkeletonTheme baseColor="var(--color-brand)" highlightColor="var(--color-background)">
        <h3>
          <Skeleton width={200} height={30} />
        </h3>
      </SkeletonTheme>
    </div>
  );
}

export default ApprovedHelpSkeleton;
