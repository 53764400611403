import React, { useState } from "react";

import TicketOverviewCard from "../../components/cards/TicketOverviewCard";
//import DownloadIcon from "../../components/icons/DownloadIcon";
import Api from "../../api";
import { useSynchronizeHubSpotTicketsMutation } from "../../api/rtk/hubspotTicketsApi";
import { AlertDialog, Filter, Layout, NoRecords, OnError, ServiceHeader } from "../../components";
import { UserRoleAccess } from "../../constant";
import { isValidArrayWithData } from "../../core/utilities";
import FilterOptions from "../../filter/FilterOptions";
import useHasAccessToFeature from "../../hooks/useHasAccessToFeature";
import ServicesSkeleton from "../../skeletons/ServicesSkeleton";

const TicketOverview = () => {
  const { data: hubspotTickets = [], isLoading, isError, error } = Api.hubspotTickets.useGetAllHubspotTicketsQuery();
  const [synchronizeTicketsData] = useSynchronizeHubSpotTicketsMutation();
  const { hasAccess: hasAccessToSynchronize } = useHasAccessToFeature(UserRoleAccess.serviceHubspotSynchronize);
  const hubspotTicketsFound = isValidArrayWithData(hubspotTickets);

   console.log(hubspotTickets,hasAccessToSynchronize );

  return isError ? (
    <OnError error={error} />
  ) : isLoading ? (
    <ServicesSkeleton isLoading={isLoading} title="Vehicle Service Tickets" />
  ) : hubspotTicketsFound ? (
    <DisplayTickets allTickets={hubspotTickets} hasAccessToSynchronize={hasAccessToSynchronize} synchronizeTicketsData={synchronizeTicketsData} />
  ) : (
    <NoRecords title="Vehicle Service Tickets" message="No Vehicle Service Tickets found." />
  );
};

function DisplayTickets({ allTickets, hasAccessToSynchronize, synchronizeTicketsData }) {
  const [isFilter, setIsFilter] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [tickets, setTickets] = useState(allTickets);
  const [displayData, setDisplayData] = useState(tickets);
  const [hubspotSynchAlert, setHubspotSynchAlert] = useState({ show: false });

  const updateTickets = (tickets) => {
    setTickets(tickets);
    setDisplayData(tickets);
  };


  
  const [loading, setLoading] = useState(false);
  const loadText = 'Synchronizing Hubspots...';

  

  const handleTicketsSync = async () => {
    setLoading(true);
    try {
    const synchResponse = await synchronizeTicketsData().unwrap();
    if (synchResponse.success) {
      setHubspotSynchAlert({ show: true, message: "Hubspot and Dashboard have been synchronized." });
    } else {
      setHubspotSynchAlert({ show: true, message: "Hubspot and Dashboard could not be synchronized." });
    }
  } catch (error) {
    console.error("Error synchronizing tickets:", error); 
    setHubspotSynchAlert({ show: true, message: "An error occurred while synchronizing Hubspot and Dashboard." });
  } finally {
    setLoading(false);
  }
  };
  
  const handleFilterDataChange = (filteredData) => {
    setIsFiltered(filteredData.length !== tickets.length);
    setDisplayData(filteredData);
  };
  
  const filterOptions = () => {
    const options = new FilterOptions();
    return options.getServicesTicketOverview();
  };
  
  console.log({ displayData, tickets, allTickets });
  
  return (
    <Layout isForegroundProcess={loading} loadingText={loadText} >
      <ServiceHeader
        title="Services"
        hasAccessToSynchronize={hasAccessToSynchronize}
        handleTicketsSync={handleTicketsSync}
        isFilter={isFilter}
        setIsFilter={setIsFilter}
        allTickets={allTickets}
        updateTickets={updateTickets}
      />
      <AlertDialog show={hubspotSynchAlert.show} alertMessage={hubspotSynchAlert.message} onClick={() => setHubspotSynchAlert({ show: false })} />
      <Filter enabled={isFilter} data={tickets} filterOptions={filterOptions()} handleFilterDataChange={handleFilterDataChange} />
      <section className="grid grid-cols-1 gap-8 mt-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
        {displayData?.map((ticket, index) => (
          <TicketOverviewCard
            key={ticket.id}
            name={ticket.hs_subject}
            makeModel={ticket.makeAndModel}
            status={ticket.status}
            vehicleReg={ticket.vehicle_registration}
            serviceCenter={ticket.serviceCenter}
            serviceDate={ticket.serviceDate}
            owner={ticket.owner}
          />
        ))}
      </section>
    </Layout>
  );
}

export default TicketOverview;