import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { setSystemSignOut } from "../features/auth/authSlice";


function getErrorStatus(error) {
  if (error.originalStatus) {
    return error.originalStatus;
  }

  return error.status;
}

function getErrorDescription(error) {
  
  if (error.data?.error) {
    return error.data.error;
  }
  else if (error.error) {
    return error.error;
  }
  return error.data;
}

function isTokenExpiredError(error) { 
  
  if (!error?.data || error.status !== 403) {
    return false;
  }
  else
  {
    const { errorMessage } = error.data;
    return errorMessage.toLowerCase() === "Token expired".toLowerCase();
   }
  
}


function OnError({ error }) {
  const location = useLocation();
  const status = getErrorStatus(error);
  const errorDescription = getErrorDescription(error);
  const tokenExpired = isTokenExpiredError(error);

  const dispatch = useDispatch();

  // console.log({ tokenExpired, error, status, errorDescription });
  
  if (tokenExpired) { 
    dispatch(setSystemSignOut({status})); 
  }
  
  return (tokenExpired ?  <Navigate to="/logout" state={{ from: location }} replace /> :
  <UserNavigation status={status} errorDescription={errorDescription} location={location} />);
}

function UserNavigation({status, errorDescription, location}) {
  const user = useSelector((state) => state.auth.user);
  const userRoleOrAccessIssue = status === 403 || !user?.role?.id || !user?.role?.name;
  const isSignedIn = user?.token && user?.isSignedIn;
  return (userRoleOrAccessIssue ? 
    <Navigate to="/login" state={{ from: location }} replace />
   : isSignedIn ? <DisplayError status={status} errorDescription={errorDescription} /> : 
    <Navigate to="/login" state={{ from: location }} replace />
  );
}

function DisplayError({status,errorDescription }) {
  

  return (
    <section className="flex items-center h-screen px-16 overflow-hidden bg-primary">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className="max-w-md text-center">
          <h2 className="mb-8 text-4xl font-extrabold text-background">
            <span className="sr-only">Error</span>
            {status || 500}
          </h2>
          <p className="text-2xl font-semibold md:text-3xl text-background">Sorry, we ran into a problem</p>
          <p className="mt-4 mb-10 text-background md:text-2xl">Details: {errorDescription || "Check your Internet Connection"}</p>
          <a rel="noopener noreferrer" href="/" className="px-8 py-3 font-semibold underline rounded text-background hover:shadow-md">
            Back to homepage
          </a>
        </div>
      </div>
    </section>
  )

}

export default OnError;
