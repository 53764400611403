import React from "react";
import { PhoneIcon } from "../components/icons";

import { Layout, PrimaryHeader, NoRecords, OnError } from "../components";
import { isValidArrayWithData } from "../core/utilities";
import Api from "../api";

export default function Scheduling() {
  /* Sample Code Data
  const data = [
    {
      vehicle: "00001",
      scheduled: "oil service",
      date: "January 20, 2022",
      provider: "Bush Auto service",
      contact: "0123456789"
    },
    {
      vehicle: "00001",
      scheduled: "oil service",
      date: "January 20, 2022",
      provider: "Bush Auto service",
      contact: "0123456789"
    },
    {
      vehicle: "00001",
      scheduled: "oil service",
      date: "January 20, 2022",
      provider: "Bush Auto service",
      contact: "0123456789"
    },
    {
      vehicle: "00001",
      scheduled: "oil service",
      date: "January 20, 2022",
      provider: "Bush Auto service",
      contact: "0123456789"
    },
    {
      vehicle: "00001",
      scheduled: "oil service",
      date: "January 20, 2022",
      provider: "Bush Auto service",
      contact: "0123456789"
    },
    {
      vehicle: "00001",
      scheduled: "oil service",
      date: "January 20, 2022",
      provider: "Bush Auto service",
      contact: "0123456789"
    },
    {
      vehicle: "00001",
      scheduled: "oil service",
      date: "January 20, 2022",
      provider: "Bush Auto service",
      contact: "0123456789"
    }
  ];
  */
  
  const { data: vehicleServices=[], isLoading, isError, error } = Api.vehicleServices.useGetAllUpcomingVehicleServicesQuery();
  const vehicleServicesFound = isValidArrayWithData(vehicleServices);

  console.log({ vehicleServicesFound, vehicleServices, isLoading });

  return isError ? <OnError error={error} /> :
    isLoading ? (
      <DisplayServiceSchedules vehicleServices={[]} isLoading={isLoading}  />
  ) : vehicleServicesFound ? (
    <DisplayServiceSchedules vehicleServices={vehicleServices} />
  ) : (
    <NoRecords title="Schedules" message="No Schedules found." />
  );
}

function DisplayServiceSchedules({ vehicleServices, isLoading=false }) {
  const table_header = ["vehicle", "Scheduled For", "Date Scheduled", "Service Provider", "Contact"];
  return (
    <Layout isLoading={isLoading} loadingText="Loading Service Schedules">
      <div className="rounded-sm shadow-lg bg-other col-span-full xl:col-span-8">
        <PrimaryHeader title="Schedules"  />
      </div>

      <div className="section-container">
        <div className="p-3">
          {/* Table */}
          <div className="overflow-x-auto">
            <table className="detail-table">
              {/* Table header */}
              <thead>
                <tr>
                  {table_header?.map((heading) => (
                    <th key={heading} className="p-2 font-semibold text-left ">
                      {heading}
                    </th>
                  ))}
                </tr>
              </thead>
              {/* Table body */}
              <tbody>
                {/* Row */}
                {vehicleServices?.map(({ vehicle, scheduled, date, provider, contact }, index) => (
                  <tr key={index + vehicle} className="hover:bg-brand text-primary hover:text-other">
                    <td className="p-2">{vehicle}</td>
                    <td className="p-2">{scheduled}</td>
                    <td className="p-2">{date}</td>
                    <td className="p-2">{provider}</td>
                    <td className="inline-flex items-center gap-1 p-2 ">
                      <PhoneIcon classes={"h-4 w-w fill-current"} /> {contact}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
}
