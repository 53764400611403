import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { dateTimeDisplay } from "../../../core/utilities";

function DeviceOverViewConnectivityCards({ device }) {
  const categoryText = (category) => {
    let textAttrib = "text-brand";
    switch (category) {
      case "Never":
        textAttrib = "text-delete";
        break;

      case "Over 30 Days":
        textAttrib = "text-warn";
        break;

      default:
        textAttrib = "text-brand";
        break;
    }
    return textAttrib;
  };

  return (
    <Fragment>
      <div className="section-container" tabIndex="0">
        <h2 className="font-semibold">Device Overview</h2>
        <ul className="ml-8 list-disc">
          <li>
            Type: <span className="font-semibold text-brand">{device?.device_type}</span>
          </li>

          <li>
            Serial Number: <span className="font-semibold text-brand">{device.serial_number || ""}</span>
          </li>
          <li>
            Firmware: <span className="font-semibold text-brand">{device.firmware_name || ""}</span>
          </li>
          {device.vehicleMakeAndModel ? (
            <li>
              Vehicle:{" "}
              <Link to={`/vehicles/${device.vehicle_id}`}>
                <span className="font-semibold text-brand">{device.vehicleMakeAndModelRegOrVin}</span>
              </Link>
            </li>
          ) : null}
        </ul>
      </div>

      <div className="section-container" tabIndex="1">
        <div>
          <h2 className="font-semibold">Connectivity</h2>
          <ul className="ml-8 list-disc">
            <li>
              Last Online: <span className={`font-semibold ${categoryText(device.lastSeen.category)}`}>{dateTimeDisplay(device.lastSeen.dateTime) || device.lastSeen.categoryInfo.label}</span>
            </li>
            <li>
              Last Reported:{" "}
              <span className={`font-semibold ${categoryText(device.lastReported.category)}`}>{dateTimeDisplay(device?.lastReported.dateTime) || device.lastReported.categoryInfo.label}</span>
            </li>

            <li>
              Last Data Timestamp: <span className={`font-semibold ${categoryText(device.lastDataTimestamp.category)}`}>{dateTimeDisplay(device.lastDataTimestamp.dateTime) || device.lastDataTimestamp.categoryInfo.label}</span>
            </li>
          </ul>
        </div>
      </div>

      <div className="section-container" tabIndex="2">
        <div>
          <h2 className="font-semibold">Manufacture Connectivity</h2>
          <ul className="ml-8 list-disc">
            <li>
              Status: <span className={`font-semibold ${device.manufacturer_api_activity_status === "Offline" ? "text-delete" : "text-brand"}`}>{device.manufacturer_api_activity_status}</span>
            </li>
            <li>
              Last Online: <span className={`font-semibold ${categoryText(device.manufacturerApiLastOnline.category)}`}>{dateTimeDisplay(device?.manufacturerApiLastOnline.dateTime)}</span>
            </li>

            <li>
              Last Sync: <span className={`font-semibold ${categoryText(device.manufacturerApiLastSync.category)}`}>{dateTimeDisplay(device?.manufacturerApiLastSync.dateTime)}</span>
            </li>
            <li>
              First Sign In: <span className={"font-semibold text-brand"}>{dateTimeDisplay(device.manufacturerApiFirstLogin.dateTime)}</span>
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
}

export default DeviceOverViewConnectivityCards;
