import React from "react";
import { CSVLink } from "react-csv";

function CsvExportButton({exportData,filename }) {
  return (
    <div className="flex justify-end p-2 group">
    <button className="btn-secondary group-hover:btn-primary">
      <CSVLink data={exportData} filename={`${filename}.csv`}>
        Generate CSV
      </CSVLink>
    </button>
  </div>
  )
}

export default CsvExportButton