import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Layout, PrimaryHeader } from "../components";
import FilterSkeleton from "./FilterSkeleton";

export default function ServicesSkeleton({ isLoading, title, serviceItemCount = 15 }) {
  return (
    <div>
      <Layout isLoading={isLoading} loadingText={`Loading ${title}`}>
        <PrimaryHeader title="Drivers" />
        <SkeletonTheme baseColor="var(--color-skeleton)" highlightColor="var(--color-background)">
          <FilterSkeleton />
          <div className="grid grid-cols-2 gap-8 mt-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
            {Array(serviceItemCount)
              .fill(0)
              .map((_, i) => {
                return <ServiceSkeleton key={i} />;
              })}
          </div>
        </SkeletonTheme>
      </Layout>
    </div>
  );
}

function ServiceSkeleton() {
  return (
    <div className="card">
      <h3>
        <Skeleton width={150} height={30} />
      </h3>
      <p>
        <Skeleton width={150} height={20} />
      </p>
      <p>
        <Skeleton width={150} height={20} />
      </p>
      <SkeletonTheme baseColor="var(--color-brand)" highlightColor="var(--color-background)">
        <h3>
          <Skeleton width={175} height={30} />
        </h3>
      </SkeletonTheme>
    </div>
  );
}
