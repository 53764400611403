import React, { useState } from "react";

function SidebarLinkGroup({children,activeCondition}) {

  const [open, setOpen] = useState(activeCondition);

  const handleClick = () => {
    setOpen(!open);
  }

  return (
    <li className={`px-3 py-2  mb-0.5 last:mb-0 sidebar-li group ${activeCondition && "sidebar-active"}`}>
      {children(handleClick, open)}
    </li>
  );
}

export default SidebarLinkGroup;