import React from "react";
import { useEffect, useState } from "react";
import { lastArrayElement, nearestInclusiveMaxArray } from "../../core/utilities";

const PageFilter = ({ itemsPerPage, setItemsPerPage, totalNumberOfItems, isFiltered,numberOfItemsOnCurrentPage, recordsName }) => {

    
    const [pageFilterOptions, setPageFilterOptions] = useState([10])
    useEffect(() => {
        
        const filterOptions = [10, 25, 75, 100, 150, 200, 250, 500, 750, 1000, 10000, 25000, 50000];
        const validOptions = nearestInclusiveMaxArray(filterOptions, totalNumberOfItems);
        setPageFilterOptions(validOptions);
    }, [totalNumberOfItems]);

    const perPageMessage = `${recordsName ? recordsName : "Records"} Per Page`;
    const selectedPageOption = pageFilterOptions &&  pageFilterOptions.includes(itemsPerPage) ? itemsPerPage : lastArrayElement(pageFilterOptions);
    const showingMessage = `Showing ${isFiltered ? "Filtered results" : ""} ${itemsPerPage> totalNumberOfItems ? totalNumberOfItems : numberOfItemsOnCurrentPage||itemsPerPage} of ${totalNumberOfItems}`;
    return (totalNumberOfItems > 10 &&
        <div className="filter-card">

            <div className="flex justify-between">
                <div className="flex flex-row justify-between ">
                    <nav className="flex flex-row items-center gap-2 text-primary" style={{zIndex:0}}>
                        <span className="px-2">{perPageMessage}:</span>
                        <ul className="flex flex-row items-center gap-2">
                            {pageFilterOptions.map(pageFilterOption => (
                                <li key={pageFilterOption}
                                    onClick={() => setItemsPerPage(pageFilterOption)} className={`border border-primary pagination-top-link ${selectedPageOption === pageFilterOption ? "text-other bg-brand border-brand pointer-events-none" : "hover:text-brand hover:border-brand "}`}>
                                        {pageFilterOption}
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
                <div className="text-gray-600 whitespace-normal">
                    <p className="px-2 text-sm text-primary"> {showingMessage}</p>
                </div>
            </div>
        </div>
    );
}
 
export default PageFilter;