import React from "react";
import { PhoneIcon, LocationIcon } from "../icons";

const InfoCard = ({ centerName, address, phone, linkTo = "book service" }) => {
  return (
    <div className="overflow-hidden rounded-lg section-container">
      <div className="px-6 ">
        <h3 className="text-xl font-semibold">{centerName}</h3>

        <div className="flex flex-col gap-2 mt-4">
          <div className="flex flex-row items-start ">
            <LocationIcon classes="h-5 w-5 fill-current" />
            <p className="px-2 text-sm">{address}</p>
          </div>
          <div className="flex flex-row items-start ">
            <PhoneIcon classes="h-5 w-5 fill-current" />
            <p className="px-2 text-sm">{phone}</p>
          </div>
        </div>

        {linkTo === "contact" ? <button className="w-full mt-4 rounded-lg btn-primary">Contact</button> : <button className="mt-4 rounded-lg btn-primary">Book Service Date</button>}
      </div>
    </div>
  );
};
export default InfoCard;
